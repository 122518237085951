import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Button,
    FormControl,
    FormHelperText,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import ApiErrorMessage from '../../components/error/ApiErrorMessage';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Loan type name is required').nullable(),
});

const AddLoanType = ({ handleAddSubmit, handleError }) => {
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    return (
        <form onSubmit={handleSubmit(handleAddSubmit)}>
            <Controller
                render={({ field, formState }) => (
                    <FormControl fullWidth size={'small'} sx={{ mt: 2, minHeight: 150 }}>
                        <Typography color={!!formState.errors?.title ? red[700] : ''}>
                            Loan Type Name*
                        </Typography>
                        <OutlinedInput
                            {...field}
                            error={!!formState.errors?.title}
                            placeholder="Enter type name"
                            maxRows={12}
                        />
                        {!!formState.errors?.title ? (
                            <FormHelperText error>{errors?.title?.message}</FormHelperText>
                        ) : (
                            ''
                        )}
                    </FormControl>
                )}
                name="title"
                control={control}
                defaultValue=""
            />

            <ApiErrorMessage handleError={handleError} />
            <Stack
                justifyContent={'end'}
                direction={'row'}
                flexWrap={'wrap'}
                gap={1.5}
                container
                spacing={2}
                sx={{ my: 2 }}
            >
                <Button
                    variant="contained"
                    onClick={() => reset()}
                    color="inherit"
                    sx={{ minWidth: 130 }}
                >
                    Reset
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    sx={{ minWidth: 130, ml: '0 !important' }}
                >
                    Add
                </Button>
            </Stack>
        </form>
    );
};

export default AddLoanType;
