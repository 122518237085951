import React, { useState } from 'react';
import {
    Button,
    Divider,
    FormControl,
    Grid,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import * as Yup from 'yup';
import { red } from '@mui/material/colors';
import useGetAllEmployees from '../../hooks/useGetAllEmployees';
import { useNavigate } from 'react-router-dom';
import useGetAllDocTypes from '../../hooks/useGetAllDocTypes';
import Docs from '../../helpers/company-docs';

const validationSchema = Yup.object().shape({
    type: Yup.string().required('Document Type is required'),
    employee: Yup.string().required('Employee is required'),
});

const GenerateDocForm = () => {
    const [template, setTemplate] = useState(0);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    const navigate = useNavigate();

    const onSubmit = (data) =>
        navigate(`/employee-docs/generate/${data.employee}/${data.type}?template=${template}`);

    const {
        data: employees,
        isLoading: employeeLoading,
        isError: employeeError,
    } = useGetAllEmployees();
    const {
        data: docTypes,
        isLoading: docTypesLoading,
        isError: docTypesError,
    } = useGetAllDocTypes({ status: 1 });

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <Typography color={formState.errors.type ? red[500] : '#000'}>
                                    Document Type
                                </Typography>
                                <Select
                                    {...field}
                                    error={!!formState.errors.type}
                                    helperText={
                                        formState.errors.type ? formState.errors.type.message : null
                                    }
                                    variant="outlined"
                                    size="small"
                                >
                                    {docTypesLoading && <MenuItem value="">Loading...</MenuItem>}
                                    {docTypesError && (
                                        <MenuItem value="">Error Loading Data</MenuItem>
                                    )}
                                    {docTypes &&
                                        docTypes?.response_data?.map((doc) => (
                                            <MenuItem key={doc.id} value={doc.id}>
                                                {doc.name}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {formState.errors.type && (
                                    <Typography variant="caption" color="red">
                                        {formState.errors.type.message}
                                    </Typography>
                                )}
                            </FormControl>
                        )}
                        name="type"
                        control={control}
                        defaultValue=""
                    />
                </Grid>

                <Grid item xs={12}>
                    <Stack>
                        <Typography>Select Template: </Typography>
                        <Select
                            defaultValue={template}
                            size="small"
                            onChange={(e) => setTemplate(e.target.value)}
                        >
                            {Docs.map((doc, index) => (
                                <MenuItem key={index} value={index}>
                                    {doc.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        render={({ field, formState }) => (
                            <FormControl fullWidth sx={{ mt: 1 }}>
                                <Typography color={formState.errors.employee ? red[500] : '#000'}>
                                    Employee
                                </Typography>
                                <Select
                                    {...field}
                                    error={!!formState.errors.employee}
                                    helperText={
                                        formState.errors.employee
                                            ? formState.errors.employee.message
                                            : null
                                    }
                                    variant="outlined"
                                    size="small"
                                >
                                    {employeeLoading && <MenuItem value="">Loading...</MenuItem>}
                                    {employeeError && (
                                        <MenuItem value="">Error Loading Data</MenuItem>
                                    )}
                                    {employees &&
                                        employees.response_data.map((emp) => (
                                            <MenuItem key={emp.id} value={emp.id}>
                                                {emp.name} ({emp.id})
                                            </MenuItem>
                                        ))}
                                </Select>
                                {formState.errors.employee && (
                                    <Typography variant="caption" color="red">
                                        {formState.errors.employee.message}
                                    </Typography>
                                )}
                            </FormControl>
                        )}
                        name="employee"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
            </Grid>
            <Divider sx={{ my: 2 }} />
            <Stack direction={'row'} justifyContent={'flex-end'}>
                <Button variant="contained" color="primary" type="submit" fullWidth>
                    Generate
                </Button>
            </Stack>
        </form>
    );
};

export default GenerateDocForm;
