// noinspection SpellCheckingInspection

import { Button, Chip, Collapse, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BiTransfer } from 'react-icons/bi';
import { BsCalendarXFill } from 'react-icons/bs';
import {
    FaChartPie,
    FaDonate,
    FaHandHoldingUsd,
    FaHandshake,
    FaMoneyBillAlt,
    FaUserTie,
} from 'react-icons/fa';
import { FaMoneyBill1Wave, FaSackDollar } from 'react-icons/fa6';
import { FiUserMinus, FiUserPlus } from 'react-icons/fi';
import { IoMdSettings } from 'react-icons/io';
import { LiaFileInvoiceDollarSolid } from 'react-icons/lia';
import {
    MdDashboard,
    MdDateRange,
    MdDocumentScanner,
    MdExpandLess,
    MdExpandMore,
    MdOutlineAnnouncement,
    MdOutlineDocumentScanner,
    MdOutlineMessage,
} from 'react-icons/md';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Axios from '../api/Axios';
import { ROLENAME } from '../helpers/commonUses';
import useGetLeaves from '../hooks/getMyLeaves';
import GetRolePermission from '../hooks/permission/GetRolePermission';
import GetUserPermission from '../hooks/permission/GetUserPermission';
import { useAuth } from '../hooks/useAuth';
import useGetMessageNotification from '../hooks/useGetMessageNotification';
import useWindowDimensions from '../hooks/useWindowDimensions';
import SidebarMenu from './SidebarMenu';

const Sidebar = ({ open, onClose }) => {
    const location = useLocation();
    const [submenuControl, setSubmenuControl] = useState({
        clients: false,
        employees: false,
        projects: false,
        department: false,
        report: false,
        settings: false,
        attendance: false,
        providentFund: false,
        gratuity: false,
        encashment: false,
        payroll: false,
        leave: false,
        holiday: false,
        announcement: false,
        officialDocs: false,
        // award: false,
        tax: false,
        transfer: false,
        certificate: false,
        activity: false,
        message: false,
        bank: false,
        loan: false,
        loanRequest: false,
        recruitment: false,
        settlement: false,
        settlementRequest: false,
        mrExpence: false,
        chalan: false,
        taxCertificate: false,
        userRole: false,
    });
    const [submenuNestedControl, setSubmenuNestedControl] = useState({
        setConfig: false,
        setCustom: false,
        permission: false,
    });
    const [submenuTwoNestedControl, setSubmenuTwoNestedControl] = useState({
        setDepartment: false,
    });
    const [drawerWidth, setDrawerWidth] = useState(240);
    const [isCheckable, setIsCheckable] = useState(true);
    const [storeTime, setStoreTime] = useState([]);
    const [shouldFetchData, setShouldFetchData] = useState(false);
    const [storePermissionRoute, setStorePermissionRoute] = useState([]);
    // const [storePermissionPath, setStorePermissionPath] = useState([]);
    const { storePermissionPath } = GetUserPermission();
    const { storeRolePermissionPath } = GetRolePermission();

    //Context
    const { role, token, user, isShowCheckInOut, setIsShowCheckInOut } = useAuth();
    const getUserInfo = JSON.parse(localStorage.getItem('user'));
    const navigate = useNavigate();
    const roleName = ROLENAME;

    //Handle Menu
    const handleClick = (key) => {
        let newValue = { ...submenuControl };
        const currentValue = newValue[key];
        Object.keys(newValue).forEach((keyItem) => {
            newValue[keyItem] = false;
        });
        newValue[key] = !currentValue;
        setSubmenuControl(newValue);
    };

    //Handle Nested Menu
    const handleNestedClick = (key) => {
        let newValue = { ...submenuNestedControl };
        const currentValue = newValue[key];
        Object.keys(newValue).forEach((keyItem) => {
            newValue[keyItem] = false;
        });
        newValue[key] = !currentValue;
        setSubmenuNestedControl(newValue);

        //Hide two nested menu
        let newTwoValue = { ...submenuTwoNestedControl };
        Object.keys(newTwoValue).forEach((keyItem) => {
            newTwoValue[keyItem] = false;
        });

        setSubmenuTwoNestedControl(newTwoValue);
    };

    //Handle Two Nested Menu
    const handleTwoNestedClick = (key) => {
        let newValue = { ...submenuTwoNestedControl };
        const currentValue = newValue[key];
        Object.keys(newValue).forEach((keyItem) => {
            newValue[keyItem] = false;
        });
        newValue[key] = !currentValue;
        setSubmenuTwoNestedControl(newValue);
    };

    const { width } = useWindowDimensions();

    useEffect(() => {
        if (width > 960) {
            setDrawerWidth(240);
        } else {
            setDrawerWidth(400);
        }
    }, [width]);
    useEffect(() => {
        if (location.pathname.includes('clients')) {
            let newValue = { ...submenuControl };
            newValue.clients = !newValue.clients;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('employees')) {
            let newValue = { ...submenuControl };
            newValue.employees = !newValue.employees;
            setSubmenuControl(newValue);
        } else if (location.pathname === '/attendance') {
            let newValue = { ...submenuControl };
            newValue.attendance = !newValue.attendance;
            setSubmenuControl(newValue);
        } else if (location.pathname === '/provident-fund') {
            let newValue = { ...submenuControl };
            newValue.providentFund = !newValue.providentFund;
            setSubmenuControl(newValue);
        } else if (location.pathname?.includes('/loan-manage')) {
            let newValue = { ...submenuControl };
            newValue.loanRequest = !newValue.loanRequest;
            setSubmenuControl(newValue);
        } else if (location.pathname === '/gratuity') {
            let newValue = { ...submenuControl };
            newValue.gratuity = !newValue.gratuity;
            setSubmenuControl(newValue);
        } else if (location.pathname === '/encashment') {
            let newValue = { ...submenuControl };
            newValue.encashment = !newValue.encashment;
            setSubmenuControl(newValue);
        }
        // else if (location.pathname.includes("award")) {
        //     let newValue = { ...submenuControl };
        //     newValue.award = !newValue.award;
        //     setSubmenuControl(newValue);
        // }
        else if (location.pathname.includes('transfer')) {
            let newValue = { ...submenuControl };
            newValue.transfer = !newValue.transfer;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('announcement')) {
            let newValue = { ...submenuControl };
            newValue.announcement = !newValue.announcement;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('official-docs')) {
            let newValue = { ...submenuControl };
            newValue.officialDocs = !newValue.officialDocs;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('settlement')) {
            let newValue = { ...submenuControl };
            newValue.settlement = !newValue.settlement;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('settlementRequest')) {
            let newValue = { ...submenuControl };
            newValue.settlementRequest = !newValue.settlementRequest;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('mrExpence')) {
            let newValue = { ...submenuControl };
            newValue.mrExpence = !newValue.mrExpence;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('message')) {
            let newValue = { ...submenuControl };
            newValue.message = !newValue.message;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('report')) {
            let newValue = { ...submenuControl };
            newValue.report = !newValue.report;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('settings')) {
            let newValue = { ...submenuControl };
            newValue.settings = !newValue.settings;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('attendance')) {
            let newValue = { ...submenuControl };
            newValue.attendance = !newValue.attendance;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('payroll')) {
            let newValue = { ...submenuControl };
            newValue.payroll = !newValue.payroll;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('leave')) {
            let newValue = { ...submenuControl };
            newValue.leave = !newValue.leave;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('bank')) {
            let newValue = { ...submenuControl };
            newValue.bank = !newValue.bank;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('loan')) {
            let newValue = { ...submenuControl };
            newValue.loan = !newValue.loan;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('recruitment')) {
            let newValue = { ...submenuControl };
            newValue.recruitment = !newValue.recruitment;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('settlement')) {
            let newValue = { ...submenuControl };
            newValue.settlement = !newValue.settlement;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('chalan')) {
            let newValue = { ...submenuControl };
            newValue.chalan = !newValue.chalan;
            setSubmenuControl(newValue);
        } else if (location.pathname.includes('certificate')) {
            let newValue = { ...submenuControl };
            newValue.taxCertificate = !newValue.taxCertificate;
            setSubmenuControl(newValue);
        }

        //nested menu
        if (
            location?.pathname?.includes('app-config') ||
            location?.pathname?.includes('logo-list') ||
            location?.pathname?.includes('banner-list') ||
            location?.pathname?.includes('mail-config') ||
            location?.pathname?.includes('branch') ||
            location?.pathname?.includes('role') ||
            location?.pathname?.includes('employee-grade') ||
            // location?.pathname?.includes("attendance") ||
            location?.pathname?.includes('job-contact') ||
            location?.pathname?.includes('office-shift') ||
            location?.pathname?.includes('leave-type') ||
            location?.pathname?.includes('document-type') ||
            location?.pathname?.includes('salary-type') ||
            location?.pathname?.includes('department-list') ||
            location?.pathname?.includes('designation-list') ||
            location?.pathname?.includes('award-type') ||
            location?.pathname?.includes('tax-category') ||
            location?.pathname?.includes('tax-settings')
        ) {
            //main
            let newValue = { ...submenuControl };
            newValue.settings = !newValue.settings;
            setSubmenuControl(newValue);
            //nested
            let newValueNested = { ...submenuNestedControl };
            newValueNested.setConfig = !newValueNested.setConfig;
            setSubmenuNestedControl(newValueNested);
        }
        if (
            location?.pathname?.includes('role-permissions') ||
            location?.pathname?.includes('user-permissions') ||
            location?.pathname?.includes('permissions-change')
        ) {
            //main
            let newValue = { ...submenuControl };
            newValue.settings = !newValue.settings;
            setSubmenuControl(newValue);
            //nested
            let newValueNested = { ...submenuNestedControl };
            newValueNested.permission = !newValueNested.permission;
            setSubmenuNestedControl(newValueNested);
        }
    }, []);
    const handleClose = () => {
        if (width <= 960) {
            onClose();
        }
    };

    // Submit Check in/out and Get User Location
    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    handleAddTimeSubmit();
                },
                (error) => {
                    if (error?.code === 1) {
                        toast.error('Your location is blocked. Please enable it');
                    } else {
                        toast.error('Cannot find your location! \n ' + error?.message);
                    }
                }
            );
        } else {
            // display an error if not supported
            toast.error('Geolocation is not supported by this browser.');
        }
    };

    //Attendance time add
    const handleAddTimeSubmit = async () => {
        try {
            const res = await Axios.post(
                `/attendance/create`,
                {
                    employee_id: user?.user_hrm_id,
                    date: moment().format('YYYY-MM-DD'),
                    time: moment().format('hh:mm:ss A'),
                    // employee_id: attendanceViewData?.employee_id,
                    // date: attendanceViewData?.date,
                    // time: moment(data?.time, "HH:mm").format("hh:mm:ss A"),
                },
                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res?.data?.status === 'success') {
                toast.success('Time Added');
                setShouldFetchData((prev) => !prev);
            } else if (res?.data?.status === 'error') {
                toast.warn(res?.data?.error[0]);
            }
        } catch (error) {
            // console.log(error);
            toast.warn("Can't add check in/out time");
        }
    };

    //format check in out
    const formatCheckInOut = () => {
        if (storeTime?.length > 0) {
            if (storeTime?.length % 2 === 0) {
                return storeTime?.toReversed()?.map((item, index) => {
                    if (index % 2 === 0) {
                        return (
                            <Box component="li">
                                Check Out - {moment(item?.time, 'HH:mm:ss').format('hh:mm A')}
                            </Box>
                        );
                    } else {
                        return (
                            <Box component="li">
                                Check In - {moment(item?.time, 'HH:mm:ss').format('hh:mm A')}
                            </Box>
                        );
                    }
                });
            } else if (storeTime?.length % 2 !== 0) {
                return storeTime?.toReversed()?.map((item, index) => {
                    if (index % 2 !== 0) {
                        return (
                            <Box component="li">
                                Check Out - {moment(item?.time, 'HH:mm:ss').format('hh:mm A')}
                            </Box>
                        );
                    } else {
                        return (
                            <Box component="li">
                                Check In - {moment(item?.time, 'HH:mm:ss').format('hh:mm A')}
                            </Box>
                        );
                    }
                });
            }
        }
    };

    //Check all function
    const checkAllList = (array = []) => {
        const roleUserConcat = storePermissionPath?.concat(storeRolePermissionPath) || [];
        return array?.some((item) => roleUserConcat?.includes(item));
    };

    //Get Attendance data
    const getAttendanceViewData = async () => {
        try {
            const res = await Axios.post(
                `/attendance/show/${user?.employee_id || getUserInfo?.employee_id}`,
                {
                    // date: `2023-02-19`,
                    date: moment().format('YYYY-MM-DD'),
                },

                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res.data.status === 'success') {
                setStoreTime(res?.data?.response_data);
            } else if (res.data.success === '200') {
                toast.info("You don't have attendance details permissions, contact admin");
                setStoreTime([]);
                // navigate("/unauthorized", { replace: true });
            } else {
                // toast.warn("Something went wrong");
                setStoreTime([]);
            }
        } catch (error) {
            setStoreTime([]);
            if (error?.response?.status !== 422) {
                toast.warn('Something went wrong about attendacne time');
            }
        }
    };

    useEffect(() => {
        getAttendanceViewData();
    }, [shouldFetchData]);

    //Get Attendance Config Data
    const getConfigData = async () => {
        try {
            const res = await Axios.post(
                '/all/setting',
                {
                    name: ['setting_online_attendance'],
                },
                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res?.data?.status === 'success') {
                setIsShowCheckInOut(
                    res?.data?.response_data?.setting_online_attendance === '1' ? true : false
                );
            } else if (res.data.success === '200') {
                toast.info("You don't have attendance config permissions, contact admin");
                // navigate("/unauthorized", { replace: true });
            } else if (res?.data?.status === 'error') {
                toast.warn(res?.data?.error[0]);
            }
        } catch (error) {
            console.log(error);
            toast.warn('Something went wrong');
        }
    };
    useEffect(() => {
        let isChecking = true;
        if (isChecking) {
            getConfigData();
        }
        return () => {
            isChecking = false;
        };
    }, [shouldFetchData]);

    //Button check in /out
    useEffect(() => {
        if (storeTime?.length === undefined) {
            setIsCheckable(true);
        } else if (storeTime?.length % 2 === 0) {
            setIsCheckable(true);
        } else {
            setIsCheckable(false);
        }
    }, [storeTime]);

    //Menu List
    const employeeRouterPath = [
        'api/employee/basic',
        'api/employee',
        'api/employee/basic-info/import',
    ];
    const docRouterPath = ['api/employee-internal-document', 'api/employee-internal-document/self'];

    const attendanceRouterPath = [
        'api/attendance/self',
        'api/attendance/summary',
        'api/attendance/daily',
        'api/attendance/details',
        'api/attendance-summery/by-supervisor',
        'api/attendance-daily/by-supervisor',
        'api/attendance/bulk/upload',
    ];

    const leaveRouterPath = [
        'api/leave/self',
        'api/leave',
        'api/leave/create',
        'api/leave/all-by-supervisor',
    ];
    const providentFundPath = [
        'api/provident-fund/self',
        'api/leavprovident-funde',
        'api/provident-fund/create',
        'api/provident-fund/all-by-supervisor',
    ];
    const loanRequestPath = ['api/loan-request', 'api/type-of-loan', 'api/loan-approval-setting'];
    const gratuityPath = [
        'api/gratuity/self',
        'api/leavgratuitye',
        'api/gratuity/create',
        'api/gratuity/all-by-supervisor',
    ];
    const encashmentPath = [
        'api/encashment/self',
        'api/leavencashmente',
        'api/encashment/create',
        'api/encashment/all-by-supervisor',
    ];

    const payrollRouterPath = [
        'api/payroll',
        'api/salary/sheet/generate/single',
        'api/salary/sheet/generate',
        'api/payroll/self',
        'api/payroll/all-by-supervisor',
        'api/salary/sheet/bank',
    ];

    const announcementRouterPath = [
        'api/announcement/self',
        'api/announcement',
        'api/announcement/create',
    ];
    const officialDocsRouterPath = [
        'api/official-document/self',
        'api/official-document',
        'api/official-document/create',
    ];

    // const awardRouterPath = ["api/award", "api/award/create"];

    const transferRouterPath = ['api/transfer/self', 'api/transfer'];

    const settlementRouterPath = ['api/settlement', 'api/settlement/create'];

    const messageRouterPath = ['api/message/self', 'api/message', 'api/message/create'];

    const reportRouterPath = [
        'api/report/employee',
        'api/report/attendance/daily',
        'api/report/attendance/summery',
        'api/report/leave',
        'api/report/payroll',
        'api/report/sattlement',
    ];

    const settingConfigRouterPath = [
        'api/setting',
        'api/setting/create/update',
        'api/setting/create/update/logo',
        'api/branch',
        'api/role',
        'api/job-contract',
        'api/office-shift',
        'api/type-of-leave',
        'api/type-of-leave',
        'api/document-type',
        'api/type-of-salary',
        'api/department',
        'api/designation',
        'api/type-of-award',
        'api/grade',
        'api/challan-year',
        // "api/setting/delete",
        // "api/setting/show/{id}",
    ];

    const settingCustomRouterPath = [
        '/custom-field',
        '/custom-field/show/{id}',
        // "api/employee/custom-field",
        // "api/employee/custom-field/show/{id}",
    ];

    const settingPermissionRouterPath = ['api/permission/role/list', 'api/permission/user/list'];

    const { data, isLoading, isError } = useGetMessageNotification({ enabled: true });
    const {
        data: myLeaves,
        isLoading: leavesLoading,
        isError: LeavesError,
    } = useGetLeaves({ enabled: true });

    return (
        <Drawer
            open={open}
            onClose={onClose}
            variant={width > 960 ? 'persistent' : 'temporary'}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />

            <Box
                className="sidebarContainer"
                sx={{ overflow: 'auto', mt: 5, maxWidth: { xs: '95%', md: '100%' } }}
            >
                {isShowCheckInOut ? (
                    <Box
                        sx={{
                            border: '1px solid #d7d7d7',
                            background: '#dbdbdb',
                            borderRadius: 2,
                            mx: 1,
                        }}
                    >
                        <Stack direction="row" justifyContent={'center'} spacing={1} sx={{ pt: 2 }}>
                            <Button
                                size={'small'}
                                variant="contained"
                                color={isCheckable ? 'info' : 'error'}
                                startIcon={isCheckable ? <FiUserPlus /> : <FiUserMinus />}
                                sx={{ fontSize: 10, pt: 1, pb: 1 }}
                                onClick={getUserLocation}
                            >
                                Check {isCheckable ? 'In' : 'Out'}
                                {/* {isCheckable ? "Check In" : "Check Out"} */}
                            </Button>
                        </Stack>
                        <Box
                            component="ul"
                            sx={{
                                maxHeight: 65,
                                overflow: 'auto',
                                mt: 2,
                                li: {
                                    mb: 1,
                                },
                            }}
                        >
                            {formatCheckInOut()}
                        </Box>
                    </Box>
                ) : null}
                <List>
                    {/***** Dashboard *****/}
                    <SidebarMenu
                        onClose={handleClose}
                        directory="/dashboard/home"
                        icon={<MdDashboard size={20} />}
                        menuTitle={'Dashboard'}
                    />

                    {/***** Employees *****/}

                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('employees')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaUserTie size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Employees" />
                                {submenuControl.employees ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.employees} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Add Employees'}
                                        directory="/employees/add"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Add Employees (Bulk)'}
                                        directory="/employees/bulk-add"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Manage Employees'}
                                        directory="/employees/manage"
                                    />

                                    {/*<SidebarMenu onClose={handleClose} icon={""} menuTitle={"Employee Service Book"} directory="/employees/service-book"/>*/}
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(employeeRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('employees')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <FaUserTie size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Employees" />
                                    {submenuControl.employees ? <MdExpandMore /> : <MdExpandLess />}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.employees}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(employeeRouterPath[0]) ||
                                            storeRolePermissionPath?.includes(
                                                employeeRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Add Employees'}
                                                directory="/employees/add"
                                            />
                                        )}
                                        {(storePermissionPath.includes(employeeRouterPath[2]) ||
                                            storeRolePermissionPath?.includes(
                                                employeeRouterPath[2]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Add Employees (Bulk)'}
                                                directory="/employees/bulk-add"
                                            />
                                        )}
                                        {(storePermissionPath.includes(employeeRouterPath[1]) ||
                                            storeRolePermissionPath?.includes(
                                                employeeRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Manage Employees'}
                                                directory="/employees/manage"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}

                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('employeeDocuments')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <MdDocumentScanner size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Legal Docs" />
                                {submenuControl.employeeDocuments ? (
                                    <MdExpandMore />
                                ) : (
                                    <MdExpandLess />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={submenuControl.employeeDocuments}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Manage Docs'}
                                        directory="/employee-docs/manage"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Manage My Docs'}
                                        directory="/employee-docs/my-docs"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Generate Docs'}
                                        directory="/employee-docs/generate"
                                    />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(docRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('employeeDocuments')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <MdDocumentScanner size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Legal Docs" />
                                    {submenuControl.employeeDocuments ? (
                                        <MdExpandMore />
                                    ) : (
                                        <MdExpandLess />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.employeeDocuments}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(employeeRouterPath[0]) ||
                                            storeRolePermissionPath?.includes(
                                                employeeRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Manage Docs'}
                                                directory="/employee-docs/manage"
                                            />
                                        )}
                                        {(storePermissionPath.includes(docRouterPath[1]) ||
                                            storeRolePermissionPath?.includes(
                                                docRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Manage My Docs'}
                                                directory="/employee-docs/my-docs"
                                            />
                                        )}
                                        {(storePermissionPath.includes(employeeRouterPath[2]) ||
                                            storeRolePermissionPath?.includes(
                                                employeeRouterPath[2]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Generate Docs'}
                                                directory="/employee-docs/generate"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}

                    {/***** Attendance *****/}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('attendance')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <MdDateRange size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Attendance" />
                                {submenuControl.attendance ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.attendance} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={"My Daily"} directory="/attendance/my/daily" /> */}
                                    {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={"My Summary"} directory="/attendance/my/summary" /> */}

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Daily'}
                                        directory="/attendance/daily"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Summary'}
                                        directory="/attendance/summary"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Bulk Attendance'}
                                        directory="/attendance/bulk"
                                    />

                                    {role !== 'Super Admin' && (
                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'Summary By Supervisor'}
                                            directory="/attendance/summary-by-supervisor"
                                        />
                                    )}

                                    {role !== 'Super Admin' && (
                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'Daily By Supervisor'}
                                            directory="/attendance/daily-by-supervisor"
                                        />
                                    )}

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(attendanceRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('attendance')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <MdDateRange size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Attendance" />
                                    {submenuControl.attendance ? (
                                        <MdExpandMore />
                                    ) : (
                                        <MdExpandLess />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.attendance}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(attendanceRouterPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                attendanceRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'My Daily'}
                                                directory="/attendance/my/daily"
                                            />
                                        )}
                                        {(storePermissionPath.includes(attendanceRouterPath[3]) ||
                                            storeRolePermissionPath.includes(
                                                attendanceRouterPath[3]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'My Summery'}
                                                directory="/attendance/my/summary"
                                            />
                                        )}
                                        {(storePermissionPath.includes(attendanceRouterPath[2]) ||
                                            storeRolePermissionPath.includes(
                                                attendanceRouterPath[2]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Daily'}
                                                directory="/attendance/daily"
                                            />
                                        )}
                                        {(storePermissionPath.includes(attendanceRouterPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                attendanceRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Summary'}
                                                directory="/attendance/summary"
                                            />
                                        )}

                                        {(storePermissionPath.includes(attendanceRouterPath[4]) ||
                                            storeRolePermissionPath.includes(
                                                attendanceRouterPath[4]
                                            )) &&
                                            role !== 'Super Admin' && (
                                                <SidebarMenu
                                                    onClose={handleClose}
                                                    icon={''}
                                                    menuTitle={'Summary By Supervisor'}
                                                    directory="/attendance/summary-by-supervisor"
                                                />
                                            )}

                                        {(storePermissionPath.includes(attendanceRouterPath[5]) ||
                                            storeRolePermissionPath.includes(
                                                attendanceRouterPath[5]
                                            )) &&
                                            role !== 'Super Admin' && (
                                                <SidebarMenu
                                                    onClose={handleClose}
                                                    icon={''}
                                                    menuTitle={'Daily By Supervisor'}
                                                    directory="/attendance/daily-by-supervisor"
                                                />
                                            )}

                                        {(storePermissionPath.includes(attendanceRouterPath[6]) ||
                                            storeRolePermissionPath.includes(
                                                attendanceRouterPath[6]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Bulk Attendance'}
                                                directory="/attendance/bulk"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}

                    {/***** Leave *****/}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('leave')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <BsCalendarXFill size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Leave" />
                                {submenuControl.leave ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.leave} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* <SidebarMenu
                                        onClose={handleClose}
                                        icon={""}
                                        menuTitle={
                                            <Stack direction={"row"} gap={1} alignItems={"center"}>
                                                <Typography variant="subtitle2" color={location.pathname === "/leave/self" ? "#3F80EA" : "#0C1E5B"}>
                                                    My Leaves
                                                </Typography>
                                            </Stack>
                                        }
                                        directory="/leave/self"
                                    /> */}

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={
                                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color={
                                                        location.pathname === '/leave/manage'
                                                            ? '#3F80EA'
                                                            : '#0C1E5B'
                                                    }
                                                >
                                                    Manage Leave
                                                </Typography>
                                                {!leavesLoading &&
                                                    !LeavesError &&
                                                    myLeaves?.total_leave_request > 0 && (
                                                        <Chip
                                                            size="small"
                                                            label={`${myLeaves?.total_leave_request ?? 0}`}
                                                            color="error"
                                                        />
                                                    )}
                                            </Stack>
                                        }
                                        directory="/leave/manage"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Leave Encashment'}
                                        directory="/encashment/manage"
                                    />
                                    {role !== 'Super Admin' && (
                                        <>
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={
                                                    <Stack
                                                        direction={'row'}
                                                        gap={1}
                                                        alignItems={'center'}
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            color={
                                                                location.pathname === '/leave/self'
                                                                    ? '#3F80EA'
                                                                    : '#0C1E5B'
                                                            }
                                                        >
                                                            Manage Leave By Supervisor
                                                        </Typography>
                                                        {!leavesLoading &&
                                                            !LeavesError &&
                                                            myLeaves?.total_leave_request > 0 && (
                                                                <Chip
                                                                    size="small"
                                                                    label={`${myLeaves?.total_leave_request ?? 0}`}
                                                                    color="error"
                                                                />
                                                            )}
                                                    </Stack>
                                                }
                                                directory="/leave/manage-by-supervisor"
                                            />
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Leave Encashment'}
                                                directory="/encashment/manage"
                                            />
                                        </>
                                    )}

                                    {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={"New Request"} directory="/leave/add" /> */}

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(leaveRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('leave')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <BsCalendarXFill size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Leave" />
                                    {submenuControl.leave ? <MdExpandMore /> : <MdExpandLess />}
                                </ListItemButton>
                                <Collapse in={submenuControl.leave} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(leaveRouterPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                leaveRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'My Leave'}
                                                directory="/leave/self"
                                            />
                                        )}
                                        {(storePermissionPath.includes(leaveRouterPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                leaveRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={
                                                    <Stack
                                                        direction={'row'}
                                                        gap={1}
                                                        alignItems={'center'}
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            color={
                                                                location.pathname === '/leave/self'
                                                                    ? '#3F80EA'
                                                                    : '#0C1E5B'
                                                            }
                                                        >
                                                            Manage Leave
                                                        </Typography>
                                                        {!leavesLoading &&
                                                            !LeavesError &&
                                                            myLeaves?.total_leave_request > 0 && (
                                                                <Chip
                                                                    size="small"
                                                                    label={`${myLeaves?.total_leave_request ?? 0}`}
                                                                    color="error"
                                                                />
                                                            )}
                                                    </Stack>
                                                }
                                                directory="/leave/manage"
                                            />
                                        )}
                                        {(storePermissionPath.includes(leaveRouterPath[3]) ||
                                            storeRolePermissionPath.includes(leaveRouterPath[3])) &&
                                            role !== 'Super Admin' && (
                                                <SidebarMenu
                                                    onClose={handleClose}
                                                    icon={''}
                                                    menuTitle={
                                                        <Stack
                                                            direction={'row'}
                                                            gap={1}
                                                            alignItems={'center'}
                                                        >
                                                            <Typography
                                                                variant="subtitle2"
                                                                color={
                                                                    location.pathname ===
                                                                    '/leave/manage-by-supervisor'
                                                                        ? '#3F80EA'
                                                                        : '#0C1E5B'
                                                                }
                                                            >
                                                                Manage Leave By Supervisor
                                                            </Typography>
                                                            {!leavesLoading &&
                                                                !LeavesError &&
                                                                myLeaves?.total_leave_request >
                                                                    0 && (
                                                                    <Chip
                                                                        size="small"
                                                                        label={`${myLeaves?.total_leave_request ?? 0}`}
                                                                        color="error"
                                                                    />
                                                                )}
                                                        </Stack>
                                                    }
                                                    directory="/leave/manage-by-supervisor"
                                                />
                                            )}
                                        {(storePermissionPath.includes(leaveRouterPath[2]) ||
                                            storeRolePermissionPath.includes(
                                                leaveRouterPath[2]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'New Request'}
                                                directory="/leave/add"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}

                    {/***** Provident Fund *****/}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('providentFund')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaHandHoldingUsd size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Provident Fund" />
                                {submenuControl.providentFund ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse
                                in={submenuControl.providentFund}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Manage Fund'}
                                        directory="/provident-fund/manage"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'PF Settings'}
                                        directory="/provident-fund/settings"
                                    />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(providentFundPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('providentFund')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <FaHandHoldingUsd size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Provident Fund" />
                                    {submenuControl.providentFund ? (
                                        <MdExpandMore />
                                    ) : (
                                        <MdExpandLess />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.providentFund}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'Manage Fund'}
                                            directory="/provident-fund/manage"
                                        />
                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'PF Settings'}
                                            directory="/provident-fund/settings"
                                        />
                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}

                    {/***** Loan Request *****/}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('loanRequest')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaSackDollar size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Loan Management" />
                                {submenuControl.providentFund ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.loanRequest} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Loan Manage'}
                                        directory="/loan-manage/list"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Loan Type'}
                                        directory="/loan-manage/type"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Loan Approval Settings'}
                                        directory="/loan-manage/approval"
                                    />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(loanRequestPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('loanRequest')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <FaSackDollar size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Loan Management" />
                                    {submenuControl.providentFund ? (
                                        <MdExpandMore />
                                    ) : (
                                        <MdExpandLess />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.loanRequest}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(loanRequestPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                loanRequestPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Loan Manage'}
                                                directory="/loan-manage/list"
                                            />
                                        )}
                                        {(storePermissionPath.includes(loanRequestPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                loanRequestPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Loan Type'}
                                                directory="/loan-manage/type"
                                            />
                                        )}
                                        {(storePermissionPath.includes(loanRequestPath[2]) ||
                                            storeRolePermissionPath.includes(
                                                loanRequestPath[2]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Loan Approval Settings'}
                                                directory="/loan-manage/approval"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}

                    {/***** Gratuity *****/}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('gratuity')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaDonate size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Gratuity" />
                                {submenuControl.gratuity ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.gratuity} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Manage Gratuity'}
                                        directory="/gratuity/manage"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Gratuity Settings'}
                                        directory="/gratuity/settings"
                                    />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(gratuityPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('gratuity')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <FaDonate size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Gratuity" />
                                    {submenuControl.gratuity ? <MdExpandMore /> : <MdExpandLess />}
                                </ListItemButton>
                                <Collapse in={submenuControl.gratuity} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'Manage Gratuity'}
                                            directory="/gratuity/manage"
                                        />
                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'Gratuity Settings'}
                                            directory="/gratuity/settings"
                                        />
                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}

                    {/***** Encashment *****/}
                    {/* {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick("encashment")}>
                                <ListItemIcon sx={{ minWidth: "35px" }}>
                                    <FaDonate size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Leave Encashment" />
                                {submenuControl.encashment ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.encashment} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Leave Encashment"} directory="/encashment/manage" />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(gratuityPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick("encashment")}>
                                    <ListItemIcon sx={{ minWidth: "35px" }}>
                                        <FaDonate size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Leave Encashment" />
                                    {submenuControl.encashment ? <MdExpandMore /> : <MdExpandLess />}
                                </ListItemButton>
                                <Collapse in={submenuControl.encashment} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Leave Encashment"} directory="/encashment/manage" />
                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )} */}

                    {role === roleName ? (
                        <SidebarMenu
                            onClose={handleClose}
                            directory="/holiday/list"
                            icon={<MdDateRange size={20} />}
                            menuTitle={'Holiday'}
                        />
                    ) : (
                        // <SidebarMenu
                        //   onClose={handleClose}
                        //   directory="/holiday/list"
                        //   icon={<MdDateRange size={20} />}
                        //   menuTitle={"Holiday"}
                        // />
                        checkAllList(['api/holiday']) && (
                            <>
                                {' '}
                                <SidebarMenu
                                    onClose={handleClose}
                                    directory="/holiday/list"
                                    icon={<MdDateRange size={20} />}
                                    menuTitle={'Holiday'}
                                />{' '}
                            </>
                        )
                    )}

                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('payroll')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaMoneyBillAlt size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Payroll" />
                                {submenuControl.payroll ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.payroll} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={"My Payroll"} directory="/payroll/self" /> */}
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Manage Payroll'}
                                        directory="/payroll/manage"
                                    />
                                    {role !== 'Super Admin' && (
                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'Manage Payroll By Supervisor'}
                                            directory="/payroll/manage-by-supervisor"
                                        />
                                    )}

                                    {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Single Generate"} directory="/payroll/generate" /> */}

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Generate Payroll'}
                                        directory="/payroll/manage-generation"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Bank Advice'}
                                        directory="/payroll/bank-advice"
                                    />

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(payrollRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('payroll')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <FaMoneyBillAlt size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Payroll" />
                                    {submenuControl.payroll ? <MdExpandMore /> : <MdExpandLess />}
                                </ListItemButton>
                                <Collapse in={submenuControl.payroll} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(payrollRouterPath[3]) ||
                                            storeRolePermissionPath.includes(
                                                payrollRouterPath[3]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'My Payroll'}
                                                directory="/payroll/self"
                                            />
                                        )}
                                        {(storePermissionPath.includes(payrollRouterPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                payrollRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Manage Payroll'}
                                                directory="/payroll/manage"
                                            />
                                        )}
                                        {(storePermissionPath.includes(payrollRouterPath[4]) ||
                                            storeRolePermissionPath.includes(
                                                payrollRouterPath[4]
                                            )) &&
                                            role !== 'Super Admin' && (
                                                <SidebarMenu
                                                    onClose={handleClose}
                                                    icon={''}
                                                    menuTitle={'Manage Payroll By Supervisor'}
                                                    directory="/payroll/manage-by-supervisor"
                                                />
                                            )}

                                        {(storePermissionPath.includes(payrollRouterPath[5]) ||
                                            storeRolePermissionPath.includes(
                                                payrollRouterPath[5]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Bank Advice'}
                                                directory="/payroll/bank-advice"
                                            />
                                        )}
                                        {/* {(storePermissionPath.includes(payrollRouterPath[1]) ||
                                            storeRolePermissionPath.includes(payrollRouterPath[1])) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={""}
                                                menuTitle={"Single Generate"}
                                                directory="/payroll/generate"
                                            />
                                        )} */}
                                        {(storePermissionPath.includes(payrollRouterPath[2]) ||
                                            storeRolePermissionPath.includes(
                                                payrollRouterPath[2]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Generate Payroll'}
                                                directory="/payroll/all-generate"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}
                    <>
                        {role === roleName ? (
                            <>
                                <ListItemButton onClick={() => handleClick('announcement')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <MdOutlineAnnouncement size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Announcement" />
                                    {submenuControl.announcement ? (
                                        <MdExpandMore />
                                    ) : (
                                        <MdExpandLess />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.announcement}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'My Announcement'}
                                            directory="/announcement/self"
                                        />

                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'Announcement List'}
                                            directory="/announcement/list"
                                        />

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        ) : (
                            checkAllList(announcementRouterPath) && (
                                <>
                                    <ListItemButton onClick={() => handleClick('announcement')}>
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            <MdOutlineAnnouncement size={20} />
                                        </ListItemIcon>
                                        <ListItemText primary="Announcement" />
                                        {submenuControl.announcement ? (
                                            <MdExpandMore />
                                        ) : (
                                            <MdExpandLess />
                                        )}
                                    </ListItemButton>
                                    <Collapse
                                        in={submenuControl.announcement}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List component="div" disablePadding>
                                            {(storePermissionPath.includes(
                                                announcementRouterPath[0]
                                            ) ||
                                                storeRolePermissionPath.includes(
                                                    announcementRouterPath[0]
                                                )) && (
                                                <SidebarMenu
                                                    onClose={handleClose}
                                                    icon={''}
                                                    menuTitle={'My Announcement'}
                                                    directory="/announcement/self"
                                                />
                                            )}
                                            {(storePermissionPath.includes(
                                                announcementRouterPath[1]
                                            ) ||
                                                storeRolePermissionPath.includes(
                                                    announcementRouterPath[1]
                                                )) && (
                                                <SidebarMenu
                                                    onClose={handleClose}
                                                    icon={''}
                                                    menuTitle={'Announcement List'}
                                                    directory="/announcement/list"
                                                />
                                            )}

                                            <Divider />
                                        </List>
                                    </Collapse>
                                </>
                            )
                        )}
                    </>

                    <>
                        {role === roleName ? (
                            <>
                                <ListItemButton onClick={() => handleClick('officialDocs')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <MdOutlineDocumentScanner size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Official Docs" />
                                    {submenuControl.officialDocs ? (
                                        <MdExpandMore />
                                    ) : (
                                        <MdExpandLess />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.officialDocs}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'My Official Docs'}
                                            directory="/official-docs/self"
                                        />

                                        <SidebarMenu
                                            onClose={handleClose}
                                            icon={''}
                                            menuTitle={'Official Docs List'}
                                            directory="/official-docs/list"
                                        />

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        ) : (
                            checkAllList(officialDocsRouterPath) && (
                                <>
                                    <ListItemButton onClick={() => handleClick('officialDocs')}>
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            <MdOutlineAnnouncement size={20} />
                                        </ListItemIcon>
                                        <ListItemText primary="Official Docs" />
                                        {submenuControl.officialDocs ? (
                                            <MdExpandMore />
                                        ) : (
                                            <MdExpandLess />
                                        )}
                                    </ListItemButton>
                                    <Collapse
                                        in={submenuControl.officialDocs}
                                        timeout="auto"
                                        unmountOnExit
                                    >
                                        <List component="div" disablePadding>
                                            {(storePermissionPath.includes(
                                                officialDocsRouterPath[0]
                                            ) ||
                                                storeRolePermissionPath.includes(
                                                    officialDocsRouterPath[0]
                                                )) && (
                                                <SidebarMenu
                                                    onClose={handleClose}
                                                    icon={''}
                                                    menuTitle={'My Official Docs'}
                                                    directory="/official-docs/self"
                                                />
                                            )}
                                            {(storePermissionPath.includes(
                                                officialDocsRouterPath[1]
                                            ) ||
                                                storeRolePermissionPath.includes(
                                                    officialDocsRouterPath[1]
                                                )) && (
                                                <SidebarMenu
                                                    onClose={handleClose}
                                                    icon={''}
                                                    menuTitle={'Official Docs List'}
                                                    directory="/official-docs/list"
                                                />
                                            )}

                                            <Divider />
                                        </List>
                                    </Collapse>
                                </>
                            )
                        )}
                    </>

                    {/* {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick("award")}>
                                <ListItemIcon sx={{ minWidth: "35px" }}>
                                    <BsAward size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Award " />
                                {submenuControl.announcement ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.award} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Award List"} directory="/award/list" />

                                    <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Award "} directory="/award/add" />

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(awardRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick("award")}>
                                    <ListItemIcon sx={{ minWidth: "35px" }}>
                                        <BsAward size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Award " />
                                    {submenuControl.announcement ? <MdExpandMore /> : <MdExpandLess />}
                                </ListItemButton>
                                <Collapse in={submenuControl.award} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(awardRouterPath[0]) ||
                                            storeRolePermissionPath.includes(awardRouterPath[0])) && (
                                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Award List"} directory="/award/list" />
                                        )}
                                        {(storePermissionPath.includes(awardRouterPath[1]) ||
                                            storeRolePermissionPath.includes(awardRouterPath[1])) && (
                                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Add Award "} directory="/award/add" />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )} */}

                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('transfer')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <BiTransfer size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Transfer" />
                                {submenuControl.transfer ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.transfer} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={"My Transfer"} directory="/transfer/self" /> */}

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'List Transfer'}
                                        directory="/transfer/list"
                                    />

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(transferRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('transfer')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <BiTransfer size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Transfer" />
                                    {submenuControl.transfer ? <MdExpandMore /> : <MdExpandLess />}
                                </ListItemButton>
                                <Collapse in={submenuControl.transfer} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {/* {(storePermissionPath.includes(transferRouterPath[0]) ||
                                            storeRolePermissionPath.includes(transferRouterPath[0])) && (
                                            <SidebarMenu onClose={handleClose} icon={""} menuTitle={"My Transfer"} directory="/transfer/self" />
                                        )} */}
                                        {(storePermissionPath.includes(transferRouterPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                transferRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'List Transfer'}
                                                directory="/transfer/list"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('settlementRequest')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaHandshake size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Settlement Requests" />
                                {submenuControl.settlementRequest ? (
                                    <MdExpandMore />
                                ) : (
                                    <MdExpandLess />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={submenuControl.settlementRequest}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Request Settlement"} directory="/settlements/request" /> */}
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Settlement Requests'}
                                        directory="/settlements/requests"
                                    />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        <>
                            <ListItemButton onClick={() => handleClick('settlementRequest')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaHandshake size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Settlement Requests" />
                                {submenuControl.settlementRequest ? (
                                    <MdExpandMore />
                                ) : (
                                    <MdExpandLess />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={submenuControl.settlementRequest}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Request Settlement'}
                                        directory="/settlements/request"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Settlement Requests'}
                                        directory="/settlements/requests"
                                    />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    )}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('mrExpence')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaMoneyBill1Wave size={20} />
                                </ListItemIcon>
                                <ListItemText primary="MR Expense" />
                                {submenuControl.mrExpence ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.mrExpence} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* <SidebarMenu onClose={handleClose} icon={""} menuTitle={"Request Settlement"} directory="/settlements/request" /> */}
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'MR Expense List'}
                                        directory="/mr-expense/list"
                                    />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        <>
                            <ListItemButton onClick={() => handleClick('mrExpence')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaHandshake size={20} />
                                </ListItemIcon>
                                <ListItemText primary="MR Expense" />
                                {submenuControl.mrExpence ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.mrExpence} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Create MR Expense'}
                                        directory="/mr-expense/create"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'MR Expense List'}
                                        directory="/mr-expense/list"
                                    />
                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    )}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('settlement')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaHandshake size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Settlement" />
                                {submenuControl.settlement ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.settlement} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'New Settlement'}
                                        directory="/settlements/new"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Settled List'}
                                        directory="/settlements/list"
                                    />

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(settlementRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('settlement')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <FaHandshake size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Settlement" />
                                    {submenuControl.settlement ? (
                                        <MdExpandMore />
                                    ) : (
                                        <MdExpandLess />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.settlement}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(settlementRouterPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                settlementRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'New Settlement'}
                                                directory="/settlements/new"
                                            />
                                        )}
                                        {(storePermissionPath.includes(settlementRouterPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                settlementRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Settled List'}
                                                directory="/settlements/list"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('taxCertificate')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <LiaFileInvoiceDollarSolid size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Tax Certificate" />
                                {submenuControl.taxCertificate ? (
                                    <MdExpandMore />
                                ) : (
                                    <MdExpandLess />
                                )}
                            </ListItemButton>
                            <Collapse
                                in={submenuControl.taxCertificate}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Generate Tax Certificate'}
                                        directory="/tax-certificate/generate"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'List Tax Certificate'}
                                        directory="/tax-certificate/list"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Fiscal Year Tax Certificate'}
                                        directory="/tax-certificate/fiscal"
                                    />

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(settlementRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('settlement')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <FaHandshake size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Settlement" />
                                    {submenuControl.settlement ? (
                                        <MdExpandMore />
                                    ) : (
                                        <MdExpandLess />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.settlement}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(settlementRouterPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                settlementRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'New Settlement'}
                                                directory="/settlements/new"
                                            />
                                        )}
                                        {(storePermissionPath.includes(settlementRouterPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                settlementRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Settled List'}
                                                directory="/settlements/list"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}
                    {/* {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('chalan')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <HiOutlineReceiptTax size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Tax Challan" />
                                {submenuControl.chalan ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.chalan} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Generate Tax Challan'}
                                        directory="/tax-challan/generate"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'List Tax Challan'}
                                        directory="/tax-challan/list"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Individual Tax Challan'}
                                        directory="/tax-challan/individual"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'108 Report'}
                                        directory="/tax-challan/108"
                                    />
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'108A Report'}
                                        directory="/tax-challan/108a"
                                    />

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(settlementRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('settlement')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <FaHandshake size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Settlement" />
                                    {submenuControl.settlement ? (
                                        <MdExpandMore />
                                    ) : (
                                        <MdExpandLess />
                                    )}
                                </ListItemButton>
                                <Collapse
                                    in={submenuControl.settlement}
                                    timeout="auto"
                                    unmountOnExit
                                >
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(settlementRouterPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                settlementRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'New Settlement'}
                                                directory="/settlements/new"
                                            />
                                        )}
                                        {(storePermissionPath.includes(settlementRouterPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                settlementRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Settled List'}
                                                directory="/settlements/list"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )} */}

                    {/* <ListItemButton onClick={() => handleClick("activity")}>
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <FiActivity size={20} />
            </ListItemIcon>
            <ListItemText primary="Activity" />
            {submenuControl.activity ? <MdExpandMore /> : <MdExpandLess />}
          </ListItemButton> */}
                    {/* <Collapse in={submenuControl.activity} timeout="auto" unmountOnExit>
            <SidebarMenu
              onClose={handleClose}
              icon={""}
              menuTitle={"Activity List"}
              directory="/activity/list"
            />
            <List component="div" disablePadding>
              <SidebarMenu
                onClose={handleClose}
                icon={""}
                menuTitle={"Add Activity"}
                directory="/activity/add"
              />

              <Divider />
            </List>
          </Collapse> */}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('message')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <MdOutlineMessage size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Message" />
                                {submenuControl.message ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.message} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* {storePermissionPath.includes(messageRouterPath[0]) && (
                    <SidebarMenu
                      onClose={handleClose}
                      icon={""}
                      menuTitle={"My Message"}
                      directory="/message/self"
                    />
                  )} */}

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={
                                            <Stack direction={'row'} gap={1} alignItems={'center'}>
                                                <Typography
                                                    variant="subtitle2"
                                                    color={
                                                        location.pathname === '/message/inbox'
                                                            ? '#3F80EA'
                                                            : '#0C1E5B'
                                                    }
                                                >
                                                    Inbox
                                                </Typography>
                                                {!isLoading &&
                                                    !isError &&
                                                    data?.total_unread_messages > 0 && (
                                                        <Chip
                                                            size="small"
                                                            label={data?.total_unread_messages}
                                                            color="primary"
                                                        />
                                                    )}
                                            </Stack>
                                        }
                                        directory="/message/inbox"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'All Messages'}
                                        directory="/message/all"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Sent'}
                                        directory="/message/send"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Compose'}
                                        directory="/message/compose-list"
                                    />

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(messageRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('message')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <MdOutlineMessage size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Message" />
                                    {submenuControl.message ? <MdExpandMore /> : <MdExpandLess />}
                                </ListItemButton>
                                <Collapse in={submenuControl.message} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {/* {storePermissionPath.includes(messageRouterPath[0]) && (
                    <SidebarMenu
                      onClose={handleClose}
                      icon={""}
                      menuTitle={"My Message"}
                      directory="/message/self"
                    />
                  )} */}
                                        {(storePermissionPath.includes(messageRouterPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                messageRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={
                                                    <Stack
                                                        direction={'row'}
                                                        gap={1}
                                                        alignItems={'center'}
                                                    >
                                                        <Typography
                                                            variant="subtitle2"
                                                            color={
                                                                location.pathname ===
                                                                '/message/inbox'
                                                                    ? '#3F80EA'
                                                                    : '#0C1E5B'
                                                            }
                                                        >
                                                            Inbox
                                                        </Typography>
                                                        {!isLoading &&
                                                            !isError &&
                                                            data?.total_unread_messages > 0 && (
                                                                <Chip
                                                                    size="small"
                                                                    label={`${data?.total_unread_messages ?? 0} New`}
                                                                    color="primary"
                                                                />
                                                            )}
                                                    </Stack>
                                                }
                                                directory="/message/inbox"
                                            />
                                        )}
                                        {(storePermissionPath.includes(messageRouterPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                messageRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Inbox'}
                                                directory="/message/inbox"
                                            />
                                        )}
                                        {(storePermissionPath.includes(messageRouterPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                messageRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Sent'}
                                                directory="/message/send"
                                            />
                                        )}
                                        {(storePermissionPath.includes(messageRouterPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                messageRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Sent'}
                                                directory="/message/send"
                                            />
                                        )}
                                        {(storePermissionPath.includes(messageRouterPath[2]) ||
                                            storeRolePermissionPath.includes(
                                                messageRouterPath[2]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Compose'}
                                                directory="/message/compose-list"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}
                    {role === roleName ? (
                        <>
                            <ListItemButton onClick={() => handleClick('report')}>
                                <ListItemIcon sx={{ minWidth: '35px' }}>
                                    <FaChartPie size={20} />
                                </ListItemIcon>
                                <ListItemText primary="Reports" />
                                {submenuControl.report ? <MdExpandMore /> : <MdExpandLess />}
                            </ListItemButton>
                            <Collapse in={submenuControl.report} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Employee Report'}
                                        directory="/reports/employee"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Attendance Daily Report'}
                                        directory="/reports/attendance/daily"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Attendance Summery Report'}
                                        directory="/reports/attendance/summery"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Leave Report'}
                                        directory="/reports/leave"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Payroll Report'}
                                        directory="/reports/payroll"
                                    />

                                    <SidebarMenu
                                        onClose={handleClose}
                                        icon={''}
                                        menuTitle={'Settlement Report'}
                                        directory="/reports/settlement"
                                    />

                                    {/*<SidebarMenu onClose={handleClose} icon={""} menuTitle={"Project Wise Attendance"} directory="/reports/project-wise-attendance"/>*/}

                                    <Divider />
                                </List>
                            </Collapse>
                        </>
                    ) : (
                        checkAllList(reportRouterPath) && (
                            <>
                                <ListItemButton onClick={() => handleClick('report')}>
                                    <ListItemIcon sx={{ minWidth: '35px' }}>
                                        <FaChartPie size={20} />
                                    </ListItemIcon>
                                    <ListItemText primary="Reports" />
                                    {submenuControl.report ? <MdExpandMore /> : <MdExpandLess />}
                                </ListItemButton>
                                <Collapse in={submenuControl.report} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {(storePermissionPath.includes(reportRouterPath[0]) ||
                                            storeRolePermissionPath.includes(
                                                reportRouterPath[0]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Employee Report'}
                                                directory="/reports/employee"
                                            />
                                        )}
                                        {(storePermissionPath.includes(reportRouterPath[1]) ||
                                            storeRolePermissionPath.includes(
                                                reportRouterPath[1]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Attendance Daily Report'}
                                                directory="/reports/attendance/daily"
                                            />
                                        )}
                                        {(storePermissionPath.includes(reportRouterPath[2]) ||
                                            storeRolePermissionPath.includes(
                                                reportRouterPath[2]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Attendance Summery Report'}
                                                directory="/reports/attendance/summery"
                                            />
                                        )}
                                        {(storePermissionPath.includes(reportRouterPath[3]) ||
                                            storeRolePermissionPath.includes(
                                                reportRouterPath[3]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Leave Report'}
                                                directory="/reports/leave"
                                            />
                                        )}
                                        {(storePermissionPath.includes(reportRouterPath[4]) ||
                                            storeRolePermissionPath.includes(
                                                reportRouterPath[4]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Payroll Report'}
                                                directory="/reports/payroll"
                                            />
                                        )}
                                        {(storePermissionPath.includes(reportRouterPath[5]) ||
                                            storeRolePermissionPath.includes(
                                                reportRouterPath[5]
                                            )) && (
                                            <SidebarMenu
                                                onClose={handleClose}
                                                icon={''}
                                                menuTitle={'Settlement Report'}
                                                directory="/reports/settlement"
                                            />
                                        )}

                                        <Divider />
                                    </List>
                                </Collapse>
                            </>
                        )
                    )}
                    {role === roleName ? (
                        <SidebarMenu
                            onClose={handleClose}
                            directory="/user/list"
                            icon={<AiOutlineUsergroupAdd size={22} />}
                            menuTitle={'Users'}
                        />
                    ) : (
                        checkAllList(['api/role/user']) && (
                            <>
                                {' '}
                                <SidebarMenu
                                    onClose={handleClose}
                                    directory="/user/list"
                                    icon={<AiOutlineUsergroupAdd size={22} />}
                                    menuTitle={'Users'}
                                />{' '}
                            </>
                        )
                    )}

                    {/* {role === "Admin" && ( */}
                    <>
                        {
                            role === roleName && (
                                <>
                                    <ListItemButton onClick={() => handleClick('settings')}>
                                        <ListItemIcon sx={{ minWidth: '35px' }}>
                                            <IoMdSettings size={20} />
                                        </ListItemIcon>
                                        <ListItemText primary="Settings" />
                                        {submenuControl.settings ? (
                                            <MdExpandMore />
                                        ) : (
                                            <MdExpandLess />
                                        )}
                                    </ListItemButton>
                                    <Collapse in={submenuControl.settings}>
                                        <List component="div" disablePadding>
                                            {/* Configuration Sub Menu    */}

                                            <>
                                                <ListItemButton
                                                    onClick={() => handleNestedClick('setConfig')}
                                                >
                                                    <ListItemIcon
                                                        sx={{ minWidth: '35px' }}
                                                    ></ListItemIcon>
                                                    <ListItemText primary="Configuration" />
                                                    {submenuControl.setConfig ? (
                                                        <MdExpandMore />
                                                    ) : (
                                                        <MdExpandLess />
                                                    )}
                                                </ListItemButton>
                                                <Collapse
                                                    in={submenuNestedControl.setConfig}
                                                    timeout="auto"
                                                    unmountOnExit
                                                    sx={{ pl: 2 }}
                                                >
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'App'}
                                                        directory="/settings/app-config"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'General'}
                                                        directory="/settings/general-config"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Bank'}
                                                        directory="/settings/bank-config"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Logo'}
                                                        directory="/settings/logo-list"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Banner'}
                                                        directory="/settings/banner-list"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'SMTP'}
                                                        directory="/settings/mail-config"
                                                    />
                                                    {/* <SidebarMenu
                    onClose={handleClose}
                    icon={""}
                    menuTitle={"Script"}
                    directory="/settings/script"
                  /> */}
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Branch'}
                                                        directory="/settings/branch"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Role'}
                                                        directory="/settings/role"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'KAM'}
                                                        directory="/settings/manage-kam"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Challan Year'}
                                                        directory="/settings/challan-year"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Employee Grade'}
                                                        directory="/settings/employee-grade"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Attendance'}
                                                        directory="/settings/attendance"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Job Contract'}
                                                        directory="/settings/job-contact"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Office Shift'}
                                                        directory="/settings/office-shift-list"
                                                    />
                                                    {/* <SidebarMenu
                          onClose={handleClose}
                          icon={""}
                          menuTitle={"Leave Type"}
                          directory="/settings/leave-type"
                        /> */}
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Leave Type'}
                                                        directory="/settings/leave-type"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Document Type'}
                                                        directory="/settings/document-type"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Salary Type'}
                                                        directory="/settings/salary-type"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Department'}
                                                        directory="/settings/department-list"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Designation'}
                                                        directory="/settings/designation-list"
                                                    />
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Award Type'}
                                                        directory="/settings/award-type"
                                                    />

                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Tax Category'}
                                                        directory="/settings/tax-category"
                                                    />

                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Tax Settings'}
                                                        directory="/settings/tax-settings"
                                                    />

                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Lunch Allowance Settings'}
                                                        directory="/settings/lunch-allowance-settings"
                                                    />

                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'LTA Allowance Settings'}
                                                        directory="/settings/lta-settings"
                                                    />

                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Absence Deduction Settings'}
                                                        directory="/settings/absence-deduction-settings"
                                                    />

                                                    {/* Department Field Sub Menu    */}
                                                    {/* <ListItemButton
                    onClick={() => handleTwoNestedClick("setDepartment")}
                  >
                    <ListItemIcon sx={{ minWidth: "35px" }}></ListItemIcon>
                    <ListItemText primary="Department" />
                    {submenuTwoNestedControl.setDepartment ? (
                      <MdExpandMore />
                    ) : (
                      <MdExpandLess />
                    )}
                  </ListItemButton>
                  <Collapse
                    in={submenuTwoNestedControl.setDepartment}
                    timeout="auto"
                    unmountOnExit
                    sx={{ pl: 2 }}
                  >
                    <SidebarMenu
                      onClose={handleClose}
                      icon={""}
                      menuTitle={"Department"}
                      directory="/settings/department-list"
                    />
                    <SidebarMenu
                      onClose={handleClose}
                      icon={""}
                      menuTitle={"Designation"}
                      directory="/settings/designation-list"
                    />
                  </Collapse> */}
                                                    {/* <SidebarMenu
                    onClose={handleClose}
                    icon={""}
                    menuTitle={"Activity Type"}
                    directory="/settings/activity-type"
                  /> */}
                                                    {/* <SidebarMenu
                    onClose={handleClose}
                    icon={""}
                    menuTitle={"API Key"}
                    directory="/settings/key-list"
                  /> */}
                                                </Collapse>
                                            </>

                                            {/* Custom Field Sub Menu    */}

                                            {/* <>
                      <ListItemButton
                        onClick={() => handleNestedClick("setCustom")}
                      >
                        <ListItemIcon sx={{ minWidth: "35px" }}></ListItemIcon>
                        <ListItemText primary="Custom Field" />
                        {submenuControl.setConfig ? (
                          <MdExpandMore />
                        ) : (
                          <MdExpandLess />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={submenuNestedControl.setCustom}
                        timeout="auto"
                        unmountOnExit
                        sx={{ pl: 2 }}
                      >
                        <SidebarMenu
                          onClose={handleClose}
                          icon={""}
                          menuTitle={"Field List"}
                          directory="*"
                        />
                      </Collapse>{" "}
                    </> */}

                                            {/* Permission  Sub Menu    */}

                                            <>
                                                <ListItemButton
                                                    onClick={() => handleNestedClick('permission')}
                                                >
                                                    <ListItemIcon
                                                        sx={{ minWidth: '35px' }}
                                                    ></ListItemIcon>
                                                    <ListItemText primary="Permission" />
                                                    {submenuControl.setConfig ? (
                                                        <MdExpandMore />
                                                    ) : (
                                                        <MdExpandLess />
                                                    )}
                                                </ListItemButton>
                                                <Collapse
                                                    in={submenuNestedControl.permission}
                                                    timeout="auto"
                                                    unmountOnExit
                                                    sx={{ pl: 2 }}
                                                >
                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'Role Wise Permission'}
                                                        directory="/settings/role-permissions"
                                                    />

                                                    <SidebarMenu
                                                        onClose={handleClose}
                                                        icon={''}
                                                        menuTitle={'User Wise Permission'}
                                                        directory="/settings/user-permissions"
                                                    />
                                                </Collapse>
                                            </>

                                            {/*<SidebarMenu onClose={handleClose} icon={""} menuTitle={"Tax Settings"} directory="/settings/tax-settings"/>*/}
                                            <Divider />
                                        </List>
                                    </Collapse>
                                </>
                            )
                            //         : (
                            //             checkAllList(settingConfigRouterPath.concat(settingCustomRouterPath, settingPermissionRouterPath)) && (
                            //                 <>
                            //                     <ListItemButton onClick={() => handleClick("settings")}>
                            //                         <ListItemIcon sx={{ minWidth: "35px" }}>
                            //                             <IoMdSettings size={20} />
                            //                         </ListItemIcon>
                            //                         <ListItemText primary="Settings" />
                            //                         {submenuControl.settings ? <MdExpandMore /> : <MdExpandLess />}
                            //                     </ListItemButton>
                            //                     <Collapse in={submenuControl.settings}>
                            //                         <List component="div" disablePadding>
                            //                             {/* Configuration Sub Menu    */}
                            //                             {checkAllList(settingConfigRouterPath) && (
                            //                                 <>
                            //                                     <ListItemButton onClick={() => handleNestedClick("setConfig")}>
                            //                                         <ListItemIcon sx={{ minWidth: "35px" }}></ListItemIcon>
                            //                                         <ListItemText primary="Configuration" />
                            //                                         {submenuControl.setConfig ? <MdExpandMore /> : <MdExpandLess />}
                            //                                     </ListItemButton>
                            //                                     <Collapse in={submenuNestedControl.setConfig} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[0]) ||
                            //                                             storePermissionPath.includes(settingConfigRouterPath[1]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[0]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[0])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"App"}
                            //                                                 directory="/settings/app-config"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[2]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[2])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Logo"}
                            //                                                 directory="/settings/logo-list"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[0]) ||
                            //                                             storePermissionPath.includes(settingConfigRouterPath[1]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[0]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[1])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"SMTP"}
                            //                                                 directory="/settings/mail-config"
                            //                                             />
                            //                                         )}

                            //                                         {/* <SidebarMenu
                            //     onClose={handleClose}
                            //     icon={""}
                            //     menuTitle={"Script"}
                            //     directory="/settings/script"
                            //   /> */}

                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[3]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[3])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Branch"}
                            //                                                 directory="/settings/branch"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[4]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[4])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Role"}
                            //                                                 directory="/settings/role"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[15]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[15])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Challan Year"}
                            //                                                 directory="/settings/challan-year"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[14]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[14])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Employee Grade"}
                            //                                                 directory="/settings/employee-grade"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[0]) ||
                            //                                             storePermissionPath.includes(settingConfigRouterPath[1]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[0]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[1])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Attendance"}
                            //                                                 directory="/settings/attendance"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[5]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[5])) && (
                            //                                             <SidebarMenu
                            //                                                 onC
                            //                                                 lose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Job Contract"}
                            //                                                 directory="/settings/job-contact"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[6]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[6])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Office Shift"}
                            //                                                 directory="/settings/office-shift-list"
                            //                                             />
                            //                                         )}
                            //                                         {/* {storePermissionPath.includes(
                            //               settingConfigRouterPath[7]
                            //             ) && (
                            //               <SidebarMenu
                            //                 onClose={handleClose}
                            //                 icon={""}
                            //                 menuTitle={"Leave Type"}
                            //                 directory="/settings/leave-type"
                            //               />
                            //             )} */}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[8]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[8])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Leave Type"}
                            //                                                 directory="/settings/leave-type"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[9]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[9])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Document Type"}
                            //                                                 directory="/settings/document-type"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[10]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[10])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Salary Type"}
                            //                                                 directory="/settings/salary-type"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[11]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[11])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Department"}
                            //                                                 directory="/settings/department-list"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[12]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[12])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Designation"}
                            //                                                 directory="/settings/designation-list"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingConfigRouterPath[13]) ||
                            //                                             storeRolePermissionPath.includes(settingConfigRouterPath[13])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Award Type"}
                            //                                                 directory="/settings/award-type"
                            //                                             />
                            //                                         )}

                            //                                         {/* Department Field Sub Menu    */}
                            //                                         {/* <ListItemButton
                            //     onClick={() => handleTwoNestedClick("setDepartment")}
                            //   >
                            //     <ListItemIcon sx={{ minWidth: "35px" }}></ListItemIcon>
                            //     <ListItemText primary="Department" />
                            //     {submenuTwoNestedControl.setDepartment ? (
                            //       <MdExpandMore />
                            //     ) : (
                            //       <MdExpandLess />
                            //     )}
                            //   </ListItemButton>
                            //   <Collapse
                            //     in={submenuTwoNestedControl.setDepartment}
                            //     timeout="auto"
                            //     unmountOnExit
                            //     sx={{ pl: 2 }}
                            //   >
                            //     <SidebarMenu
                            //       onClose={handleClose}
                            //       icon={""}
                            //       menuTitle={"Department"}
                            //       directory="/settings/department-list"
                            //     />
                            //     <SidebarMenu
                            //       onClose={handleClose}
                            //       icon={""}
                            //       menuTitle={"Designation"}
                            //       directory="/settings/designation-list"
                            //     />
                            //   </Collapse> */}

                            //                                         {/* <SidebarMenu
                            //     onClose={handleClose}
                            //     icon={""}
                            //     menuTitle={"Activity Type"}
                            //     directory="/settings/activity-type"
                            //   /> */}
                            //                                         {/* <SidebarMenu
                            //     onClose={handleClose}
                            //     icon={""}
                            //     menuTitle={"API Key"}
                            //     directory="/settings/key-list"
                            //   /> */}
                            //                                     </Collapse>
                            //                                 </>
                            //                             )}
                            //                             {/* Custom Field Sub Menu    */}
                            //                             {/* {checkAllList(settingCustomRouterPath) && (
                            //         <>
                            //           <ListItemButton
                            //             onClick={() => handleNestedClick("setCustom")}
                            //           >
                            //             <ListItemIcon
                            //               sx={{ minWidth: "35px" }}
                            //             ></ListItemIcon>
                            //             <ListItemText primary="Custom Field" />
                            //             {submenuControl.setConfig ? (
                            //               <MdExpandMore />
                            //             ) : (
                            //               <MdExpandLess />
                            //             )}
                            //           </ListItemButton>
                            //           <Collapse
                            //             in={submenuNestedControl.setCustom}
                            //             timeout="auto"
                            //             unmountOnExit
                            //             sx={{ pl: 2 }}
                            //           >
                            //             {(storePermissionPath.includes(
                            //               settingCustomRouterPath[0]
                            //             ) ||
                            //               storeRolePermissionPath.includes(
                            //                 settingCustomRouterPath[0]
                            //               )) && (
                            //               <SidebarMenu
                            //                 onClose={handleClose}
                            //                 icon={""}
                            //                 menuTitle={"Field List"}
                            //                 directory="*"
                            //               />
                            //             )}
                            //           </Collapse>{" "}
                            //         </>
                            //       )} */}

                            //                             {/* Permission  Sub Menu    */}
                            //                             {checkAllList(settingPermissionRouterPath) && (
                            //                                 <>
                            //                                     <ListItemButton onClick={() => handleNestedClick("permission")}>
                            //                                         <ListItemIcon sx={{ minWidth: "35px" }}></ListItemIcon>
                            //                                         <ListItemText primary="Permission" />
                            //                                         {submenuControl.setConfig ? <MdExpandMore /> : <MdExpandLess />}
                            //                                     </ListItemButton>
                            //                                     <Collapse in={submenuNestedControl.permission} timeout="auto" unmountOnExit sx={{ pl: 2 }}>
                            //                                         {(storePermissionPath.includes(settingPermissionRouterPath[0]) ||
                            //                                             storeRolePermissionPath.includes(settingPermissionRouterPath[0])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"Role Wise Permission"}
                            //                                                 directory="/settings/role-permissions"
                            //                                             />
                            //                                         )}
                            //                                         {(storePermissionPath.includes(settingPermissionRouterPath[1]) ||
                            //                                             storeRolePermissionPath.includes(settingPermissionRouterPath[1])) && (
                            //                                             <SidebarMenu
                            //                                                 onClose={handleClose}
                            //                                                 icon={""}
                            //                                                 menuTitle={"User Wise Permission"}
                            //                                                 directory="/settings/user-permissions"
                            //                                             />
                            //                                         )}
                            //                                     </Collapse>
                            //                                 </>
                            //                             )}
                            //                             {/*<SidebarMenu onClose={handleClose} icon={""} menuTitle={"Tax Settings"} directory="/settings/tax-settings"/>*/}
                            //                             <Divider />
                            //                         </List>
                            //                     </Collapse>
                            //                 </>
                            //             )
                            //         )
                        }
                    </>
                    {/* )} */}
                </List>
            </Box>
        </Drawer>
    );
};

export default Sidebar;
