import { useQuery } from "@tanstack/react-query";
import { getAppConfig } from "../api/fetchers/commonFetcher";

const defaultFieldNames = [
    "setting_app_name",
    "setting_app_email",
    "setting_app_phone",
    "setting_app_address",
    "setting_app_tin_number",
    "setting_app_company_name",
    "setting_app_bank_name",
    "setting_app_bank_acc_number",
    "company_logo",
];

const useAppConfig = (fieldNames = defaultFieldNames) => {
    const { data, isLoading, error } = useQuery({
        queryKey: ["appConfig"],
        queryFn: () => getAppConfig(fieldNames),
    });

    return {
        data: data?.response_data,
        isLoading,
        error,
    };
};

export default useAppConfig;
