import { Resolution, Margin } from "react-to-pdf";

export const options = {
    // open can also be used to open the PDF in a new window, default is false
    method: "save",
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: 2,
    page: {
        // margin is in MM, default is Margin.NONE = 0
        margin: 5,
        // 'letter' can also be used
        format: "A4",
        orientation: "portrait",
    },
    canvas: {
        // 'image/png' be used for better image quality
        mimeType: "image/webp",
        qualityRatio: 3,
    },
    // Customize any value passed to the jsPDF instance and html2canvas
    // function. You probably will not need this and things can break,
    // so use with caution.
    overrides: {
        // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
        pdf: {
            compress: false,
        },
        // see https://html2canvas.hertzen.com/configuration for more options
        canvas: {
            useCORS: false,
        },
    },
};
