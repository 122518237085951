import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    MenuItem,
    Modal,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { FaEdit } from "react-icons/fa";
import { MdDeleteForever } from "react-icons/md";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { postProvidentSettings } from "../../api/fetchers/settingsFetcher";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useGetProvidentSettings from "../../hooks/useGetProvidentSettings";

const validationSchema = Yup.object().shape({
    econtribution: Yup.string().required("Employee's Contribution is required"),
    ocontribution: Yup.string().required("Organization's Contribution is required"),
    // minAge: Yup.string().required("Min Job Age as Permanent is required"),
    minAgeToReceive: Yup.string().required("Min Job Age to Reciveve Organization Benefit is required"),
});

const FundSettings = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
        defaultValues: {
            calculationOn: 1,
        },
    });
    const onSubmit = (data) =>
        mutation.mutate({
            employee_contribution: data.econtribution,
            organization_contribution: data.ocontribution,
            min_job_age_reciv_org_benefit: data.minAgeToReceive,
            min_job_age_permanent: data.minAge,
            calculation_on: data.calculationOn,
        });

    const queryClient = useQueryClient();

    const { data: providentSettings, isLoading, isError } = useGetProvidentSettings();

    useEffect(() => {
        if (providentSettings) {
            console.log("gratuitySettings", providentSettings.reponse_data);
            setValue("econtribution", providentSettings.reponse_data.employee_contribution);
            setValue("ocontribution", providentSettings.reponse_data.organization_contribution);
            setValue("minAge", providentSettings.reponse_data.min_job_age_permanent);
            setValue("minAgeToReceive", providentSettings.reponse_data.min_job_age_reciv_org_benefit);
            setValue("calculationOn", providentSettings.reponse_data.calculation_on);
        }
    }, [providentSettings]);

    const mutation = useMutation({
        mutationFn: (formData) => {
            return postProvidentSettings(formData);
        },
        onSuccess: () => {
            queryClient.invalidateQueries("provident-settings");
            toast.success("Settings Updated Successfully");
        },

        onError: () => {
            queryClient.invalidateQueries("provident-settings");
            toast.error("Something went wrong");
        },
    });

    return (
        <Paper elevation={2} sx={{ p: 2, maxWidth: "500px", margin: "0 auto" }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="h6">Provident Fund Contribution and Calculation Settings</Typography>
            </Stack>
            <Divider sx={{ my: 2 }}></Divider>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Employee's Contribution (in %)</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.econtribution}
                                        helperText={formState.errors.econtribution ? formState.errors.econtribution.message : null}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "-" || e.key === "e") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="econtribution"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Organization's Contribution (in %)</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.ocontribution}
                                        helperText={formState.errors.ocontribution ? formState.errors.ocontribution.message : null}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "-" || e.key === "e") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="ocontribution"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Calculation On</Typography>
                                    <FormControl fullWidth size="small">
                                        <Select
                                            {...field}
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            error={!!formState.errors.calculationOn}
                                            helperText={formState.errors.calculationOn ? formState.errors.calculationOn.message : null}
                                        >
                                            <MenuItem value={1}>Basic</MenuItem>
                                            <MenuItem value={2}>Gross</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FormControl>
                            )}
                            name="calculationOn"
                            control={control}
                            defaultValue="gross"
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Typography>Eligibity</Typography>
                <Grid container spacing={2}>
                    {/* <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Min Job Age as Permanent</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.minAge}
                                        helperText={formState.errors.minAge ? formState.errors.minAge.message : null}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "-" || e.key === "e") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="minAge"
                            control={control}
                            defaultValue=""
                        />
                    </Grid> */}
                    <Grid item xs={12}>
                        <Controller
                            render={({ field, formState }) => (
                                <FormControl fullWidth sx={{ mt: 1 }}>
                                    <Typography>Min Job Age to Reciveve Organization Benefit</Typography>
                                    <TextField
                                        {...field}
                                        error={!!formState.errors.minAgeToReceive}
                                        helperText={formState.errors.minAgeToReceive ? formState.errors.minAgeToReceive.message : null}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        inputProps={{ min: 0, max: 100 }}
                                        onKeyPress={(e) => {
                                            if (e.key === "-" || e.key === "e") {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </FormControl>
                            )}
                            name="minAgeToReceive"
                            control={control}
                            defaultValue=""
                        />
                    </Grid>
                </Grid>
                <Divider sx={{ my: 2 }} />
                <Stack direction={"row"} justifyContent={"flex-end"}>
                    <Button variant="contained" color="primary" type="submit">
                        Save
                    </Button>
                </Stack>
            </form>
        </Paper>
    );
};

export default FundSettings;
