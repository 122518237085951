import CircularSpinner from './circular-spinner';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';


const SpinnerModalLoading = () => {

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
        >
            <Paper
                elevation={0}
                sx={{
                    padding: 3,
                    borderRadius: 2,
                }}
            >
                <CircularSpinner />
            </Paper>
        </Box>
    );
};

export default SpinnerModalLoading;
