import { Avatar, Box, Paper, Stack, Typography } from "@mui/material";

export default function DataNotFound({padding=4}) {
  return (
    <Box>
      <Paper elevation={0} sx={{ p: padding }}>
        <Stack alignItems={"center"}>
          <Avatar
            src="/assets/images/no-data.png"
            alt="No Data"
            variant={"square"}
            sx={{ height: "128px", width: "128px", opacity: 0.5 }}
          />
          <Typography
            variant={"h4"}
            textAlign={"center"}
            sx={{ fontSize:24, mt: 2, opacity: 0.7 }}
          >
            No Data Found!
          </Typography>
        </Stack>
      </Paper>
    </Box>
  );
}
