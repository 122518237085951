import { useQuery } from "@tanstack/react-query";
import { getSalarySheetList } from "../api/fetchers/payrollFetcher";

const useGetAllSalarySheet = ({ page, rowsPerPage, month = null, year = null }) => {
    return useQuery({
        queryKey: ["all-salarySheet", page, rowsPerPage, month, year],
        queryFn: () => getSalarySheetList(page, rowsPerPage, month, year),
    });
};

export default useGetAllSalarySheet;
