import { useQuery } from "@tanstack/react-query";
import { getGratuitySettings } from "../api/fetchers/settingsFetcher";

const useGetGratuitySettings = () => {
    return useQuery({
        queryKey: ["gratuity-settings"],
        queryFn: () => getGratuitySettings(),
    });
};

export default useGetGratuitySettings;
