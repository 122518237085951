import axios from "axios";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_LIVE_API_BASE_URL,
  timeout: 60000,
});

Axios.interceptors.response.use(
  function (response) {
    // console.log(
    //   window?.location?.pathname?.includes("unauthorized"),
    //   "response:",
    //   response,
    //   response?.data
    // );
    if (
      response?.data?.success === "200" &&
      !window?.location?.pathname?.includes("unauthorized")
    ) {
      console.log("yes 200");
      // alert("unauthorized");
      //   window.location.href = "/unauthorized";
      // return response;
    } else {
    }
    return response;
  },
  function (error) {
    if (
      error.response.status === 403 &&
      !window?.location?.pathname?.includes("unauthorized")
    ) {
      console.log("error 403");

      window.location.href = "/unauthorized";
    }
    if (error.response.status === 401 && !window?.location?.pathname?.includes("login")) {
      console.log(error);
      localStorage.clear();
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default Axios;
