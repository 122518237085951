import React from 'react';
import { loanStatus } from '../../utils/utils';
import { Chip } from '@mui/material';

function LoanStatus({ status }) {
    if (status === loanStatus[1]) {
        return <Chip label="Running" size="small" color="warning" />;
    } else if (status === loanStatus[2]) {
        return <Chip label="Pause" size="small" />;
    } else if (status === loanStatus[3]) {
        return <Chip label="Closed" size="small" color="error" />;
    }
    return <Chip label="Opening" size="small" color="info" />;
}

export default LoanStatus;
