import { toast } from "react-toastify";
import Axios from "../Axios";

export const getManageProvidentFund = async (page, rowsPerPage, searchValue) => {
    try {
        const res = await Axios.get(`provident-funds/manage-provident-fund?page=${page + 1}&limit=${rowsPerPage}&search=${searchValue}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getProvidentFundDetails = async (employeeId) => {
    try {
        const res = await Axios.get(`provident-funds/employee/${employeeId}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
