import { useQuery } from "@tanstack/react-query";
import { getProvidendSettings } from "../api/fetchers/settingsFetcher";

const useGetProvidentSettings = () => {
    return useQuery({
        queryKey: ["provident-settings"],
        queryFn: () => getProvidendSettings(),
    });
};

export default useGetProvidentSettings;
