import { Button, Divider, FormControl, Grid, MenuItem, OutlinedInput, Select, Stack, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import Axios from "../../api/Axios";
import { useAuth } from "../../hooks/useAuth";
import ErrorMessageShow from "./ErrorMessageShow";

const SalaryInfoTab = ({ onSubmit, onPrev, employeeData, stepData, salaryType, setSalaryType, handleError }) => {
    const { token } = useAuth();
    const [loading, setLoading] = useState(false);
    const [salaryDetails, setSalaryDetails] = useState({
        additions: 0,
        deductions: 0,
        total: 0,
    });

    const changeHandler = (value, index) => {
        const salaries = [...salaryType];
        if (value.length > 0) {
            salaries[index].amount = parseInt(value);
            setSalaryType(salaries);
        } else {
            salaries[index].amount = 0;
        }
        console.log({ salaries });
        setSalaryType(salaries);
    };

    useEffect(() => {
        reset({ ...employeeData });
    }, []);

    const validationSchema = Yup.object().shape({});
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
        defaultValues: {
            taxApplicable: "No",
        },
    });

    // useEffect(() => {
    //     let basic = 0;
    //     let transport = 0;
    //     if (getValues().basic !== "") {
    //         basic = parseInt(getValues().basic)
    //     }
    //     if (getValues().transportAllowance !== "") {
    //         transport = parseInt(getValues().transportAllowance)
    //     }
    //     setValue("gross", basic + transport)
    // }, [getValues().basic, getValues().transportAllowance])

    // useEffect(() => {
    //     if (stepData !== null) {
    //         setValue("basic", stepData?.basic_salary);
    //         setValue("transportAllowance", stepData?.transport_allowance);
    //         setValue("medicalAllowance", stepData?.medical_allowance);
    //         setValue("houseRent", stepData?.house_rent);
    //         setValue("mobileBill", stepData?.mobile_bill);
    //         setValue("conveyance", stepData?.conveyance_bill);
    //         setValue("lunch", stepData?.lunch_allowance);
    //         setValue("others", stepData?.others_bill);
    //         setValue("basic", stepData?.basic_salary);
    //         setValue("eidBonus", stepData?.bonus);
    //         setValue("taxApplicable", stepData?.tax_applicable);
    //         setValue("tax", stepData?.tax);
    //         setValue("otherDeductions", stepData?.others_deduction);
    //         setValue("Circle", stepData?.circle);
    //         setValue("zone", stepData?.zone);
    //         setValue("etinNo", stepData?.e_tin);
    //         setValue(
    //             "taxReturnDate",
    //             stepData?.tax_return_date === null
    //                 ? null
    //                 : new Date(
    //                     stepData?.tax_return_date === undefined
    //                         ? null
    //                         : stepData?.tax_return_date
    //                 )
    //         );
    //         setValue("gross", stepData?.gross_salary);
    //         setIsTaxRequired(stepData?.tax_applicable);
    //         setAllSalary({
    //             basic: stepData?.basic_salary,
    //             transport: stepData?.transport_allowance,
    //             medical: stepData?.medical_allowance,
    //             house: stepData?.house_rent,
    //             provident: 0,
    //             tax: stepData?.tax,
    //             mobile: stepData?.mobile_bill,
    //             conveyance: stepData?.conveyance_bill,
    //             lunch: stepData?.lunch_allowance,
    //             others: stepData?.others_bill,
    //         });
    //     }
    //     console.log(stepData);
    // }, [stepData]);

    // useEffect(() => {
    //     if(stepData !== null) {
    //         setIsTaxRequired(stepData?.tax_applicable)
    //     }
    // }, [stepData])

    useEffect(() => {
        if (stepData && stepData.length > 0) {
            // setSalaryType(stepData.map(salary => ({id: salary.salary_type.id, name: salary.salary_type.name, amount: salary.value})))
            // console.log(stepData.map(salary => ({id: salary.salary_type.id, name: salary.salary_type.name, amount: salary.value})))
            const tempSalaryType = [...salaryType];
            stepData.forEach((salary) => {
                // tempSalaryType.find(item => parseInt(item.id) == parseInt(salary?.salary_type?.id)).amount = parseInt(salary.value)
                const ind = tempSalaryType.findIndex((item) => parseInt(item.id) === parseInt(salary?.salary_type?.id));
                if (ind > -1) {
                    tempSalaryType[ind].amount = parseInt(salary.value);
                }
            });
            setSalaryType(tempSalaryType);
        }
    }, [stepData]);

    const getAllSalaryType = async () => {
        setLoading(true);
        try {
            const response = await Axios.get(`type-of-salary/all`, {
                headers: {
                    Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                },
            });
            console.log("getAllSalaryType - response:", response);
            setSalaryType(response.data.response_data);
        } catch (error) {
            console.log(error.response.status);
        } finally {
            setLoading(false);
        }
    };

    //get office schedule
    useEffect(() => {
        getAllSalaryType();
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Additions
                    </Typography>
                    {salaryType
                        ?.filter((item) => item.type === 0 && item.id !== 1)
                        .map((salary, index) => (
                            <FormControl fullWidth variant="outlined" size={"small"}>
                                <Typography sx={{ textTransform: "capitalize" }}>{salary.name}</Typography>
                                <OutlinedInput
                                    sx={{ marginBottom: 2 }}
                                    type="number"
                                    placeholder="Enter Salary"
                                    value={salary?.amount}
                                    inputProps={{ min: 0 }}
                                    // value={salary?.amount ?? 0}
                                    onChange={(e) => {
                                        changeHandler(e.target.value, index);
                                    }}
                                />
                            </FormControl>
                        ))}
                </Grid>

                <Grid item md={6} sm={6} xs={12}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        Deductions
                    </Typography>
                    {salaryType
                        ?.filter((item) => item.type === 1)
                        .map((salary, index) => (
                            <FormControl fullWidth variant="outlined" size={"small"} key={index}>
                                {salary.name === "Tax Applicable?" ? (
                                    <>
                                        <Typography sx={{ textTransform: "capitalize" }}>{salary.name}</Typography>
                                        <Select
                                            value={salary?.amount}
                                            onChange={(e) => {
                                                changeHandler(e.target.value, index);
                                            }}
                                        >
                                            <MenuItem value="yes">Yes</MenuItem>
                                            <MenuItem value="no">No</MenuItem>
                                        </Select>
                                    </>
                                ) : (
                                    <>
                                        <Typography sx={{ textTransform: "capitalize" }}>{salary.name}</Typography>
                                        <OutlinedInput
                                            sx={{ marginBottom: 2 }}
                                            type="number"
                                            value={salary?.amount}
                                            placeholder="Enter Salary"
                                            onChange={(e) => {
                                                changeHandler(e.target.value, index);
                                            }}
                                        />
                                    </>
                                )}
                            </FormControl>
                        ))}
                </Grid>

                {/*<Grid item md={12} sm={12} xs={12}>*/}
                {/*    <Divider sx={{mt: 2}}/>*/}
                {/*</Grid>*/}
                {/*<Grid item md={4} sm={4} xs={12}>*/}
                {/*    <Typography variant="h6" sx={{mb: 2}}>*/}
                {/*       Total Additions: {salaryDetails.additions}*/}
                {/*    </Typography>*/}
                {/*</Grid>*/}
            </Grid>

            <Divider sx={{ mt: 2 }} />
            <ErrorMessageShow handleError={handleError} />
            <Stack direction="row" justifyContent="flex-end" sx={{ mt: 2 }}>
                <Button variant="contained" type={"submit"} onClick={() => console.log(salaryType)}>
                    Save
                </Button>
            </Stack>
        </form>
    );
};

export default SalaryInfoTab;
