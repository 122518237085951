import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import ReactSelect from "react-select";
import DateInput from "../CustomComponents/DateInput";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import {
  departmentTreeViewFormat,
  designationTreeViewFormat,
} from "../../helpers/treeViewHelper";

const dutyTypeOption = [
  { label: "Monthly", value: "Monthly" },
  { label: "Weekly", value: "Weekly" },
  { label: "Daily", value: "Daily" },
];

const isSupervisorOption = [
  { label: "Yes", value: "Yes" },
  { label: "No", value: "No" },
];

const validationSchema = Yup.object().shape({
  subDepartment: Yup.string().required("Sub Department is Required"),
  position: Yup.string().required("Position is Required"),
  hireDate: Yup.date().required("Hiring date is Required").nullable(),
  joiningDate: Yup.date().required("Joining date is Required").nullable(),
  terminationDate: Yup.date().nullable(),
  rehireDate: Yup.date().nullable(),
  payFrequency: Yup.string().required("Pay Frequency is Required"),
  isSupervisor: Yup.string().required("Is Supervisor is required"),
});

const PositionalTab = ({
  onSubmit,
  onPrev,
  employeeData,
  stepData,
  projectId,
}) => {
  const [payFrequency, setPayFrequency] = useState([]);
  const [separationOption, setSeparationOption] = useState([]);
  const [parentDepartment, setParentDepartment] = useState([]);
  const [parentDesignation, setParentDesignation] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  useEffect(() => {
    reset({ ...employeeData });
  }, []);

  const { token } = useAuth();

  //Getting Pay Frequency data
  useEffect(() => {
    const getFrequency = async () => {
      try {
        const res = await Axios.get("/pay-frequency", {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        });
        if (res.data.status === "success") {
          setPayFrequency(
            res.data.response_data.map((payF) => ({
              label: payF.name,
              value: payF.id,
            }))
          );
        } else {
          setPayFrequency([]);
          toast.warn("Unable to fetch clients at the moment");
        }
      } catch (error) {
        console.log(error);
        toast.warn("Unable to fetch clients at the moment");
      }
    };

    const getSeperation = async () => {
      try {
        const res = await Axios.get("/voluntary-separation", {
          headers: {
            Authorization: token
              ? "Bearer " + token
              : "Bearer " + localStorage.getItem("token"),
          },
        });
        if (res.data.status === "success") {
          setSeparationOption(
            res.data.response_data.map((separation) => ({
              label: separation.name,
              value: separation.id,
            }))
          );
        } else {
          setSeparationOption([]);
          toast.warn("Unable to fetch clients at the moment");
        }
      } catch (error) {
        console.log(error);
        toast.warn("Unable to fetch clients at the moment");
      }
    };

    const getParentDepartment = async () => {
      try {
        const res = await Axios.get(
          `department/parent?project_id=${projectId}`,
          {
            headers: {
              Authorization: token
                ? "Bearer " + token
                : "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.status === "success") {
          setParentDepartment(departmentTreeViewFormat(res.data.response_data));
        } else {
          setParentDepartment([]);
          toast.warn("Unable to fetch parent designations at the moment");
        }
      } catch (error) {
        if (error.response.data.message === "Data not found.") {
          toast.warn("No parent designation available, add one.");
        } else {
          console.log(error);
        }
      }
    };

    const getSupervisors = async () => {
      try {
        const res = await Axios.get(
          `employee/position/supervisor?project_id=${projectId}`,
          {
            headers: {
              Authorization: token
                ? "Bearer " + token
                : "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.status === "success") {
          setSupervisors(
            res.data.response_data.map((item) => ({
              label: `${item?.name} - ${item?.email}`,
              value: item.id,
            }))
          );
        } else {
          setSupervisors([]);
          toast.warn("Unable to fetch supervisors at the moment");
        }
      } catch (error) {
        if (error.response.data.message === "Data not found.") {
          toast.warn("No supervisors available, add one.");
        } else {
          console.log(error);
        }
      }
    };

    // getSupervisors();
    // getParentDepartment();
    // getSeperation();
    // getFrequency();
  }, [projectId]);

  useEffect(() => {
    const getParentDesignation = async () => {
      try {
        const res = await Axios.get(
          `designation/parent?project_id=${projectId}`,
          {
            headers: {
              Authorization: token
                ? "Bearer " + token
                : "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        if (res.data.status === "success") {
          setParentDesignation(
            designationTreeViewFormat(
              res.data.response_data,
              selectedDepartment
            )
          );
        } else {
          setParentDesignation([]);
          toast.warn("Unable to fetch parent designations at the moment");
        }
      } catch (error) {
        if (error.response.data.message === "Data not found.") {
          toast.warn("No parent designation available, add one.");
        } else {
          console.log(error);
        }
      }
    };

    // getParentDesignation();
  }, [projectId, selectedDepartment]);

  useEffect(() => {
    // if (stepData !== null) {
    //   setValue("subDepartment", stepData.department_id);
    //   setValue("position", stepData.designation_id);
    //   setValue("dutyType", stepData.duty_type);
    //   setValue(
    //     "hireDate",
    //     new Date(stepData.hire_date === undefined ? null : stepData.hire_date)
    //   );
    //   setValue(
    //     "joiningDate",
    //     new Date(stepData.join_date === undefined ? null : stepData.join_date)
    //   );
    //   setValue(
    //     "terminationDate",
    //     stepData.separation_date === null
    //       ? null
    //       : new Date(
    //           stepData.separation_date === undefined
    //             ? null
    //             : stepData.separation_date
    //         )
    //   );
    //   setValue("voluntaryTermination", parseInt(stepData.voluntary_separation));
    //   setValue(
    //     "rehireDate",
    //     stepData.re_hire_date === null
    //       ? null
    //       : new Date(
    //           stepData.re_hire_date === undefined ? null : stepData.re_hire_date
    //         )
    //   );
    //   setValue("payFrequency", stepData.pay_frequencie_id);
    //   setValue("supervisor", stepData.supervisor_id);
    //   setValue("isSupervisor", stepData.is_supervisor);
    //   setValue("reportingTo", stepData.reporting_to);
    //   setSelectedDepartment(stepData.department_id);
    // }
  }, [stepData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2}>
        {/* <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth>
                <Typography
                  color={!!formState.errors?.subDepartment ? red[700] : ""}
                >
                  Department*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.subDepartment
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.subDepartment
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={parentDepartment}
                  value={
                    field?.value !== ""
                      ? parentDepartment?.filter(
                          (option) => option?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) => {
                    field.onChange(selectedOption?.value);
                    setSelectedDepartment(selectedOption?.value);
                  }}
                />
                {!!formState.errors?.subDepartment ? (
                  <FormHelperText error>
                    {errors?.subDepartment?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="subDepartment"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth>
                <Typography
                  color={!!formState.errors?.position ? red[700] : ""}
                >
                  Position*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.position
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.position
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  isDisabled={
                    selectedDepartment === null || selectedDepartment === ""
                  }
                  className="basic-single"
                  placeholder={
                    selectedDepartment === null
                      ? "Select a Department first."
                      : "Select..."
                  }
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={parentDesignation}
                  value={
                    field?.value !== ""
                      ? parentDesignation?.filter(
                          (option) => option?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.position ? (
                  <FormHelperText error>
                    {errors?.position?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="position"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"}>
                <Typography
                  color={!!formState.errors?.dutyType ? red[700] : ""}
                >
                  Duty Type
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.dutyType
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.dutyType
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={dutyTypeOption}
                  value={
                    field?.value !== ""
                      ? dutyTypeOption?.filter(
                          (option) => option?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.dutyType ? (
                  <FormHelperText error>
                    {errors?.dutyType?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="dutyType"
            control={control}
            defaultValue=""
          />
        </Grid> */}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth variant="outlined" size={"small"}>
                <Typography
                  color={!!formState.errors?.hireDate ? red[700] : ""}
                >
                  Hiring Date*
                </Typography>
                <DateInput
                  inputProps={{ fullWidth: true }}
                  inputValue={field.value}
                  onChange={field.onChange}
                  isError={!!formState.errors?.hireDate}
                />
                {!!formState.errors?.hireDate ? (
                  <FormHelperText error>
                    {errors?.hireDate?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="hireDate"
            control={control}
            defaultValue=""
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl
                fullWidth
                variant="outlined"
                size={"small"}
                placeholder={"dd-mm-yyyy"}
              >
                <Typography
                  color={!!formState.errors?.joiningDate ? red[700] : ""}
                >
                  Joining Date*
                </Typography>
                <DateInput
                  inputProps={{ fullWidth: true }}
                  inputValue={field.value}
                  onChange={field.onChange}
                  isError={!!formState.errors?.joiningDate}
                />
                {!!formState.errors?.joiningDate ? (
                  <FormHelperText error>
                    {errors?.joiningDate?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="joiningDate"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/* <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl
                fullWidth
                variant="outlined"
                size={"small"}
                placeholder={"dd-mm-yyyy"}
              >
                <Typography
                  color={!!formState.errors?.terminationDate ? red[700] : ""}
                >
                  Separation Date
                </Typography>
                <DateInput
                  inputProps={{ fullWidth: true }}
                  inputValue={field.value}
                  onChange={field.onChange}
                  isError={!!formState.errors?.terminationDate}
                />
                {!!formState.errors?.terminationDate ? (
                  <FormHelperText error>
                    {errors?.terminationDate?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="terminationDate"
            control={control}
            defaultValue=""
          />
        </Grid> */}
        {/* <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"}>
                <Typography
                  color={
                    !!formState.errors?.voluntaryTermination ? red[700] : ""
                  }
                >
                  Voluntary Separation
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.voluntaryTermination
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.voluntaryTermination
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={separationOption}
                  value={
                    field?.value !== ""
                      ? separationOption?.filter(
                          (option) => option?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.voluntaryTermination ? (
                  <FormHelperText error>
                    {errors?.voluntaryTermination?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="voluntaryTermination"
            control={control}
            defaultValue=""
          />
        </Grid> */}
        {/* <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth variant="outlined" size={"small"}>
                <Typography
                  color={!!formState.errors?.rehireDate ? red[700] : ""}
                >
                  Re-Hire Date
                </Typography>
                <DateInput
                  inputProps={{ fullWidth: true }}
                  inputValue={field.value}
                  onChange={field.onChange}
                  isError={!!formState.errors?.rehireDate}
                />
                {!!formState.errors?.rehireDate ? (
                  <FormHelperText error>
                    {errors?.rehireDate?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="rehireDate"
            control={control}
            defaultValue=""
          />
        </Grid> */}
        {/*<Grid item lg={4} sm={6} xs={12}>*/}
        {/*    <Controller*/}
        {/*        render={({field, formState}) => (*/}
        {/*            <FormControl fullWidth variant="outlined" size={"small"}>*/}
        {/*                <Typography color={!!formState.errors?.rate ? red[700] : ""}>Rate*</Typography>*/}
        {/*                <OutlinedInput*/}
        {/*                    {...field}*/}
        {/*                    error={!!formState.errors?.rate}*/}
        {/*                    type="number"*/}
        {/*                />*/}
        {/*                {!!formState.errors?.rate ?*/}
        {/*                    <FormHelperText*/}
        {/*                        error>{errors?.rate?.message}</FormHelperText> : ""}*/}

        {/*            </FormControl>*/}
        {/*        )}*/}
        {/*        name="rate"*/}
        {/*        control={control}*/}
        {/*        defaultValue=""*/}
        {/*    />*/}
        {/*</Grid>*/}
        {/*<Grid item lg={4} sm={6} xs={12}>*/}
        {/*    <Controller*/}
        {/*        render={({field, formState}) => (*/}
        {/*            <FormControl fullWidth variant="outlined" size={"small"}>*/}
        {/*                <Typography color={!!formState.errors?.monthlyWorkHour ? red[700] : ""}>Monthly Work Hour*</Typography>*/}
        {/*                <OutlinedInput*/}
        {/*                    {...field}*/}
        {/*                    error={!!formState.errors?.monthlyWorkHour}*/}
        {/*                    type="number"*/}
        {/*                />*/}
        {/*                {!!formState.errors?.monthlyWorkHour ?*/}
        {/*                    <FormHelperText*/}
        {/*                        error>{errors?.monthlyWorkHour?.message}</FormHelperText> : ""}*/}

        {/*            </FormControl>*/}
        {/*        )}*/}
        {/*        name="monthlyWorkHour"*/}
        {/*        control={control}*/}
        {/*        defaultValue=""*/}
        {/*    />*/}
        {/*</Grid>*/}
        <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"}>
                <Typography
                  color={!!formState.errors?.payFrequency ? red[700] : ""}
                >
                  Pay Frequency*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.payFrequency
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.payFrequency
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={payFrequency}
                  value={
                    field?.value !== ""
                      ? payFrequency?.filter(
                          (option) => option?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.payFrequency ? (
                  <FormHelperText error>
                    {errors?.payFrequency?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="payFrequency"
            control={control}
            defaultValue=""
          />
        </Grid>
        {/* <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"}>
                <Typography
                  color={!!formState.errors?.supervisor ? red[700] : ""}
                >
                  Supervisor
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.supervisor
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.supervisor
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={supervisors}
                  value={
                    field?.value !== ""
                      ? supervisors?.filter(
                          (option) => option?.value === field?.value
                        )[0]
                      : null
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.supervisor ? (
                  <FormHelperText error>
                    {errors?.supervisor?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="supervisor"
            control={control}
            defaultValue=""
          />
        </Grid> */}
        {/* <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth size={"small"}>
                <Typography
                  color={!!formState.errors?.isSupervisor ? red[700] : ""}
                >
                  Is Supervisor*
                </Typography>
                <ReactSelect
                  styles={{
                    control: (base) => ({
                      ...base,
                      border: !!formState.errors?.isSupervisor
                        ? "1px solid #D32F2F"
                        : "1px solid #C4C4C4",
                      "&:hover": {
                        border: !!formState.errors?.isSupervisor
                          ? "1px solid #D32F2F"
                          : "1px solid #C4C4C4",
                      },
                    }),
                  }}
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="color"
                  options={isSupervisorOption}
                  value={
                    field?.value !== ""
                      ? isSupervisorOption?.filter(
                          (option) => option?.value === field?.value
                        )[0]
                      : setValue("isSupervisor", "No")
                  }
                  onChange={(selectedOption) =>
                    field.onChange(selectedOption?.value)
                  }
                />
                {!!formState.errors?.isSupervisor ? (
                  <FormHelperText error>
                    {errors?.isSupervisor?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="isSupervisor"
            control={control}
            defaultValue=""
          />
        </Grid> */}
        {/* <Grid item lg={4} sm={6} xs={12}>
          <Controller
            render={({ field, formState }) => (
              <FormControl fullWidth variant="outlined" size={"small"}>
                <Typography
                  color={!!formState.errors?.reportingTo ? red[700] : ""}
                >
                  Reporting To
                </Typography>
                <OutlinedInput
                  {...field}
                  error={!!formState.errors?.reportingTo}
                />
                {!!formState.errors?.reportingTo ? (
                  <FormHelperText error>
                    {errors?.reportingTo?.message}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            )}
            name="reportingTo"
            control={control}
            defaultValue=""
          />
        </Grid> */}
      </Grid>

      <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
        <Button variant="contained" onClick={onPrev}>
          Previous
        </Button>
        <Button variant="contained" type={"submit"}>
          Next
        </Button>
      </Stack>
    </form>
  );
};

export default PositionalTab;
