import {Box, Stack, Typography} from "@mui/material";

const LoadingPage = () => {
    return (
        <Box sx={{width: "100%", height: "80vh", background: "white"}}>
            <Stack alignItems={"center"} justifyContent={"center"} sx={{height: "100%", background: "rgba(255,255,255,0.5)"}}>
                <img src="/assets/images/loading.svg" alt=""/>
                <Typography variant={"h6"}>Loading ...</Typography>
            </Stack>
        </Box>
    )
}

export default LoadingPage;