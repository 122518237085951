import {
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    Modal,
    OutlinedInput,
    Paper,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { red } from "@mui/material/colors";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import useGetManageGratuity from "../../hooks/useGetManageGratuity";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    maxHeight: 800,
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const employees = [
    {
        SL: 1,
        EmpID: "1023",
        EmpName: "Sara Ahmed",
        TotalWorkingYears: 5,
        LastBasic: 60000,
        NumberOfGratuity: 2,
        Status: "Active",
    },
    {
        SL: 2,
        EmpID: "2156",
        EmpName: "Rahul Sharma",
        TotalWorkingYears: 8,
        LastBasic: 75000,
        NumberOfGratuity: 3,
        Status: "Active",
    },
    {
        SL: 3,
        EmpID: "3789",
        EmpName: "Aisha Khan",
        TotalWorkingYears: 3,
        LastBasic: 50000,
        NumberOfGratuity: 1,
        Status: "Inactive",
    },
    {
        SL: 4,
        EmpID: "4912",
        EmpName: "Imran Ali",
        TotalWorkingYears: 10,
        LastBasic: 90000,
        NumberOfGratuity: 4,
        Status: "Active",
    },
    {
        SL: 5,
        EmpID: "5643",
        EmpName: "Fiona Rahman",
        TotalWorkingYears: 6,
        LastBasic: 70000,
        NumberOfGratuity: 2,
        Status: "Active",
    },
    {
        SL: 6,
        EmpID: "6789",
        EmpName: "Khaled Hussain",
        TotalWorkingYears: 2,
        LastBasic: 45000,
        NumberOfGratuity: 1,
        Status: "Inactive",
    },
    {
        SL: 7,
        EmpID: "7321",
        EmpName: "Nadia Begum",
        TotalWorkingYears: 7,
        LastBasic: 80000,
        NumberOfGratuity: 3,
        Status: "Active",
    },
    {
        SL: 8,
        EmpID: "8456",
        EmpName: "Tariq Rahman",
        TotalWorkingYears: 4,
        LastBasic: 55000,
        NumberOfGratuity: 2,
        Status: "Inactive",
    },
    {
        SL: 9,
        EmpID: "9087",
        EmpName: "Lina Khan",
        TotalWorkingYears: 9,
        LastBasic: 85000,
        NumberOfGratuity: 3,
        Status: "Active",
    },
    {
        SL: 10,
        EmpID: "9999",
        EmpName: "Ahmed Hassan",
        TotalWorkingYears: 5,
        LastBasic: 65000,
        NumberOfGratuity: 2,
        Status: "Active",
    },
    // Add more employees as needed
];

const validationSchema = Yup.object().shape({
    employeeName: Yup.string().required("Cannot generate report without employee name"),
    month: Yup.string().required("Cannot generate report without month"),
    year: Yup.string().required("Cannot generate report without year"),
});

const ManageGratuity = () => {
    const [open, setOpen] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [selectedFund, setSelectedFund] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const menuOpen = Boolean(anchorEl);
    const handleMenuOpen = (event, selectedFund) => {
        setSelectedFund(selectedFund);
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });

    const calculateTenure = (totalMonths) => {
        let years = Math.floor(totalMonths / 12);
        const months = totalMonths % 12;

        if (months > 6) {
            years += 1;
        }

        return years;
    };

    const calculateGratuity = (basicSalary, totalMonths, daysPerMonth, maxAmount, basicPercentage) => {
        const gratuity = (15 * (basicSalary * (basicPercentage / 100)) * Math.floor(parseFloat(totalMonths) / 12)) / daysPerMonth;

        return gratuity > maxAmount * 100000 ? maxAmount * 100000 : gratuity.toFixed(2);
    };

    const onSubmit = (data) => console.log(data);

    const { data: gratuity, isLoading, isError } = useGetManageGratuity({ page: page + 1, limit: rowsPerPage, searchValue });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleExport = async () => {
        try {
            const res = await Axios.post(
                `gratuity/export`,
                {},
                {
                    headers: {
                        Authorization: "Bearer " + localStorage.getItem("token"),
                    },
                }
            );

            const a = document.createElement("a");
            a.href = `${process.env.REACT_APP_BASE_URL}/${res?.data?.file}`;
            a.download = `${process.env.REACT_APP_BASE_URL}/${res?.data?.file}`.split("/").pop();
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.log("error", error);
        }
    };

    return (
        <Paper elevation={2} sx={{ p: 2 }}>
            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography variant="h5">Manage Gratuities</Typography>

                {/* <Button
                    sx={{ mr: 1 }}
                    variant={"contained"}
                    size={"small"}
                    color={"success"}
                    onClick={handleOpen}
                >
                    Add Loan
                </Button> */}
                <Stack direction={"row"} gap={2}>
                    <form>
                        {/* Add employee search input with button */}
                        <Stack direction={"row"} spacing={2}>
                            <TextField
                                size={"small"}
                                label={"Search Employee"}
                                variant={"outlined"}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                        </Stack>
                    </form>

                    <Divider />

                    <Button sx={{ mr: 1 }} variant={"contained"} size={"small"} color={"success"} onClick={handleExport}>
                        Export Data
                    </Button>
                </Stack>
            </Stack>
            <Divider sx={{ my: 2 }}></Divider>
            <TableContainer>
                <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">SL</TableCell>
                            <TableCell align="center">Emp ID</TableCell>
                            <TableCell align="center">Emp Name</TableCell>
                            <TableCell align="center">Join Date</TableCell>
                            <TableCell align="center">Basic Salary</TableCell>
                            <TableCell align="center">{gratuity?.response_data?.data[0]?.gratuity_benefit}% of Basic Salary</TableCell>
                            <TableCell align="center">Total Working Years</TableCell>
                            <TableCell align="center">Gratuity Amount</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    {isLoading ? (
                        <TableBody>
                            <TableRow>
                                <TableCell align="center" colSpan={7}>
                                    Loading...
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {gratuity?.response_data?.data?.map((employee, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell align="center">{employee.hrm_employee_id}</TableCell>
                                    <TableCell align="center">{employee.employee_name}</TableCell>
                                    <TableCell align="center">{employee.join_date}</TableCell>
                                    <TableCell align="center">{employee.basic_salary}</TableCell>
                                    <TableCell align="center">{(employee.basic_salary * employee.gratuity_benefit) / 100}</TableCell>
                                    <TableCell align="center">
                                        {/* {employee.total_working_months} /  */}
                                        {Math.floor(parseFloat(employee.total_working_months) / 12)} years{" "}
                                        {parseFloat(employee.total_working_months) % 12} months ≈{" "}
                                        {Math.floor(parseFloat(employee.total_working_months) / 12)} years
                                    </TableCell>
                                    <TableCell align="center">
                                        <Tooltip
                                            placement="right"
                                            title={`(15 x ${(employee.basic_salary * employee.gratuity_benefit) / 100} x ${Math.floor(
                                                parseFloat(employee.total_working_months) / 12
                                            )}) / ${employee.total_working_days_per_month}`}
                                            arrow
                                        >
                                            {calculateGratuity(
                                                employee.basic_salary,
                                                employee.total_working_months,
                                                employee.total_working_days_per_month,
                                                employee.max_gratuity_amount,
                                                employee.gratuity_benefit
                                            )}{" "}
                                            /-
                                        </Tooltip>
                                    </TableCell>

                                    {/* <TableCell align="center">
                                    <Chip label={employee.Status} size="small" color={employee.Status === "Active" ? "success" : "warning"} />
                                </TableCell> */}
                                    {/* <TableCell align="right">
                                    <IconButton
                                        id="basic-button"
                                        aria-controls={menuOpen ? "basic-menu" : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={menuOpen ? "true" : undefined}
                                        onClick={(event) => handleMenuOpen(event, employee)}
                                    >
                                        <BsThreeDotsVertical />
                                    </IconButton>
                                </TableCell> */}
                                    <TableCell align="right">
                                        <Link to={`/gratuity/${employee?.employee_id}`} style={{ textDecoration: "none", color: "black" }}>
                                            <Button size="small">Details</Button>
                                        </Link>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    )}
                </Table>
                <TablePagination
                    component="div"
                    count={gratuity?.response_data?.total}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                <MenuItem onClick={handleClose}>
                    <Link to={`/gratuity/${selectedFund.EmpID}`} style={{ textDecoration: "none", color: "black" }}>
                        View Details
                    </Link>
                </MenuItem>
                <MenuItem onClick={handleClose}>Set {selectedFund.Status === "Active" ? "Inactive" : "Active"}</MenuItem>
            </Menu>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Grant Loan
                    </Typography>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={2} sx={{ my: 2 }} alignItems="center" justifyContent="end">
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography color={!!formState.errors?.position ? red[700] : ""}>Employee Name*</Typography>
                                            <Select {...field} error={!!formState.errors?.position}>
                                                <MenuItem value={"intern software engineer"}>Intern Software Engineer</MenuItem>
                                                <MenuItem value={"trainee software engineer"}>Trainee Software Engineer</MenuItem>
                                                <MenuItem value={"associate software engineer"}>Associate Software Engineer</MenuItem>
                                                <MenuItem value={"software engineer"}>Software Engineer</MenuItem>
                                                <MenuItem value={"senior software engineer"}>Senior Software Engineer</MenuItem>
                                            </Select>
                                            {!!formState.errors?.position ? <FormHelperText error>{errors?.position?.message}</FormHelperText> : ""}
                                        </FormControl>
                                    )}
                                    name="position"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography color={!!formState.errors?.position ? red[700] : ""}>Permitted By*</Typography>
                                            <Select {...field} error={!!formState.errors?.position}>
                                                <MenuItem value={"intern software engineer"}>Intern Software Engineer</MenuItem>
                                                <MenuItem value={"trainee software engineer"}>Trainee Software Engineer</MenuItem>
                                                <MenuItem value={"associate software engineer"}>Associate Software Engineer</MenuItem>
                                                <MenuItem value={"software engineer"}>Software Engineer</MenuItem>
                                                <MenuItem value={"senior software engineer"}>Senior Software Engineer</MenuItem>
                                            </Select>
                                            {!!formState.errors?.position ? <FormHelperText error>{errors?.position?.message}</FormHelperText> : ""}
                                        </FormControl>
                                    )}
                                    name="position"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Loan Details</Typography>
                                            <TextField multiline rows={3} {...field} error={!!formState.errors?.startDate} />

                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>{errors?.employeeName?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Approve Date*</Typography>
                                            <OutlinedInput {...field} type={"date"} error={!!formState.errors?.startDate} />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>{errors?.employeeName?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Repayment From*</Typography>
                                            <OutlinedInput {...field} type={"date"} error={!!formState.errors?.startDate} />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>{errors?.employeeName?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Amount*</Typography>
                                            <OutlinedInput {...field} type={"number"} error={!!formState.errors?.startDate} />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>{errors?.employeeName?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Percentage (%)*</Typography>
                                            <OutlinedInput {...field} type={"number"} error={!!formState.errors?.startDate} />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>{errors?.employeeName?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Installment Period*</Typography>
                                            <OutlinedInput {...field} type={"number"} error={!!formState.errors?.startDate} />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>{errors?.employeeName?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Repayment Total*</Typography>
                                            <OutlinedInput {...field} type={"number"} error={!!formState.errors?.startDate} />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>{errors?.employeeName?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl fullWidth variant="outlined" size="small">
                                            <Typography color={!!formState.errors?.employeeName ? red[700] : ""}>Installment ($)*</Typography>
                                            <OutlinedInput {...field} type={"number"} error={!!formState.errors?.startDate} />
                                            {!!formState.errors?.employeeName ? (
                                                <FormHelperText error>{errors?.employeeName?.message}</FormHelperText>
                                            ) : (
                                                ""
                                            )}
                                        </FormControl>
                                    )}
                                    name="employeeName"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field, formState }) => (
                                        <FormControl size={"small"} fullWidth>
                                            <Typography color={!!formState.errors?.position ? red[700] : ""}>Status*</Typography>
                                            <Select {...field} error={!!formState.errors?.position}>
                                                <MenuItem value={"intern software engineer"}>Intern Software Engineer</MenuItem>
                                                <MenuItem value={"trainee software engineer"}>Trainee Software Engineer</MenuItem>
                                                <MenuItem value={"associate software engineer"}>Associate Software Engineer</MenuItem>
                                                <MenuItem value={"software engineer"}>Software Engineer</MenuItem>
                                                <MenuItem value={"senior software engineer"}>Senior Software Engineer</MenuItem>
                                            </Select>
                                            {!!formState.errors?.position ? <FormHelperText error>{errors?.position?.message}</FormHelperText> : ""}
                                        </FormControl>
                                    )}
                                    name="position"
                                    control={control}
                                    defaultValue=""
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button sx={{ mt: 3 }} variant="contained" type="submit" fullWidth>
                                    Grant Loan
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>
        </Paper>
    );
};

export default ManageGratuity;
