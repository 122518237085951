import { useQuery } from "@tanstack/react-query";
import { getMessageNotification } from "../api/fetchers/commonFetcher";

const useGetMessageNotification = ({ enabled = true }) => {
    return useQuery({
        queryKey: ["Message Notification"],
        queryFn: () => getMessageNotification(),
        enabled: enabled,
    });
};

export default useGetMessageNotification;
