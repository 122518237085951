import Routes from "./routes";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ProvideAuth } from "./hooks/useAuth";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import CustomizeTheme from "./theme/CustomizeTheme";
import "react-datepicker/dist/react-datepicker.css";
import { EmployeeContextProvider } from "./context/employee/EmployeeContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const queryClient = new QueryClient()



function App() {
  return (
    <ThemeProvider theme={CustomizeTheme}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <BrowserRouter>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
            <ProvideAuth>
              <EmployeeContextProvider>
                <Routes />
              </EmployeeContextProvider>
            </ProvideAuth>
          </BrowserRouter>
        </HelmetProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default App;
