import { useQuery } from '@tanstack/react-query';
import { getAllEmployee } from '../api/fetchers/employeeFetcher';

const useGetAllEmployees = (isExcludeUser = false) => {
    return useQuery({
        queryKey: ['all-employee', isExcludeUser],
        queryFn: () => getAllEmployee(isExcludeUser),
    });
};

export default useGetAllEmployees;
