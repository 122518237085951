import { useQuery } from "@tanstack/react-query";
import { getAllBranches } from "../api/fetchers/dropdownData";
import { getManageProvidentFund } from "../api/fetchers/providentFund";

const useGetManageProvidentFund = ({ page, rowsPerPage, searchValue }) => {
    return useQuery({
        queryKey: ["manage-provident-fund", page, rowsPerPage, searchValue],
        queryFn: () => getManageProvidentFund(page, rowsPerPage, searchValue),
    });
};

export default useGetManageProvidentFund;
