import {Typography} from "@mui/material";

let depth = -1;
let newArray = [];

const dashAdder = (count, name) => {
    let dashes = ""
    for (let i = 0; i < count; i++) {
        dashes += `    `
    }
    return <Typography sx={{ml: count*3}}>{`${dashes} — ${name}`}</Typography>
}

export function departmentTreeViewFormat(nestedArray) {
    if(depth === -1){
        newArray = []
    }
    depth += 1;
    nestedArray.forEach(item => {
        newArray = [...newArray, {label: depth !== 0 ? dashAdder(depth, item.name) : item.name, value: item.id}];
        if (item.child_department.length > 0) {
            departmentTreeViewFormat(item.child_department);
        }
    })
    depth -= 1;
    return newArray;
}

export function designationTreeViewFormat(nestedArray, departmentFilter = null) {
    if(depth === -1){
        newArray = []
    }
    depth += 1;
    nestedArray.forEach(item => {
        newArray = [...newArray, {label: depth !== 0 ? dashAdder(depth, item.name) : item.name, value: item.id, department: item.department_id}];
        if (item.child_designations.length > 0) {
            designationTreeViewFormat(item.child_designations);
        }
    })
    depth -= 1;
    return departmentFilter === null ? newArray : newArray.filter(item => item.department === departmentFilter);
}