import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Button,
    FormControl,
    FormHelperText,
    OutlinedInput,
    Stack,
    Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Axios from '../../api/Axios';
import ApiErrorMessage from '../../components/error/ApiErrorMessage';
import { useAuth } from '../../hooks/useAuth';

const validationSchema = Yup.object().shape({
    title: Yup.string().required('Loan type name is required').nullable(),
});

const transferTypeList = [
    { label: 'Department Transfer', value: 'Department Transfer' },
    { label: 'SBO Transfer', value: 'SBO Transfer' },
    { label: 'Location Transfer', value: 'Location Transfer' },
];

const EditLoanType = ({ handleAddSubmit, handleError, id }) => {
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });
    const { token } = useAuth();

    //Get Edit Data
    const getEditManageData = async () => {
        try {
            const res = await Axios.get(
                `/type-of-loan/show/${id}`,

                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );
            if (res.data.status === 'success') {
                const { title } = res.data.response_data;

                setValue('title', title);
            }
        } catch (error) {
            console.log('branch list', error);
            toast.warn('Something went wrong');
        }
    };

    useEffect(() => {
        getEditManageData();
    }, [id]);

    return (
        <form onSubmit={handleSubmit(handleAddSubmit)}>
            <Controller
                render={({ field, formState }) => (
                    <FormControl fullWidth size={'small'} sx={{ mt: 2, minHeight: 150 }}>
                        <Typography color={!!formState.errors?.title ? red[700] : ''}>
                            Loan Type Name*
                        </Typography>
                        <OutlinedInput
                            {...field}
                            error={!!formState.errors?.title}
                            placeholder="Enter type name"
                            maxRows={12}
                        />
                        {!!formState.errors?.title ? (
                            <FormHelperText error>{errors?.title?.message}</FormHelperText>
                        ) : (
                            ''
                        )}
                    </FormControl>
                )}
                name="title"
                control={control}
                defaultValue=""
            />

            <ApiErrorMessage handleError={handleError} />
            <Stack
                justifyContent={'end'}
                direction={'row'}
                flexWrap={'wrap'}
                gap={1.5}
                container
                spacing={2}
                sx={{ my: 2 }}
            >
                <Button
                    variant="contained"
                    onClick={() => reset()}
                    color="inherit"
                    sx={{ minWidth: 130 }}
                >
                    Reset
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    sx={{ minWidth: 130, ml: '0 !important' }}
                >
                    Update
                </Button>
            </Stack>
        </form>
    );
};

export default EditLoanType;
