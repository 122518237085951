import { createTheme } from "@mui/material";

//For Breakpoints
const theme = createTheme();

const CustomizeTheme = createTheme(theme, {
    palette: {
        primary: {
            main: "#2B96D3", //change theme client theme color if you want
        },
        secondary: {
            main: "#93BD53",
        },
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    ".MuiInputBase-root": {
                        minHeight: "40px",
                        borderRadius: "4px",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#0D0C11",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "14px",
                            minHeight: "38px",
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.MuiInputBase-root": {
                        minHeight: "40px",
                        borderRadius: "4px",
                        fontWeight: 400,
                        fontSize: "16px",
                        color: "#0D0C11",
                        [theme.breakpoints.down("sm")]: {
                            fontSize: "14px",
                            minHeight: "38px",
                        },
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    minHeight: "40px",
                    [theme.breakpoints.down("sm")]: {
                        fontSize: "14px",
                        minHeight: "38px",
                    },
                },
            },
        },
    },
});

export default CustomizeTheme;
