import { useQuery } from "@tanstack/react-query";
import { getEmployeeSelfDocs } from "../api/fetchers/employeeDocFetcher";

const useGetSelfDocs = ({ page, rowsPerPage, id }) => {
    return useQuery({
        queryKey: ["employee-slef-docs", page, rowsPerPage, id],
        queryFn: () => getEmployeeSelfDocs(page, rowsPerPage, id),
    });
};

export default useGetSelfDocs;
