import { useQuery } from "@tanstack/react-query";
import { getLeaveEncashment } from "../api/fetchers/gratuity";

const useGetLeaveEncashment = ({ page, limit }) => {
    return useQuery({
        queryKey: ["manage-gratuity", page, limit],
        queryFn: () => getLeaveEncashment(page, limit),
    });
};

export default useGetLeaveEncashment;
