import { useQuery } from "@tanstack/react-query";
import { getEmployeeProfile } from "../api/fetchers/commonFetcher";

const useGetEmployeeProfile = ({ id }) => {
    return useQuery({
        queryKey: ["employees-profile"],
        queryFn: () => getEmployeeProfile(id),
        enabled: !!id,
    });
};

export default useGetEmployeeProfile;
