import {Controller, useForm} from "react-hook-form";
import {
    Button,
    FormControl,
    FormHelperText, Grid, MenuItem,
    OutlinedInput, Select,
    Stack, TextField, Typography
} from "@mui/material";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {red} from "@mui/material/colors";
import {useEffect} from "react";
import countryList from "../../_mockData/countries"

const validationSchema = Yup.object().shape({

});

const EducationalInformationTab = ({onSubmit, employeeData, onPrev}) => {
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    useEffect(() => {
        reset({...employeeData})
    }, [])

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.firstname ? red[700]: ""}>University</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.firstname}
                                />
                                {!!formState.errors?.firstname ?
                                    <FormHelperText
                                        error>{errors?.firstname?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="firstname"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.lastname ? red[700]: ""}>Degree</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.lastname}
                                />
                                {!!formState.errors?.lastname ?
                                    <FormHelperText
                                        error>{errors?.lastname?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="lastname"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.email ? red[700]: ""}>CGPA</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.email}
                                />
                                {!!formState.errors?.email ?
                                    <FormHelperText
                                        error>{errors?.email?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="email"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.city ? red[700]: ""}>Comment</Typography>
                                <TextField
                                    {...field}
                                    error={!!formState.errors?.city}
                                    multiline
                                    minRows={2}
                                />
                                {!!formState.errors?.city ?
                                    <FormHelperText
                                        error>{errors?.city?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="city"
                        control={control}
                        defaultValue=""
                    />
                </Grid>

            </Grid>

            <Stack direction="row" justifyContent="space-between" sx={{mt: 2}}>
                <Button variant="contained" onClick={onPrev}>Previous</Button>
                <Button variant="contained" type={"submit"}>Next</Button>
            </Stack>
        </form>
    )
}

export default EducationalInformationTab;