import { useQuery } from "@tanstack/react-query";
import { getProvidentFundDetails } from "../api/fetchers/providentFund";

const useGetProvidentFundDetails = ({ empId }) => {
    return useQuery({
        queryKey: ["provident-fund details", empId],
        queryFn: () => getProvidentFundDetails(empId),
        enabled: !!empId,
    });
};

export default useGetProvidentFundDetails;
