import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { Button, FormControl, FormHelperText, Grid, OutlinedInput, Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ApiErrorMessage from "../../components/error/ApiErrorMessage";
import { useAuth } from "../../hooks/useAuth";

const validationSchema = Yup.object().shape({
    branchName: Yup.string().required("Year range is required"),
});

const AddChallanYear = ({ handleAddSubmit, handleError }) => {
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    });
    const { token } = useAuth();
    const navigate = useNavigate();

    return (
        <>
            <form onSubmit={handleSubmit(handleAddSubmit)}>
                <Controller
                    render={({ field, formState }) => (
                        <FormControl fullWidth variant="outlined" size="small">
                            <Typography color={!!formState.errors?.branchName ? red[700] : ""}>Year Range*</Typography>
                            <OutlinedInput {...field} error={!!formState.errors?.branchName} placeholder="2024-2025" />
                            {!!formState.errors?.branchName ? <FormHelperText error>{errors?.branchName?.message}</FormHelperText> : ""}
                        </FormControl>
                    )}
                    name="branchName"
                    control={control}
                    defaultValue=""
                />
                <ApiErrorMessage handleError={handleError} />
                <Grid container spacing={2} sx={{ my: 2 }}>
                    <Grid item xs={6}>
                        <Button variant="contained" onClick={() => reset()} color="inherit" fullWidth>
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button variant="contained" type="submit" fullWidth>
                            Add
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default AddChallanYear;
