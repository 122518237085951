import { useQuery } from "@tanstack/react-query";
import { getEmployeeDocs } from "../api/fetchers/employeeDocFetcher";

const useGetEmployeeDocs = ({ page, rowsPerPage }) => {
    return useQuery({
        queryKey: ["employee-docs", page, rowsPerPage],
        queryFn: () => getEmployeeDocs(page, rowsPerPage),
    });
};

export default useGetEmployeeDocs;
