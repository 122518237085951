import {
  Dialog,
  DialogTitle,
  Stack,
  Typography,
  Divider,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Slide,
} from "@mui/material";
import { forwardRef } from "react";
import { AiFillWarning } from "react-icons/ai";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteModal({
  dialogOpen,
  handleDialogClose,
  handleDelete,
  title,
  isBodyCustomText,
  modifyText,
  customText,
  agreeButtonText,
  disAgreeButtonText,
}) {
  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleDialogClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          direction={"row"}
        >
          <AiFillWarning size={24} color={"#eed202"} />
          <Typography sx={{ ml: 1 }} variant={"h5"}>
            {title}
          </Typography>
        </Stack>
      </DialogTitle>
      <Divider></Divider>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {isBodyCustomText ? (
            <Typography>{customText}</Typography>
          ) : (
            <Typography>
              Are you sure you want to delete this {modifyText} ? Once deleted,
              you will not get this {modifyText} data back.
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant={"contained"}
          color={"error"}
          onClick={handleDialogClose}
        >
          {agreeButtonText}
        </Button>
        <Button variant={"contained"} color={"success"} onClick={handleDelete}>
          {disAgreeButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
DeleteModal.defaultProps = {
  title: "Confirm Delete!",
  isBodyCustomText: false,
  modifyText: "module name",
  customText: "",
  agreeButtonText:"Disagree",
  disAgreeButtonText:"Agree",
};

export default DeleteModal;
