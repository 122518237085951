import {
    Box,
    Button, ButtonGroup,
    Checkbox,
    Divider, FormControl,
    FormControlLabel,
    FormGroup, FormHelperText, Grid,
    IconButton, MenuItem,
    Modal, OutlinedInput,
    Paper, Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead, TablePagination,
    TableRow, TextField,
    Typography
} from "@mui/material";
import {FaEdit} from "react-icons/fa"
import {MdDeleteForever} from "react-icons/md"
import {useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {red} from "@mui/material/colors";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    maxHeight: 800,
    overflowY: "scroll",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const validationSchema = Yup.object().shape({

});

const EducationalInformationManage = ({onSubmit}) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const {
        control,
        handleSubmit,
        formState: {errors},
        reset
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });
    //const onSubmit = (data) => console.log(data);

    return (<Box elevation={2} sx={{p: 2}}>
        <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
            <Typography variant="h5">Manage Candidate</Typography>



        </Stack>
        <Divider sx={{my: 2}}></Divider>
        <TableContainer>
            <Table sx={{minWidth: 650}} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">SL</TableCell>
                        <TableCell align="center">Candidate Id</TableCell>
                        <TableCell align="center">Obtained Degree</TableCell>
                        <TableCell align="center">University</TableCell>
                        <TableCell align="center">CGPA</TableCell>
                        <TableCell align="center">Comment</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell align="left">1</TableCell>
                        <TableCell align="center">d87a8s97</TableCell>
                        <TableCell align="center">Bachelors</TableCell>
                        <TableCell align="center">Dhaka</TableCell>
                        <TableCell align="center">3.50/-</TableCell>
                        <TableCell align="center">{" "}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={100}
                page={1}
                // onPageChange={handleChangePage}
                rowsPerPage={10}
                // onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </TableContainer>
    </Box>)
}

export default EducationalInformationManage;