import { Avatar, Box, IconButton } from '@mui/material';
import React from 'react';
import { FaFile } from 'react-icons/fa';
import { renderFile } from '../../utils/utils';

const FileViewer = ({ url = '' }) => {
    // Lists of   image and video file extensions
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg'];
    const videoExtensions = ['mp4', 'webm', 'ogg', 'mov', 'avi', 'mkv'];

    // Function to get the file extension
    const getFileExtension = (url) => {
        return url?.split('.')?.pop()?.toLowerCase();
    };

    // Determine file type
    const renderAttachment = (url) => {
        const extension = getFileExtension(url);
        const getUrl = renderFile(url);
        if (imageExtensions.includes(extension)) {
            return (
                <Box component={'a'} href={getUrl} target="_blank">
                    <Avatar
                        src={getUrl}
                        variant="square"
                        sx={{ height: 'auto', width: 'auto', maxWidth: 200, maxHeight: 150 }}
                    />
                </Box>
            );
        } else if (videoExtensions.includes(extension)) {
            return (
                <video controls style={{ maxWidth: '100%', maxHeight: '300px' }}>
                    <source src={url} type={`video/${extension}`} />
                    Your browser does not support the video tag.
                </video>
            );
        } else if (url) {
            return (
                <IconButton LinkComponent={'a'} href={getUrl} target="_blank">
                    <FaFile size={24} />
                </IconButton>
            );
        } else {
            return null;
        }
    };

    return <div>{renderAttachment(url)}</div>;
};

export default FileViewer;
