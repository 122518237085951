import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const CircularSpinner = ({size=64}) => {
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <CircularProgress size={size} thickness={2} color="warning" />
        </Box>
    );
};

export default CircularSpinner;
