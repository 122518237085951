// @ts-nocheck
import { useTheme } from '@emotion/react';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { FaFileContract } from 'react-icons/fa';
import { LiaTimesSolid } from 'react-icons/lia';

function FileUploadReport({
    files,
    setFiles,
    path,
    maxFiles = 1,
    acceptFiles = {
        'image/*': [],
        'application/pdf': [],
        'application/msword': ['.doc'],
        'application/vnd.ms-excel': [],
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
        'text/plain': ['.txt'],
    },
}) {
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: acceptFiles,
        onDrop: (acceptedFiles) => {
            if (maxFiles <= 1) {
                setFiles(
                    acceptedFiles?.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )
                );
            } else {
                setFiles([
                    ...files,
                    ...acceptedFiles?.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    ),
                ]);
            }
        },
        maxFiles: maxFiles,
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
            <ul>
                {errors.map((e) => (
                    <li key={e.code}>
                        {e.message} <Chip label="Rejected" color="error" size="small" />{' '}
                    </li>
                ))}
            </ul>
        </li>
    ));

    //Context
    const theme = useTheme();

    //Remove the upload files
    const handleRemoveImage = (index) => {
        const removeFile = files?.filter((item, row) => row !== index);
        setFiles(removeFile);
    };

    return (
        <>
            <Box
                {...getRootProps({ className: 'dropzone' })}
                sx={{
                    position: 'relative',
                    width: 1,
                    height: 140,
                    border: `1.5px dashed ${theme?.palette?.primary?.main}`,
                    borderRadius: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    cursor: 'pointer',
                }}
            >
                <input {...getInputProps()} />
                <Typography>Drag & Drop Your File</Typography>
                <Button variant="outlined" sx={{ mt: 1 }}>
                    Select
                </Button>
            </Box>
            {files?.length > 0 ? (
                <>
                    <Typography sx={{ fontWeight: 600, mt: 2 }}>Uploaded Files:</Typography>
                    <Stack direction={'row'} gap={2} mt={2}>
                        {files?.map((item, index) => {
                            console.log('{files?.map - item:', item);
                            if (item?.type?.includes('image')) {
                                return (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: 120,
                                            height: 120,
                                            border: '1px solid #d7d7d7',
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Avatar
                                            src={item?.preview}
                                            variant="square"
                                            sx={{ width: 1, height: 1 }}
                                        />
                                        <Tooltip title="Remove">
                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    right: '0',
                                                    top: '0',
                                                    background: '#d7d7d7',
                                                    width: 30,
                                                    height: 30,
                                                }}
                                                onClick={() => handleRemoveImage(index)}
                                            >
                                                <LiaTimesSolid />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                );
                            } else if (item?.type?.includes('video')) {
                                return (
                                    <Box
                                        sx={{
                                            position: 'relative',
                                            width: 120,
                                            height: 120,
                                            border: '1px solid #d7d7d7',
                                            borderRadius: 1,
                                        }}
                                    >
                                        <Box
                                            component="video"
                                            autoPlay
                                            loop
                                            muted
                                            poster="https://assets.codepen.io/6093409/river.jpg"
                                            sx={{ width: 1, height: 1, objectFit: 'cover' }}
                                        >
                                            <source src={item?.preview} type="video/mp4" />
                                        </Box>
                                        <Tooltip title="Remove">
                                            <IconButton
                                                sx={{
                                                    position: 'absolute',
                                                    right: '0',
                                                    top: '0',
                                                    background: '#d7d7d7',
                                                    width: 30,
                                                    height: 30,
                                                }}
                                                onClick={() => handleRemoveImage(index)}
                                            >
                                                <LiaTimesSolid />
                                            </IconButton>
                                        </Tooltip>
                                    </Box>
                                );
                            }

                            return (
                                <Box
                                    sx={{
                                        position: 'relative',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 120,
                                        height: 120,
                                        border: '1px solid #d7d7d7',
                                        borderRadius: 1,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width: 1,
                                            height: 1,
                                            objectFit: 'cover',
                                            position: 'relative',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <FaFileContract size={28} />
                                    </Box>
                                    <Tooltip title="Remove">
                                        <IconButton
                                            sx={{
                                                position: 'absolute',
                                                right: '0',
                                                top: '0',
                                                background: '#d7d7d7',
                                                width: 30,
                                                height: 30,
                                            }}
                                            onClick={() => handleRemoveImage(index)}
                                        >
                                            <LiaTimesSolid />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            );
                        })}
                    </Stack>
                    <Divider sx={{ mt: 1 }} />
                </>
            ) : null}
            {fileRejectionItems ? <ul>{fileRejectionItems}</ul> : null}
        </>
    );
}

export default FileUploadReport;
