import { useQuery } from "@tanstack/react-query";
import { getGratuityDetails } from "../api/fetchers/gratuity";

const useGetGratuityDetails = ({ empId }) => {
    return useQuery({
        queryKey: ["gratuity-details", empId],
        queryFn: () => getGratuityDetails(empId),
        enabled: !!empId,
    });
};

export default useGetGratuityDetails;
