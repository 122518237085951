import Axios from "./Axios";
import { toast } from "react-toastify";
import { handleErrorMessage } from "../helpers/errorMessageHandler";

//get projects for filtering
export async function getFilteringProjects(
  setLoading,
  token,
  setFunction,
  clientIdForFiltering = null
) {
  setLoading(true);
  try {
    const res = await Axios.post(
      `/all/project`,
      { hrm_client_id: clientIdForFiltering },
      {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (res.data.status === "success") {
      setFunction(
        res.data.response_data.map((project) => ({
          label: project.name,
          value: project.id,
        }))
      );
    } else {
      setFunction([]);
      toast.warn("Projects for filtering is not available");
    }
    setLoading(false);
  } catch (error) {
    if (error.response.data.message === "Data not found.") {
      toast.warn("Projects for filtering is not available");
      setFunction([]);
    } else {
      console.log(error);
    }
    setLoading(false);
  }
}

//get clients for filtering
export async function getFilteringClients(setLoading, token, setFunction) {
  setLoading(true);
  try {
    const res = await Axios.post(
      `employee/all`,
      {},
      {
        headers: {
          Authorization: token
            ? "Bearer " + token
            : "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    if (res.data.status === "success") {
      setFunction(
        res.data.response_data.map((client) => ({
          label: client.name,
          value: client.id,
        }))
      );
    } else {
      setFunction([]);
      toast.warn("Clients for filtering is not available");
    }
    setLoading(false);
  } catch (error) {
    if (error.response.data.message === "Data not found.") {
      toast.warn("Clients for filtering is not available");
      setFunction([]);
    } else {
      console.log(error);
    }
    setLoading(false);
  }
}

//Add Employee Post Data
export async function AddEmployeePostData(
  data,
  token,
  setValue,
  nextStep,
  endPoint,
  employeeIdSetter,
  loading,
  setLoading,
  setUserId,
  emailSetter
) {
  try {
    setLoading(true);
    const res = await Axios.post(`/employee/${endPoint}`, data, {
      headers: {
        Authorization: token
          ? "Bearer " + token
          : "Bearer " + localStorage.getItem("token"),
      },
    });
    console.log("employee res:", res);
    if (res?.data?.status === "success") {
      if (nextStep >= 11) {
        window.location.href = "/employees/manage";
      }
      if (nextStep === 10 || nextStep === 1) {
        setUserId(res.data.user_id);
        console.log(res.data.user_id);
      }
      if (nextStep === 1) {
        if (window.location.pathname === "/employees/add") {
          window.location.href = `/employees/edit/${res.data.employee_id}`;
        }
        setValue(1);
      }
      if (nextStep === 5) {
        // window.location.href = "/employees/manage";
      }
      // setValue(nextStep)
      employeeIdSetter(res.data.employee_id);

      if (res.data.step === 0) {
        emailSetter(data.email);
        console.log(data.email);
      }
      employeeIdSetter(res.data.employee_id);
      setUserId(res.data.user_id);
    } else if (res.data.success === "200") {
      toast.info("You don't have  permissions, contact admin");
      window.location.href = "/unauthorized";
    }
    setLoading(false);
  } catch (error) {
    console.log("employee update error:", error);
    setLoading(false);
    if (error?.response?.data?.message === "Empty field!") {
      setValue(nextStep);
    }
    if (nextStep === 10) {
      setUserId(error.response.data.user_id);
    }
    handleErrorMessage(error);
  }
}
