import { toast } from "react-toastify";
import Axios from "../Axios";

export const getAllBranches = async () => {
    try {
        const res = await Axios.get(`branch/all`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllDepartments = async () => {
    try {
        const res = await Axios.get(`department/all`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllDesignations = async () => {
    try {
        const res = await Axios.get(`designation/all`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllPayFrequencies = async () => {
    try {
        const res = await Axios.get(`pay-frequency`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllRoles = async () => {
    try {
        const res = await Axios.get(`role/all`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllJobContracts = async () => {
    try {
        const res = await Axios.get(`job-contract/all`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllShifts = async () => {
    try {
        const res = await Axios.get(`office-shift/all`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllDocumentTypes = async (status) => {
    try {
        const res = await Axios.get(`document-type/all?status=${status}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getAllSupervisors = async () => {
    try {
        const res = await Axios.get(`supervisor-list`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
