import { useAuth } from "../../hooks/useAuth";
import { Navigate } from "react-router-dom";

const AuthGuard = ({ children, redirectRoute }) => {
    const auth = useAuth();
    if (auth.token) {
        return <>{children}</>;
    } else {
        const token = localStorage.getItem("token");
        if (token !== null) {
            return <>{children}</>;
        }
    }
    return <Navigate to={redirectRoute} />;
};

export default AuthGuard;
