import {
    Alert,
    AlertTitle,
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { AiFillFileExcel, AiFillPrinter } from 'react-icons/ai';
import { BsEye, BsFillCheckCircleFill } from 'react-icons/bs';
import { GrStatusCritical } from 'react-icons/gr';
import { MdOutlineCancel } from 'react-icons/md';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Axios from '../../api/Axios';
import FileViewer from '../../components/attachment/FileViewer';
import DataNotFound from '../../components/DataNotFound';
import NewFormModal from '../../components/Modal/NewFormModal';
import LoanMainStatus from '../../components/status/LoanMainStatus';
import LoanStatus from '../../components/status/LoanStatus';
import { ROLENAME } from '../../helpers/commonUses';
import { useAuth } from '../../hooks/useAuth';
import useCompanyInfo from '../../hooks/useCompanyInfo';
import { generateLoanTranscationPDF } from '../../utils/pdf-generate/invoice-generate';
import {
    checkLoanMainStatus,
    checkLoanStatus,
    createUpdatedDateTime,
    dateFormat,
    displayAmountText,
    exportToExcelForLoanTranscation,
    exportToExcelForTaxCertificate,
    loanMainStatus,
} from '../../utils/utils';
import ApproveLoan from './ApproveLoan';
import ApproveLoanStatus from './ApproveLoanStatus';
import moment from 'moment';

const LoanManageView = () => {
    const [viewData, setViewData] = useState(null);
    const [stepperData, setStepperData] = useState([]);
    const [loanTransctionData, setLoanTransctionData] = useState([]);
    const [approveModal, setApproveModal] = useState(false);
    const [approveLoanModal, setApproveLoanModal] = useState(false);
    const [shouldFetchData, setShouldFetchData] = useState(false);
    const [error, setError] = useState({
        isError: false,
        message: null,
    });
    const { companyInfoForPdf } = useCompanyInfo();

    //React router
    const { id } = useParams();

    //Context
    const { role, token } = useAuth();

    //Check loan status
    const isDisabledStatus = checkLoanStatus(viewData?.loan_status);
    const isDisabledLoanStatus = checkLoanMainStatus(
        viewData?.approval_status,
        viewData?.loan_status
    );

    //approve Modal
    const handleOpenApprove = () => {
        setApproveModal(true);
    };
    const handleModalCloseApprove = () => {
        setApproveModal(false);
    };

    //Approve Loan Modal
    const handleOpenApproveLoan = () => {
        setApproveLoanModal(true);
    };
    const handleModalCloseApproveLoan = () => {
        setApproveLoanModal(false);
    };

    //Handle generate pdf
    const handleGeneratePdf = () => {
        //Format Data
        const storeData = [];

        if (loanTransctionData?.length > 0) {
            loanTransctionData?.forEach((item, index) => {
                storeData.push({
                    column1: index + 1,
                    column2: dateFormat({
                        date: `${item?.month} ${item?.year}`,
                        dateFormat: 'MM YYYY',
                        format: 'MMM YYYY',
                    }),
                    column3: displayAmountText(item?.paid_amount),
                    column4: createUpdatedDateTime({
                        createDate: item?.created_at,
                        updateDate: item?.updated_at,
                    }),
                });
            });
        }

        const invoiceData = {
            //Company info
            companyInfoForPdf: companyInfoForPdf,

            //Employee Info
            cards: [
                {
                    title: 'Employee Information:',
                    items: [
                        { label: 'Loan ID', value: ` : ${viewData?.response_data?.id}` },
                        {
                            label: 'Employee',
                            value: ` : ${viewData?.response_data?.employee_name} (${viewData?.response_data?.hrm_employee_id})`,
                        },
                        {
                            label: 'Designation',
                            value: ` : ${viewData?.response_data?.designation_name}`,
                        },
                        {
                            label: 'Loan Type',
                            value: ` : ${viewData?.response_data?.loan_type_title} `,
                        },
                        {
                            label: 'Loan Amount',
                            value: ` : ${displayAmountText(viewData?.response_data?.loan_amount)} `,
                            isBold: true,
                        },
                    ],
                },
            ],

            //Report title
            invoiceForLabel: {
                invoiceType: `Loan Transcation History`,
            },

            //Table header
            tableLabels: {
                column1: 'SL',
                column2: 'Date',
                column3: 'Paid Amount',
                column4: 'Create At',
            },

            items: storeData,
        };

        generateLoanTranscationPDF(invoiceData);
    };

    //Excel generate
    const handleExportExcel = () => {
        const title = `Loan Transcation History`;
        const card1 = {
            title: 'Employee Information:',
            items: [
                `Loan ID: ${viewData?.response_data?.id}`,
                `Employee: ${viewData?.response_data?.employee_name} (${viewData?.response_data?.hrm_employee_id})`,
                `Designation: ${viewData?.response_data?.designation_name}`,
                `Loan Type: ${viewData?.response_data?.loan_type_title}`,
                `Loan Amount: ${displayAmountText(viewData?.response_data?.loan_amount)}`,
            ],
        };

        const mainTable = {
            headers: ['Date', 'Paid Amount', 'Create At'],
            data: loanTransctionData?.map((item) => {
                return [
                    dateFormat({
                        date: `${item?.month} ${item?.year}`,
                        dateFormat: 'MM YYYY',
                        format: 'MMM YYYY',
                    }),
                    displayAmountText(item?.paid_amount),
                    createUpdatedDateTime({
                        createDate: item?.created_at,
                        updateDate: item?.updated_at,
                    }),
                ];
            }),
        };

        exportToExcelForLoanTranscation(
            `Loan-transcation-history-Report-${moment().format('DD-MM-YYYY-hh:mm:ss')}`,
            title,
            card1,
            mainTable
        );
    };

    //Get View Data
    const getViewData = async (signal) => {
        try {
            const res = await Axios.get(
                `/loan-request/show/${id}`,

                {
                    signal: signal,
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res.data.status === 'success') {
                setViewData(res?.data);
                setError({
                    isError: false,
                    message: null,
                });
            } else if (res.data.success === '200') {
                toast.info("You don't have transfer view permissions, contact admin");
            } else {
                toast.warn('Something went wrong');
            }
        } catch (error) {
            console.log('error', error);
            toast.warn('Something went wrong');
            setError({
                isError: true,
                message: error?.response?.data?.message,
            });
        }
    };

    //Get Loan Transcation Data
    const getLoanTranscationList = async () => {
        try {
            const res = await Axios.get(
                `/manage-loan/trans-details/${id}`,

                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );
            console.log('getAnnouncementList - res:', res);
            if (res.data.status === 'success') {
                setLoanTransctionData(res?.data?.response_data);
            } else {
                toast.warn('Something went wrong');
                setLoanTransctionData([]);
            }
        } catch (error) {
            console.log('branch list', error);
            setLoanTransctionData([]);

            if (error?.response?.data?.message) {
                toast.warn(error?.response?.data?.message);
            } else {
                toast.warn('Something went wrong');
            }
        }
    };

    // Combined fetch function
    const fetchApprovalData = async () => {
        const controller = new AbortController();

        try {
            // Fetch both data in parallel
            const [userListRes, historyListRes] = await Promise.all([
                Axios.get(`/loan-request/loan-approve-history/${id}`, {
                    signal: controller.signal,
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }),
                Axios.get('/loan-approval-setting', {
                    signal: controller.signal,
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }),
            ]);

            // Check if both requests were successful
            if (userListRes.data.status === 'success' && historyListRes.data.status === 'success') {
                const approvalData = userListRes?.data?.response_data || [];
                const getData = historyListRes?.data?.response_data || [];

                // Sort the data by rank
                const sortedData = [...getData].sort((a, b) => a?.rank - b?.rank);

                // Merge approval status into stepper data
                const updatedData = sortedData.map((item) => {
                    const approval = approvalData.find(
                        (approval) => approval.approved_by === item.employee_id
                    );
                    return {
                        ...item,
                        approval_status: approval ? approval.approval_status : '',
                    };
                });

                setStepperData(updatedData);
            } else {
                setStepperData([]);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
            toast.warn('Something went wrong while fetching data.');
        }
    };

    // Call fetchApprovalData when the component mounts or when `id` or `token` changes
    useEffect(() => {
        if (id && token) {
            fetchApprovalData();
        }
    }, [id, token]);

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        getViewData(signal);
        return () => {
            controller.abort();
        };
    }, [id, shouldFetchData]);

    useEffect(() => {
        getLoanTranscationList();
    }, [id, shouldFetchData]);

    if (error?.isError) {
        return (
            <Stack
                sx={{
                    // minWidth: { sm: "500px", xs: "300px" },
                    // maxWidth: { sm: "500px", xs: "300px" },
                    width: '100%',
                }}
                spacing={2}
            >
                <Alert severity="error" sx={{ fontSize: '16px' }}>
                    <AlertTitle>Error</AlertTitle>
                    {error?.message}
                </Alert>
            </Stack>
        );
    }

    return (
        <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Loan View Details</Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
                <Grid item md={8} sm={7} xs={12}>
                    <TableContainer sx={{ mt: 1 }}>
                        <Table
                            sx={{
                                // minWidth: { sm: "500px", xs: "300px" },
                                // maxWidth: { sm: "500px", xs: "300px" },
                                width: '100%',
                                border: '1px solid rgba(224, 224, 224, 1)',
                            }}
                            size="small"
                        >
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Loan ID{' '}
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        {viewData?.response_data?.id || 'N/A'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Employee ID{' '}
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        {viewData?.response_data?.hrm_employee_id || 'N/A'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Name{' '}
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        {viewData?.response_data?.employee_name || 'N/A'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Designation{' '}
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        {viewData?.response_data?.designation_name || 'N/A'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Loan Type
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        {viewData?.response_data?.loan_type_title || 'N/A'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Loan Amount
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        {displayAmountText(viewData?.response_data?.loan_amount) ||
                                            'N/A'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Num of Installment
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        {viewData?.response_data?.num_of_installment || 'N/A'}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Amount Per Installment
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        {displayAmountText(
                                            viewData?.response_data?.amount_per_installment
                                        ) || 'N/A'}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ verticalAlign: 'top' }}>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Loan Status
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        <LoanStatus status={viewData?.response_data?.loan_status} />
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ verticalAlign: 'top' }}>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Approve Status
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        <LoanMainStatus
                                            status={viewData?.response_data?.approval_status}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ verticalAlign: 'top' }}>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Reason
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        {viewData?.response_data?.loan_details || 'N/A'}
                                    </TableCell>
                                </TableRow>
                                <TableRow sx={{ verticalAlign: 'top' }}>
                                    <TableCell
                                        component={'td'}
                                        width={'30%'}
                                        sx={{
                                            borderRight: '1px solid rgba(224, 224, 224, 1)',
                                            fontSize: '16px',
                                        }}
                                    >
                                        Attachment
                                    </TableCell>
                                    <TableCell
                                        component={'td'}
                                        width={'70%'}
                                        sx={{ fontSize: '16px' }}
                                    >
                                        <FileViewer url={viewData?.response_data?.attachment} />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {role !== ROLENAME && (
                        <Stack
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            direction={'row'}
                            flexWrap={'wrap'}
                            gap={1.5}
                            container
                            spacing={2}
                            sx={{ mt: 4 }}
                        >
                            <Typography fontWeight={500}>Updated Status</Typography>
                            <Stack
                                justifyContent={'end'}
                                direction={'row'}
                                flexWrap={'wrap'}
                                gap={1.5}
                                container
                                spacing={2}
                            >
                                <Button
                                    variant="contained"
                                    color="info"
                                    sx={{ minWidth: 130 }}
                                    disabled={isDisabledStatus}
                                    onClick={handleOpenApproveLoan}
                                >
                                    Loan Status
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{ minWidth: 130, ml: '0 !important' }}
                                    disabled={isDisabledLoanStatus}
                                    onClick={handleOpenApprove}
                                >
                                    Approve Status
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                    <Box sx={{ mt: 5 }}>
                        <Stack
                            justifyContent={'space-between'}
                            alignItems={'start'}
                            flexDirection={'row'}
                            flexWrap={'wrap'}
                            gap={2}
                            sx={{ mb: 2 }}
                        >
                            <Typography fontSize={{ md: 18, xs: 16 }} fontWeight={500} variant="h5">
                                Loan Transcation History
                            </Typography>
                            <Stack
                                justifyContent={'end'}
                                alignItems={'center'}
                                flexDirection={'row'}
                                flexWrap={'wrap'}
                                gap={2}
                            >
                                <Button
                                    className={'hide-on-print'}
                                    variant="contained"
                                    size={'small'}
                                    startIcon={<AiFillFileExcel />}
                                    onClick={handleExportExcel}
                                    disabled={loanTransctionData?.length <= 0}
                                >
                                    Export
                                </Button>
                                <Button
                                    className={'hide-on-print'}
                                    variant="contained"
                                    size={'small'}
                                    startIcon={<AiFillPrinter />}
                                    onClick={handleGeneratePdf}
                                    disabled={loanTransctionData?.length <= 0}
                                >
                                    Print
                                </Button>
                            </Stack>
                        </Stack>
                        <TableContainer sx={{ maxHeight: 600 }}>
                            <Divider />
                            <Table aria-label="simple table" size={'small'} stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align={'left'}>#</TableCell>
                                        <TableCell align={'left'}>Date</TableCell>
                                        <TableCell>Paid Amount</TableCell>
                                        <TableCell>Create At</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {loanTransctionData?.length <= 0 ? (
                                        <TableRow>
                                            <TableCell component="th" scope="row" colSpan={9}>
                                                <DataNotFound />
                                            </TableCell>
                                        </TableRow>
                                    ) : (
                                        loanTransctionData?.map((item, index) => {
                                            return (
                                                <TableRow key={item?.id}>
                                                    <TableCell align="left">{index + 1}</TableCell>
                                                    <TableCell align="left">
                                                        {dateFormat({
                                                            date: `${item?.month} ${item?.year}`,
                                                            dateFormat: 'MM YYYY',
                                                            format: 'MMM YYYY',
                                                        })}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {displayAmountText(item?.paid_amount) ??
                                                            'N/A'}
                                                    </TableCell>
                                                    <TableCell>
                                                        {createUpdatedDateTime({
                                                            createDate: item?.created_at,
                                                            updateDate: item?.updated_at,
                                                        })}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                    {viewData?.employee_loan?.length > 0 && (
                        <Box sx={{ mt: 5 }}>
                            <Typography
                                fontSize={{ md: 18, xs: 16 }}
                                fontWeight={500}
                                variant="h5"
                                sx={{ mb: 2 }}
                            >
                                Others Loan
                            </Typography>
                            <TableContainer sx={{ maxHeight: 600 }}>
                                <Divider />
                                <Table aria-label="simple table" size={'small'} stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align={'left'}>#</TableCell>
                                            <TableCell align={'left'}>Loan ID</TableCell>
                                            <TableCell align={'left'}>Loan Type</TableCell>
                                            <TableCell>Num of Installment</TableCell>
                                            <TableCell>Per Installment</TableCell>
                                            <TableCell>Loan Amount</TableCell>
                                            <TableCell>Approve Status</TableCell>
                                            <TableCell align="right">Action </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {viewData?.employee_loan?.length <= 0 ? (
                                            <TableRow>
                                                <TableCell component="th" scope="row" colSpan={9}>
                                                    <DataNotFound />
                                                </TableCell>
                                            </TableRow>
                                        ) : (
                                            viewData?.employee_loan?.map((item, index) => {
                                                return (
                                                    <TableRow key={item?.id}>
                                                        <TableCell align="left">
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {item?.id}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {item?.loan_type_title}
                                                        </TableCell>

                                                        <TableCell align="left">
                                                            {item?.num_of_installment}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {displayAmountText(
                                                                item?.amount_per_installment
                                                            ) ?? 'N/A'}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            {displayAmountText(item?.loan_amount) ??
                                                                'N/A'}
                                                        </TableCell>
                                                        <TableCell align="left">
                                                            <LoanMainStatus
                                                                status={item?.approval_status}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <IconButton
                                                                LinkComponent={Link}
                                                                to={`/loan-manage/view/${item?.id}`}
                                                            >
                                                                <BsEye size={18} />
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    )}
                </Grid>
                <Grid item md={4} sm={5} xs={12}>
                    <List
                        sx={{
                            width: '100%',
                            maxWidth: 360,
                            '.MuiListItem-root': {
                                position: 'relative',
                                '&:after': {
                                    content: "''",
                                    position: 'absolute',
                                    top: '21px',
                                    left: '34px',
                                    width: '1px',
                                    height: '100%',
                                    backgroundColor: '#d7d7d7',
                                },
                                '&:last-child': {
                                    '&:after': {
                                        display: 'none',
                                    },
                                },
                            },
                            '.MuiListItemAvatar-root ': {
                                position: 'relative',
                                zIndex: 2,
                            },
                            '.MuiListItemText-secondary': {
                                fontWeight: 600,
                                textTransform: 'capitalize',
                            },
                        }}
                    >
                        {stepperData?.length > 0 ? (
                            stepperData?.map((item) => {
                                const isApprove = item?.approval_status === loanMainStatus[2];
                                const isCancel = item?.approval_status === loanMainStatus[3];
                                const getStatus = item?.approval_status;
                                return (
                                    <ListItem sx={{ opacity: getStatus ? 1 : 0.7 }} key={item?.id}>
                                        <ListItemAvatar>
                                            <Avatar
                                                sx={{
                                                    backgroundColor: isApprove
                                                        ? 'green'
                                                        : isCancel
                                                          ? '#da2828'
                                                          : '#bdbdbd',
                                                }}
                                            >
                                                {isApprove ? (
                                                    <BsFillCheckCircleFill color="white" />
                                                ) : isCancel ? (
                                                    <MdOutlineCancel color="white" />
                                                ) : (
                                                    <GrStatusCritical />
                                                )}
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={item?.employee_name}
                                            secondary={
                                                getStatus
                                                    .replace(/[^a-zA-Z0-9 ]/g, ' ')
                                                    .replace(/\s+/g, ' ')
                                                    .trim() || 'Awaiting Approval'
                                            }
                                        />
                                    </ListItem>
                                );
                            })
                        ) : (
                            <Typography textAlign={'center'} mt={4} fontWeight={600}>
                                Please setup loan approval settings
                            </Typography>
                        )}
                    </List>
                </Grid>
            </Grid>

            {/* Approve Modal  */}
            <NewFormModal
                openModal={approveModal}
                handleModalClose={handleModalCloseApprove}
                modalTitle="Approve Status"
                modalWidth={'sm'}
            >
                <ApproveLoan
                    data={viewData}
                    setApproveModal={setApproveModal}
                    setShouldFetchData={setShouldFetchData}
                />
            </NewFormModal>

            {/* Approve Loan Modal  */}
            <NewFormModal
                openModal={approveLoanModal}
                handleModalClose={handleModalCloseApproveLoan}
                modalTitle="Approve Loan Status"
                modalWidth={'sm'}
            >
                <ApproveLoanStatus
                    data={viewData}
                    setApproveModal={setApproveLoanModal}
                    setShouldFetchData={setShouldFetchData}
                />
            </NewFormModal>
        </Paper>
    );
};

export default LoanManageView;
