import pdfMake from 'pdfmake/build/pdfmake';
import { convertImageUrlToBase64, displayAmountText, toWordsFormat } from '../utils';
import * as pdfFonts from './vfs_fonts'; // 👈 local import
// Attach fonts to pdfMake
pdfMake.vfs = pdfFonts;

//Style
const BORDERCOLOR = '#d7d7d7';

//Fiscal tax certificate report generate
export const generatePayableReportPDF = async (data) => {
    try {
        const currentDate = new Date().toLocaleString('en-US');
        const imageUrl = data?.bannerImage;
        const base64Image = imageUrl ? await convertImageUrlToBase64(imageUrl) : null;

        const tableLabels = Object.values(data.tableLabels).map((value, index) => {
            return {
                text: value,
                margin: [4, 4, 4, 4],
                fillColor: 'whitesmoke',
                alignment: index === 5 || index === 6 ? 'right' : 'left',
                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
            };
        });

        const docDefinition = {
            pageSize: 'A4',

            content: [
                base64Image
                    ? {
                          image: base64Image,
                          width: 595, // Full width for A4
                          height: 60,
                          margin: [-40, -40, 0, 10],
                      }
                    : null,
                ...(data?.companyInfo?.name
                    ? [
                          {
                              width: '*',
                              text: data?.companyInfo?.name,
                              alignment: 'center',
                              style: 'top_header',
                          },
                      ]
                    : []),
                ...(data?.companyInfo?.address1
                    ? [
                          {
                              width: '*',
                              text: `${data?.companyInfo?.address1}`,
                              alignment: 'center',
                              style: 'top_subheader',
                          },
                      ]
                    : []),
                ...(data?.companyInfo?.address2
                    ? [
                          {
                              width: '*',
                              text: `${data?.companyInfo?.address2}`,
                              alignment: 'center',
                              style: 'top_subheader',
                          },
                      ]
                    : []),

                { text: '\n' },
                {
                    text: data?.taxCertificateDate,
                    alignment: 'right',
                    fontSize: 10,
                    bold: true,
                },

                { text: '\n' },
                {
                    stack: data.cards.map((card) => ({
                        stack: [
                            // Card Title
                            { text: card.title, style: 'cardTitle', margin: [0, 5, 0, 5] },
                            // Card Items
                            ...card.items.map((item) => ({
                                text: [
                                    item?.label ? { text: `${item.label}: `, bold: true } : null, // Add ':' only if label exists
                                    { text: item?.value || '', margin: [5, 0, 0, 0] },
                                ].filter(Boolean), // Remove null items
                                margin: [0, 2, 0, 2],
                            })),
                            // Total Gross (if available)
                            card?.totalGross
                                ? {
                                      text: `${card.totalGross}`,
                                      style: 'cardTotal',
                                      margin: [0, 5, 0, 5],
                                      bold: true,
                                  }
                                : null,
                        ].filter(Boolean), // Filter out null or undefined items
                        // margin: [0, 10, 0, 10], // Add spacing between cards
                    })),
                },
                { text: '\n' },
                {
                    text: 'The particulars of payment of deducted tax to the credit of the Government:',
                    style: 'subheader',
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ['auto', 'auto', '*', 'auto', 'auto', '*', '*'],
                        body: [
                            tableLabels,
                            ...data.items.map((item, index) => [
                                {
                                    text: item.column1,
                                    margin: [4, 4, 4, 4],
                                    fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                },
                                {
                                    text: item.column2,
                                    margin: [4, 4, 4, 4],
                                    fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                },
                                {
                                    text: item.column3,
                                    margin: [4, 4, 4, 4],
                                    fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                },
                                {
                                    text: item.column4,
                                    margin: [4, 4, 4, 4],
                                    fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                },
                                {
                                    text: item.column5,
                                    margin: [4, 4, 4, 4],
                                    fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                },
                                {
                                    text: item.column6,
                                    margin: [4, 4, 4, 4],
                                    fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                    alignment: 'right',
                                },
                                {
                                    text: item.column7,
                                    margin: [4, 4, 4, 4],
                                    fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                    alignment: 'right',
                                },
                            ]),
                            [
                                {
                                    text: ' ',
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                },
                                {
                                    text: 'Total Amount',
                                    colSpan: 5,
                                    bold: true,
                                    alignment: 'right',
                                    margin: [4, 4, 4, 4],
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                },
                                {},
                                {},
                                {},
                                {},
                                {
                                    text: displayAmountText(data.totalAmount),
                                    alignment: 'right',
                                    bold: true,
                                    margin: [4, 4, 4, 4],
                                    borderColor: [
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                        BORDERCOLOR,
                                    ],
                                },
                            ],
                        ],
                    },
                },
                // Total Amount in Words
                { text: '\n', alignment: 'center', fontSize: 10, bold: true },
                {
                    text: `Total Amount in Words: ${toWordsFormat.convert(data.totalAmount || 0)}`,
                    fontSize: 10,
                    bold: true,
                },

                { text: '\n\n', alignment: 'center', fontSize: 10, bold: true },
                {
                    text: 'Certified that the information given above is correct and complete.',
                    style: 'subheader',
                },
                ...(data.HRInfo[0]
                    ? [
                          {
                              columns: [
                                  {
                                      text: '',
                                  },
                                  {
                                      stack: [
                                          {
                                              text: '_________________________________',
                                              style: 'signaturePlaceholder',
                                          },
                                          ...data.HRInfo.filter((item) => item) // Filter out null/undefined
                                              .map((item) => ({
                                                  text: item,
                                                  margin: [0, 2, 0, 2],
                                                  style: 'signatureName',
                                              })),
                                      ],
                                      width: 200,
                                  },
                              ],
                          },
                      ]
                    : []),
            ],
            footer: {
                text: `System generated invoice\nGeneration Date: ${currentDate}`,
                style: 'footer',
            },
            styles: {
                top_header: {
                    fontSize: 14,
                    bold: true,
                    margin: [0, 0, 0, 5],
                },
                top_subheader: {
                    fontSize: 11,
                    bold: false,
                    margin: [0, 2, 0, 2],
                },
                header: {
                    fontSize: 14,
                    margin: [0, 20, 0, 10],
                },
                subheader: {
                    fontSize: 10,
                    bold: false,
                    margin: [0, 2, 0, 2],
                },
                card: {
                    border: '1px solid #ccc',
                    padding: 10,
                    margin: [0, 5],
                },
                cardTitle: {
                    bold: true,
                    fontSize: 12,
                    margin: [0, 0, 0, 10],
                    decoration: 'underline',
                    decorationStyle: 'solid',
                    decorationColor: 'black',
                },
                signaturePlaceholder: {
                    margin: [0, 70, 0, 0],
                    alignment: 'center',
                },
                signatureName: {
                    bold: true,
                    alignment: 'center',
                },
                footer: {
                    fontSize: 8,
                    italics: true,
                    margin: [20, 10, 0, 5],
                },
            },
            defaultStyle: {
                fontSize: 10,
            },
        };

        pdfMake.createPdf(docDefinition).open();
    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};

//Tax certificate report generate
export const generateTaxCertificatePDF = async (data) => {
    const currentDate = new Date().toLocaleString('en-US');

    const tableLabels = Object.values(data.tableLabels).map((value, index) => {
        return {
            text: value,
            margin: [4, 4, 4, 4],
            fillColor: 'whitesmoke',
            alignment: index === 2 ? 'right' : 'left',
            borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
        };
    });

    const docDefinition = {
        pageSize: 'A4',
        content: [
            ...(data?.companyInfoForPdf?.name
                ? [
                      {
                          width: '*',
                          text: data?.companyInfoForPdf?.name,
                          alignment: 'center',
                          style: 'top_header',
                      },
                  ]
                : []),
            ...(data?.companyInfoForPdf?.email
                ? [
                      {
                          width: '*',
                          text: `${data?.companyInfoForPdf?.email}`,
                          alignment: 'center',
                          style: 'top_subheader',
                      },
                  ]
                : []),
            ...(data?.companyInfoForPdf?.phone
                ? [
                      {
                          width: '*',
                          text: `${data?.companyInfoForPdf?.phone}`,
                          alignment: 'center',
                          style: 'top_subheader',
                      },
                  ]
                : []),
            ...(data?.companyInfoForPdf?.address
                ? [
                      {
                          width: '*',
                          text: `${data?.companyInfoForPdf?.address}`,
                          alignment: 'center',
                          style: 'top_subheader',
                      },
                  ]
                : []),
            { text: '\n' },
            {
                text: data.invoiceForLabel.invoiceType,
                alignment: 'center',
                style: 'header',
            },

            { text: '\n' },
            // Two cards in a grid layout
            {
                columns: data.cards.map((card, index) => ({
                    stack: [
                        { text: card.title, style: 'cardTitle' },
                        ...card.items.map((item) => {
                            if (item) {
                                return {
                                    text: [
                                        { text: item?.label },
                                        {
                                            text:
                                                index === 3
                                                    ? displayAmountText(item?.value)
                                                    : item?.value,
                                        },
                                    ],
                                    margin: [0, 2, 0, 2],
                                    bold: item?.isBold ? true : false,
                                };
                            }
                        }),
                    ],
                    style: 'card',
                })),
                columnGap: 10,
            },
            { text: '\n' },

            {
                table: {
                    headerRows: 1,
                    widths: ['auto', '*', '*'],
                    body: [
                        tableLabels,
                        ...data.items.map((item, index) => [
                            {
                                text: item.column1,
                                margin: [4, 4, 4, 4],
                                fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                            },
                            {
                                text: item.column2,
                                margin: [4, 4, 4, 4],
                                fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                            },
                            {
                                text: item.column3,
                                margin: [4, 4, 4, 4],
                                fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                                alignment: 'right',
                            },
                        ]),
                        [
                            {
                                text: ' ',
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                            },
                            {
                                text: 'Total Amount',
                                bold: true,
                                alignment: 'right',
                                margin: [4, 4, 4, 4],
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                            },
                            {
                                text: displayAmountText(data.totalAmount),
                                alignment: 'right',
                                bold: true,
                                margin: [4, 4, 4, 4],
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                            },
                        ],
                    ],
                },
            },
            // Total Amount in Words
            { text: '\n', alignment: 'center', fontSize: 10, bold: true },
            {
                text: `Total Amount in Words: ${toWordsFormat.convert(data.totalAmount || 0)}`,
                fontSize: 10,
                bold: true,
            },

            { text: '\n\n', alignment: 'center', fontSize: 10, bold: true },
            {
                text: 'Certified that the information given above is correct and complete.',
                style: 'subheader',
            },
            ...(data.HRInfo[0]
                ? [
                      {
                          columns: [
                              {
                                  text: '',
                              },
                              {
                                  stack: [
                                      {
                                          text: '_________________________________',
                                          style: 'signaturePlaceholder',
                                      },
                                      ...data.HRInfo.filter((item) => item) // Filter out null/undefined
                                          .map((item) => ({
                                              text: item,
                                              margin: [0, 2, 0, 2],
                                              style: 'signatureName',
                                          })),
                                  ],
                                  width: 200,
                              },
                          ],
                      },
                  ]
                : []),
        ],
        footer: {
            text: `System generated invoice\nGeneration Date: ${currentDate}`,
            style: 'footer',
        },
        styles: {
            top_header: {
                fontSize: 14,
                bold: true,
                margin: [0, 0, 0, 5],
            },
            top_subheader: {
                fontSize: 11,
                bold: false,
                margin: [0, 2, 0, 2],
            },
            header: {
                fontSize: 14,
                margin: [0, 20, 0, 10],
            },
            subheader: {
                fontSize: 10,
                bold: false,
                margin: [0, 2, 0, 2],
            },
            card: {
                border: '1px solid #ccc',
                padding: 10,
                margin: [0, 5],
            },
            cardTitle: {
                bold: true,
                fontSize: 10,
                margin: [0, 0, 0, 10],
                decoration: 'underline',
                decorationStyle: 'solid',
                decorationColor: 'black',
            },
            signaturePlaceholder: {
                margin: [0, 70, 0, 0],
                alignment: 'center',
            },
            signatureName: {
                bold: true,
                alignment: 'center',
            },
            footer: {
                fontSize: 8,
                italics: true,
                margin: [20, 10, 0, 5],
            },
        },
        defaultStyle: {
            fontSize: 10,
        },
    };

    pdfMake.createPdf(docDefinition).open();
};

//Loan transcation report generate
export const generateLoanTranscationPDF = async (data) => {
    const currentDate = new Date().toLocaleString('en-US');

    const tableLabels = Object.values(data.tableLabels).map((value, index) => {
        return {
            text: value,
            margin: [4, 4, 4, 4],
            fillColor: 'whitesmoke',
            alignment: index === 2 ? 'right' : 'left',
            borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
        };
    });

    const docDefinition = {
        pageSize: 'A4',
        content: [
            ...(data?.companyInfoForPdf?.name
                ? [
                      {
                          width: '*',
                          text: data?.companyInfoForPdf?.name,
                          alignment: 'center',
                          style: 'top_header',
                      },
                  ]
                : []),
            ...(data?.companyInfoForPdf?.email
                ? [
                      {
                          width: '*',
                          text: `${data?.companyInfoForPdf?.email}`,
                          alignment: 'center',
                          style: 'top_subheader',
                      },
                  ]
                : []),
            ...(data?.companyInfoForPdf?.phone
                ? [
                      {
                          width: '*',
                          text: `${data?.companyInfoForPdf?.phone}`,
                          alignment: 'center',
                          style: 'top_subheader',
                      },
                  ]
                : []),
            ...(data?.companyInfoForPdf?.address
                ? [
                      {
                          width: '*',
                          text: `${data?.companyInfoForPdf?.address}`,
                          alignment: 'center',
                          style: 'top_subheader',
                      },
                  ]
                : []),
            { text: '\n' },
            {
                text: data.invoiceForLabel.invoiceType,
                alignment: 'center',
                style: 'header',
            },

            { text: '\n' },
            // Two cards in a grid layout
            {
                columns: data.cards.map((card, index) => ({
                    stack: [
                        { text: card.title, style: 'cardTitle' },
                        ...card.items.map((item) => {
                            if (item) {
                                return {
                                    text: [
                                        { text: item?.label },
                                        {
                                            text:
                                                index === 3
                                                    ? displayAmountText(item?.value)
                                                    : item?.value,
                                        },
                                    ],
                                    margin: [0, 2, 0, 2],
                                    bold: item?.isBold ? true : false,
                                };
                            }
                        }),
                    ],
                    style: 'card',
                })),
                columnGap: 10,
            },
            { text: '\n' },

            {
                table: {
                    headerRows: 1,
                    widths: ['auto', '*', '*', 'auto'],
                    body: [
                        tableLabels,
                        ...data.items.map((item, index) => [
                            {
                                text: item.column1,
                                margin: [4, 4, 4, 4],
                                fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                            },
                            {
                                text: item.column2,
                                margin: [4, 4, 4, 4],
                                fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                            },
                            {
                                text: item.column3,
                                margin: [4, 4, 4, 4],
                                fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                                alignment: 'right',
                            },
                            {
                                text: item.column4,
                                margin: [4, 4, 4, 4],
                                fillColor: (index + 1) % 2 === 0 ? 'whitesmoke' : '',
                                borderColor: [BORDERCOLOR, BORDERCOLOR, BORDERCOLOR, BORDERCOLOR],
                            },
                        ]),
                    ],
                },
            },
        ],
        footer: {
            text: `System generated invoice\nGeneration Date: ${currentDate}`,
            style: 'footer',
        },
        styles: {
            top_header: {
                fontSize: 14,
                bold: true,
                margin: [0, 0, 0, 5],
            },
            top_subheader: {
                fontSize: 11,
                bold: false,
                margin: [0, 2, 0, 2],
            },
            header: {
                fontSize: 14,
                margin: [0, 20, 0, 10],
            },
            subheader: {
                fontSize: 10,
                bold: false,
                margin: [0, 2, 0, 2],
            },
            card: {
                border: '1px solid #ccc',
                padding: 10,
                margin: [0, 5],
            },
            cardTitle: {
                bold: true,
                fontSize: 10,
                margin: [0, 0, 0, 10],
                decoration: 'underline',
                decorationStyle: 'solid',
                decorationColor: 'black',
            },
            signaturePlaceholder: {
                margin: [0, 70, 0, 0],
                alignment: 'center',
            },
            signatureName: {
                bold: true,
                alignment: 'center',
            },
            footer: {
                fontSize: 8,
                italics: true,
                margin: [20, 10, 0, 5],
            },
        },
        defaultStyle: {
            fontSize: 10,
        },
    };

    pdfMake.createPdf(docDefinition).open();
};
