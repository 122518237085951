// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from 'react';
import Axios from '../api/Axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { removeLSItemsWithPrefix } from '../utils/utils';

const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(false);
    const [token, setToken] = useState(false);
    const [role, setRole] = useState('User');
    const [loading, setLoading] = useState(false);
    const [isShowCheckInOut, setIsShowCheckInOut] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const storageUser = JSON.parse(localStorage.getItem('user'));
        const storageToken = localStorage.getItem('token');
        const storageRole = localStorage.getItem('role');
        setUser(storageUser);
        setToken(storageToken);
        setRole(storageRole);
    }, []);

    const signin = async (email, password) => {
        setLoading(true);
        try {
            const res = await Axios.post('/login', {
                email: email,
                password: password,
            });
            if (res.data.status === 'success') {
                toast.success('Login Successful');
                setUser(res.data.user);
                setToken(res.data.token);
                setRole(res.data.role);
                localStorage.setItem('user', JSON.stringify(res.data.user));
                localStorage.setItem('token', res.data.token);
                localStorage.setItem('role', res.data.role);
                navigate('/dashboard/home');
            }
            setLoading(false);
        } catch (error) {
            toast.error(error.response.data.message);
            setLoading(false);
        }
    };

    const signout = async () => {
        try {
            let res;
            if (token) {
                res = await Axios.post(
                    '/logout',
                    {},
                    {
                        headers: {
                            Authorization: 'Bearer ' + token,
                        },
                    }
                );
                setUser(false);
                setToken(false);
                setRole('User');
                localStorage.removeItem('user');
                localStorage.removeItem('token');
                localStorage.removeItem('role');
                navigate('/login');
                toast.success(res.data.message);
            } else {
                if (localStorage.getItem('token') !== null) {
                    res = await Axios.post(
                        '/logout',
                        {},
                        {
                            headers: {
                                Authorization: 'Bearer ' + localStorage.getItem('token'),
                            },
                        }
                    );
                    setUser(false);
                    setToken(false);
                    setRole('User');
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    localStorage.removeItem('role');
                    navigate('/login');
                    toast.success(res.data.message);
                } else {
                    setUser(false);
                    setToken(false);
                    setRole('User');
                    localStorage.removeItem('user');
                    localStorage.removeItem('token');
                    localStorage.removeItem('role');
                    navigate('/login');
                    toast.warn('Something went wrong');
                }
            }
        } catch (error) {
            setUser(false);
            setToken(false);
            setRole('User');
            localStorage.removeItem('user');
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            navigate('/login');
            toast.error(error.response.data.message);
        } finally {
            removeLSItemsWithPrefix('payroll_');
        }
    };

    const refreshUser = async () => {
        try {
            const res = await Axios.get(`me`, {
                headers: {
                    Authorization: token
                        ? 'Bearer ' + token
                        : 'Bearer ' + localStorage.getItem('token'),
                },
            });
            if (res.data.status === 'success') {
                setUser(res.data.response_data);
                localStorage.removeItem('user');
                localStorage.setItem('user', JSON.stringify(res.data.response_data));
                //console.log(departmentTreeViewFormat(res.data.response_data))
            } else {
                toast.warn('Unable to update local user data, Please refresh the page');
            }
        } catch (error) {
            console.log(error);
            toast.warn('Something went wrong');
        }
    };

    return {
        user,
        token,
        role,
        loading,
        isShowCheckInOut,
        setIsShowCheckInOut,
        signin,
        signout,
        refreshUser,

        //resetData,
    };
}
