import { Box, Grid } from '@mui/material'
import React from 'react'
import ApiErrorMessage from '../error/ApiErrorMessage'

function ErrorMessageShow({handleError}) {
  return (
    <Box sx={{ mt: 4 }}>
        <Grid container>
          <Grid item md={4} sm={6} xs={12}>
            <ApiErrorMessage handleError={handleError} />
          </Grid>
        </Grid>
      </Box>
  )
}

export default ErrorMessageShow