import {
    Box,
    Button,
    Divider,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useGetProvidentFundDetails from "../../hooks/useGetProvidentFundDetails";
import { useParams } from "react-router-dom";
import generatePDF from "react-to-pdf";
import { options } from "../../helpers/pdf/pdfOptions";
import { grey } from "@mui/material/colors";
import Axios from "../../api/Axios";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import useGetGratuityDetails from "../../hooks/useGetGratuityDetails";

const monthNames = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    10: "October",
    11: "November",
    12: "December",
};

const GratuityDetails = () => {
    const [pdfLoading, setPdfLoading] = useState(false);
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(true);

    const { id } = useParams();
    const { data: gratuity, isLoading, isError } = useGetGratuityDetails({ empId: id });

    const { token } = useAuth();

    const sheetRef = useRef();
    const pdfGenerator = async (sheetRef) => {
        setPdfLoading(true);
        try {
            await generatePDF(sheetRef, options);
        } catch (error) {
            console.log(error);
        } finally {
            setPdfLoading(false);
        }
    };

    useEffect(() => {
        const getSettings = async () => {
            setLoading(true);
            try {
                const res = await Axios.post(
                    `all/setting`,
                    {
                        name: [
                            "company_logo",
                            "setting_app_company_name",
                            "company_favIcon",
                            "setting_app_name",
                            "setting_app_address",
                            "setting_app_phone",
                            "setting_app_email",
                        ],
                    },
                    {
                        headers: {
                            Authorization: token ? "Bearer " + token : "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );
                if (res.data.status === "success") {
                    setSettings(res.data.response_data);
                } else {
                    setSettings({});
                    toast.warn("Something went wrong");
                }
                setLoading(false);
            } catch (error) {
                if (error.response.data.message === "Data not found.") {
                    toast.warn("Payslip is not available, Generate salary data first.");
                    setSettings({});
                } else {
                    console.log(error);
                }
                setLoading(false);
            }
        };
        getSettings();
    }, []);
    return pdfLoading ? (
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
            }}
        >
            <Typography variant="h6">Loading...</Typography>
        </Box>
    ) : (
        <Paper elevation={0} sx={{ p: 2 }} id={"section-to-print"}>
            <Stack alignItems={"end"} direction={"row"} justifyContent={"flex-end"} gap={1}>
                <Button className={"hide-on-print"} variant="contained" size={"small"} onClick={() => window.print()}>
                    Print
                </Button>
                <Button className={"hide-on-print"} variant="contained" size={"small"} onClick={() => pdfGenerator(sheetRef)}>
                    Download
                </Button>
            </Stack>
            <Paper className="paperRef" ref={sheetRef} elevation={0}>
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    my={2}
                    sx={{ background: grey[100], borderRadius: "5px", border: `1px solid ${grey[500]}` }}
                    px={3}
                    py={2}
                >
                    <Stack alignItems={"center"} direction={"column"} mb={2}>
                        <img src={"/site-logo.png"} alt={settings?.setting_app_name ?? "Sun Pharma"} style={{ maxHeight: "60px" }} />
                    </Stack>
                    <Stack>
                        <Typography
                            sx={{ fontSize: "16px", margin: "0px" }}
                            className="address"
                            variant={"h6"}
                            color={"#606060"}
                            align="center"
                            marginBottom={1}
                        >
                            {settings?.setting_app_company_name ?? "N/A"}
                        </Typography>
                        <Typography
                            sx={{ fontSize: "14px", margin: "0px" }}
                            className="address"
                            variant={"subtitle2"}
                            color={"#606060"}
                            align="center"
                            marginBottom={1}
                        >
                            {settings?.setting_app_address ?? "N/A"}
                        </Typography>
                    </Stack>
                </Stack>
                <Button fullWidth color={"inherit"} variant="contained" disableElevation>
                    Gratuity Details for {gratuity?.employee_name}
                </Button>
                <TableContainer sx={{ mt: 2, border: "1px solid #c4c4c4", borderRadius: "4px" }}>
                    <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                        <>
                            {/* <TableHead sx={{ background: "#D4D4D4" }}>
                                <TableRow>
                                    <TableCell align="left">Current Basic Salary</TableCell>
                                    <TableCell align="left">{gratuity?.basic_salary}/-</TableCell>
                                </TableRow>
                            </TableHead> */}
                            <TableBody>
                                <TableRow>
                                    <TableCell align="left">Current Basic Salary</TableCell>
                                    <TableCell align="left">{gratuity?.basic_salary}/-</TableCell>
                                </TableRow>
                            </TableBody>
                        </>
                        <TableFooter>
                            <TableRow>
                                <TableCell align="left" sx={{ fontWeight: "bold" }}>
                                    Total
                                </TableCell>
                                <TableCell align="left" sx={{ fontWeight: "bold" }}>
                                    {gratuity?.total_gratuity}
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
                <Typography mt={5} variant="caption" sx={{ display: "block" }} color={grey[700]}>
                    <em className="caption" style={{ fontWeight: "bold", display: "block", textAlign: "center" }}>
                        Note: This is a computer generated document and does not require a signature.
                    </em>
                </Typography>
            </Paper>
        </Paper>
    );
};

export default GratuityDetails;
