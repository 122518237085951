import { toast } from "react-toastify";
import Axios from "../Axios";

export const getManageGratuity = async (page, limit, searchValue) => {
    try {
        const res = await Axios.get(`gratuity?page=${page}&limit=${limit}&search=${searchValue}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getLeaveEncashment = async (page, limit) => {
    try {
        const res = await Axios.get(`employee/leave-encassment-for-all?page=${page}&limit=${limit}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};

export const getGratuityDetails = async (employeeId) => {
    try {
        const res = await Axios.get(`employee/total-gratuity/${employeeId}`, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
            },
        });

        return res.data;
    } catch (error) {
        console.log("error", error);
        toast.warn("Something went wrong");
    }
};
