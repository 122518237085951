import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Box,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Stack,
    Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Axios from '../../api/Axios';
import { useAuth } from '../../hooks/useAuth';
import { displayAmountText, loanTypeOptons } from '../../utils/utils';

const validationSchema = Yup.object().shape({
    type: Yup.string().required('Status type is required'),
});

const ApproveLoanStatus = ({ setApproveModal, setShouldFetchData, data }) => {
    const [viewData, setViewData] = useState(null);

    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: { errors },
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });
    const { token } = useAuth();

    // Approve Loan Submit
    const handleApproveSubmit = async (fromData) => {
        try {
            const res = await Axios.post(
                `/loan-request/update/loan-status/${data?.id}`,
                {
                    loan_status: fromData?.type,
                },
                {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res?.data?.status === 'success') {
                toast.success('Loan Status Updated Successfully');
                setApproveModal(false);
                setShouldFetchData((prev) => !prev);
                reset();
            } else if (res.data.success === '200') {
                toast.info("You don't have loan approve permissions, contact admin");
            } else if (res?.data?.status === 'error') {
                toast.warn(res?.data?.error[0]);
            }
        } catch (error) {
            console.log(error);
            if (error?.response?.data?.status === 'error') {
                if (error?.response?.data?.errors?.length > 0) {
                    toast.error(error?.response?.data?.errors[0]);
                } else {
                    toast.error(error?.response?.data?.message);
                }
            } else {
                toast.error('Something went wrong');
            }
        }
    };

    //Get View Data
    const getViewData = async (signal) => {
        try {
            const res = await Axios.get(
                `/loan-request/show/${data?.id}`,

                {
                    signal: signal,
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );

            if (res.data.status === 'success') {
                setViewData(res?.data?.response_data);
            } else if (res.data.success === '200') {
                toast.info("You don't have transfer view permissions, contact admin");
            } else {
                toast.warn('Something went wrong');
            }
        } catch (error) {
            console.log('error', error);
            toast.warn('Something went wrong');
        }
    };

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        getViewData(signal);
        return () => {
            controller.abort();
        };
    }, []);

    return (
        <form onSubmit={handleSubmit(handleApproveSubmit)}>
            <Box>
                <Typography>Employee: {viewData?.employee_name || 'N/A'}</Typography>
                <Typography>Designation: {viewData?.designation_name || 'N/A'}</Typography>
                <Typography>Loan Type: {viewData?.loan_type_title || 'N/A'}</Typography>
                <Typography>Amount: {displayAmountText(viewData?.loan_amount) || 'N/A'}</Typography>
            </Box>
            <Divider sx={{ mt: 1 }} />
            <Box sx={{ minHeight: 140 }}>
                <Controller
                    render={({ field, formState }) => (
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <Typography color={!!formState.errors?.type ? red[700] : ''}>
                                Status*
                            </Typography>
                            <ReactSelect
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        border: !!formState.errors?.type
                                            ? '1px solid #D32F2F'
                                            : '1px solid #C4C4C4',
                                        '&:hover': {
                                            border: !!formState.errors?.type
                                                ? '1px solid #D32F2F'
                                                : '1px solid #C4C4C4',
                                        },
                                    }),
                                }}
                                className="basic-single"
                                classNamePrefix="select"
                                isClearable={true}
                                isSearchable={true}
                                name="type"
                                options={loanTypeOptons}
                                value={
                                    field?.value !== ''
                                        ? loanTypeOptons?.filter(
                                              (option) => option?.value === field?.value
                                          )[0]
                                        : null
                                }
                                onChange={(selectedOption) => {
                                    field.onChange(selectedOption?.value);
                                }}
                            />
                            {!!formState.errors?.type ? (
                                <FormHelperText error>{errors?.type?.message}</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                    )}
                    name="type"
                    control={control}
                    defaultValue=""
                />
            </Box>

            <Stack
                justifyContent={'end'}
                direction={'row'}
                flexWrap={'wrap'}
                gap={1.5}
                container
                spacing={2}
                sx={{ my: 2 }}
            >
                <Button
                    variant="contained"
                    onClick={() => reset()}
                    color="inherit"
                    sx={{ minWidth: 130 }}
                >
                    Reset
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    sx={{ minWidth: 130, ml: '0 !important' }}
                >
                    Save
                </Button>
            </Stack>
        </form>
    );
};

export default ApproveLoanStatus;
