import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

function BranchTable({ children }) {
  return (
    <Paper
      elevation={2}
      sx={{
        py: 3,
        px: { lg: 4, md: 3, xs: 2 },
        width: "100%",
        mx: "auto",
      }}
    >
      <Box sx={{ width: "100%", mx: "auto" }}>{children}</Box>
    </Paper>
  );
}

export default BranchTable;
