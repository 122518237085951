import {Controller, useForm} from "react-hook-form";
import {
    Button,
    FormControl,
    FormHelperText, Grid, MenuItem,
    OutlinedInput, Select,
    Stack, TextField, Typography
} from "@mui/material";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {red} from "@mui/material/colors";
import {useEffect} from "react";
import countryList from "../../_mockData/countries"
import ReactSelect from "react-select";

const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("Firstname is required"),
    lastname: Yup.string().required("Lastname is required").min(3, "Minimum 3 characters"),
    email: Yup.string().required("Email is required").email('Email is invalid'),
    phone: Yup.string().required("Phone is required").min(7, "Minimum 7 digits"),
    country: Yup.string().required("Country is required"),
});

const BasicInformationTab = ({onSubmit, employeeData}) => {
    //react-hook-form
    const {
        control,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm({
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    useEffect(() => {
        reset({...employeeData})
    }, [])

    return(
        <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.firstname ? red[700]: ""}>First Name*</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.firstname}
                                />
                                {!!formState.errors?.firstname ?
                                    <FormHelperText
                                        error>{errors?.firstname?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="firstname"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.lastname ? red[700]: ""}>Last Name*</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.lastname}
                                />
                                {!!formState.errors?.lastname ?
                                    <FormHelperText
                                        error>{errors?.lastname?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="lastname"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.email ? red[700]: ""}>Email*</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.email}
                                />
                                {!!formState.errors?.email ?
                                    <FormHelperText
                                        error>{errors?.email?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="email"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.phone ? red[700]: ""}>Phone*</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.phone}
                                />
                                {!!formState.errors?.phone ?
                                    <FormHelperText
                                        error>{errors?.phone?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="phone"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.altPhone ? red[700]: ""}>Alternative Phone</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.altPhone}
                                />
                                {!!formState.errors?.altPhone ?
                                    <FormHelperText
                                        error>{errors?.altPhone?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="altPhone"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.ssn ? red[700]: ""}>SSN</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.ssn}
                                />
                                {!!formState.errors?.ssn ?
                                    <FormHelperText
                                        error>{errors?.ssn?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="ssn"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.city ? red[700]: ""}>Present Address</Typography>
                                <TextField
                                    {...field}
                                    error={!!formState.errors?.city}
                                    multiline
                                    minRows={2}
                                />
                                {!!formState.errors?.city ?
                                    <FormHelperText
                                        error>{errors?.city?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="city"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.city ? red[700]: ""}>Permanent Adress</Typography>
                                <TextField
                                    {...field}
                                    error={!!formState.errors?.city}
                                    multiline
                                    minRows={2}
                                />
                                {!!formState.errors?.city ?
                                    <FormHelperText
                                        error>{errors?.city?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="city"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl fullWidth>
                                <Typography color={!!formState.errors?.country ? red[700]: ""}>Country*</Typography>
                                {/*<Select*/}
                                {/*    {...field}*/}
                                {/*    error={!!formState.errors?.country}*/}
                                {/*>*/}
                                {/*    {countryList?.map(country => <MenuItem key={country.id} value={country.id}>{country.label}</MenuItem>)}*/}
                                {/*</Select>*/}
                                <ReactSelect
                                    styles={
                                        {
                                            control: (base) => ({
                                                ...base,
                                                border: !!formState.errors?.country ? "1px solid #D32F2F" : "1px solid #C4C4C4",
                                                "&:hover": {
                                                    border: !!formState.errors?.country ? "1px solid #D32F2F" : "1px solid #C4C4C4"
                                                }
                                            })
                                        }
                                    }
                                    className="basic-single"
                                    classNamePrefix="select"
                                    isClearable={true}
                                    isSearchable={true}
                                    name="color"
                                    options={countryList}
                                    value={field?.value !== "" ? countryList?.filter(country => country?.id === field?.value)[0] : null}
                                    onChange={(selectedOption) => field.onChange(selectedOption?.id)}
                                />
                                {!!formState.errors?.country ?
                                    <FormHelperText
                                        error>{errors?.country?.message}</FormHelperText> : ""}
                            </FormControl>
                        )}
                        name="country"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.city ? red[700]: ""}>City</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.city}
                                />
                                {!!formState.errors?.city ?
                                    <FormHelperText
                                        error>{errors?.city?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="city"
                        control={control}
                        defaultValue=""
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controller
                        render={({field, formState}) => (
                            <FormControl size={"small"} fullWidth variant="outlined">
                                <Typography color={!!formState.errors?.zip ? red[700]: ""}>ZIP Code</Typography>
                                <OutlinedInput
                                    {...field}
                                    error={!!formState.errors?.zip}
                                />
                                {!!formState.errors?.zip ?
                                    <FormHelperText
                                        error>{errors?.zip?.message}</FormHelperText> : ""}

                            </FormControl>
                        )}
                        name="zip"
                        control={control}
                        defaultValue=""
                    />
                </Grid>

            </Grid>

            <Stack direction="row" justifyContent="end" sx={{mt: 2}}>
                <Button variant="contained" type={"submit"}>Next</Button>
            </Stack>
        </form>
    )
}

export default BasicInformationTab;