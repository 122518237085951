import { createContext, useContext, useState } from "react";

//Create context
const EmployeeContext = createContext();

//Use context
export function useEmployeeContext() {
  return useContext(EmployeeContext);
}

//Context function
export function EmployeeContextProvider({ children }) {
  const [tabValue, setTabValue] = useState(0);
  const [basicEmployeeInfo, setBasicEmployeeInfo] = useState(null);
  const [tabConditionShow, setTabConditionShow] = useState({
    basic: false,
    payment: true,
    salary: true,
    // positional: true,
    // bio: true,
    leaves: true,
    attachment: true,
    reference: true,
    nominee: true,
    contact: true,
    customField: true,
    login: true,
  });

  //Tab functionality
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  //Tab condition show
  const handleShowTabCondition = (key) => {
    switch (key) {
      case "basic":
        setTabConditionShow({
          ...tabConditionShow,
          basic: false,
        });
        break;
      case "payment":
        setTabConditionShow({
          ...tabConditionShow,
          payment: false,
        });

        break;
      case "salary":
        setTabConditionShow({
          ...tabConditionShow,
          salary: false,
        });
        break;
      // case "positional":
      //   setTabConditionShow({
      //     ...tabConditionShow,
      //     positional: false,
      //   });
      //   break;
      // case "bio":
      //   setTabConditionShow({
      //     ...tabConditionShow,
      //     bio: false,
      //   });
      //   break;
      case "leaves":
        setTabConditionShow({
          ...tabConditionShow,
          leaves: false,
        });
        break;
      case "attachment":
        setTabConditionShow({
          ...tabConditionShow,
          attachment: false,
        });
        break;
      case "reference":
        setTabConditionShow({
          ...tabConditionShow,
          reference: false,
        });
        break;
      case "nominee":
        setTabConditionShow({
          ...tabConditionShow,
          nominee: false,
        });
        break;
      case "contact":
        setTabConditionShow({
          ...tabConditionShow,
          contact: false,
        });
        break;
      case "customField":
        setTabConditionShow({
          ...tabConditionShow,
          customField: false,
        });
        break;
      case "login":
        setTabConditionShow({
          ...tabConditionShow,
          login: false,
        });
        break;

      default:
        setTabValue(0);
        break;
    }
  };

  return (
    <EmployeeContext.Provider
      value={{
        tabValue,
        basicEmployeeInfo,
        tabConditionShow,
        setTabValue,
        setBasicEmployeeInfo,
        handleChangeTab,
        handleShowTabCondition,
      }}
    >
      {children}
    </EmployeeContext.Provider>
  );
}
