import { useQuery } from "@tanstack/react-query";
import { getManageGratuity } from "../api/fetchers/gratuity";

const useGetManageGratuity = ({ page, limit, searchValue }) => {
    return useQuery({
        queryKey: ["manage-gratuity", page, limit, searchValue],
        queryFn: () => getManageGratuity(page, limit, searchValue),
    });
};

export default useGetManageGratuity;
