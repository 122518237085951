import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Pagination,
    PaginationItem,
    Paper,
    Slide,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { forwardRef, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
    AiFillWarning,
    AiOutlineArrowLeft,
    AiOutlineArrowRight,
    AiOutlineEye,
    AiOutlinePlus,
} from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { GoChecklist } from 'react-icons/go';
import { LuPackageCheck } from 'react-icons/lu';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import Axios from '../../api/Axios';
import DataNotFound from '../../components/DataNotFound';
import NewFormModal from '../../components/Modal/NewFormModal';
import ErrorMessage from '../../components/error/ErrorMessage';
import LoanMainStatus from '../../components/status/LoanMainStatus';
import LoanStatus from '../../components/status/LoanStatus';
import { ROLENAME, checkAllPermissionList } from '../../helpers/commonUses';
import GetRolePermission from '../../hooks/permission/GetRolePermission';
import GetUserPermission from '../../hooks/permission/GetUserPermission';
import { useAuth } from '../../hooks/useAuth';
import { checkLoanMainStatus, checkLoanStatus, displayAmountText } from '../../utils/utils';
import LoadingPage from '../LoadingPage/LoadingPage';
import ApproveLoan from './ApproveLoan';
import ApproveLoanStatus from './ApproveLoanStatus';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const validationSchema = Yup.object().shape({
    fromDate: Yup.string().required('Start is required'),
    toDate: Yup.string().required('End is required'),
});

//Menu Style
const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity
                ),
            },
        },
    },
}));

const LoanManageList = () => {
    const [open, setOpen] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [approveLoanModal, setApproveLoanModal] = useState(false);
    const [shouldFetchData, setShouldFetchData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [storeStatus, setStoreStatus] = useState(null);
    const [transferData, setTransferData] = useState([]);
    const [page, setPage] = useState(1);
    const [countPage, setCountPage] = useState(10);
    const [error, setError] = useState({
        isError: false,
        errorMessage: null,
    });
    const [currentIndex, setCurrentIndex] = useState(-1);
    const { storePermissionPath } = GetUserPermission();
    const { storeRolePermissionPath } = GetRolePermission();

    //Router Path
    const routerPermissionPath = [
        'api/loan-request/create',
        'api/loan-request/show/{id}',
        'api/loan-request/update/loan-status/{id}',
        'api/loan-request/change/approval-status/{id}',
    ];

    const navigate = useNavigate();

    const menuOpen = Boolean(anchorEl);

    //Check loan status
    const isDisabledStatus = checkLoanStatus(currentIndex?.loan_status);
    const isDisabledLoanStatus = checkLoanMainStatus(
        currentIndex?.approval_status,
        currentIndex?.loan_status
    );

    //Open Modal
    const handleOpen = () => {
        setOpen(true);
        handleMenuClose();
    };
    const handleModalClose = () => {
        setOpen(false);
    };

    //approve Modal
    const handleOpenApprove = () => {
        setApproveModal(true);
        handleMenuClose();
    };
    const handleModalCloseApprove = () => {
        setApproveModal(false);
    };

    //Approve Loan Modal
    const handleOpenApproveLoan = () => {
        setApproveLoanModal(true);
        handleMenuClose();
    };
    const handleModalCloseApproveLoan = () => {
        setApproveLoanModal(false);
    };

    const handleClick = (event, id, statusCode) => {
        setAnchorEl(event.currentTarget);
        setCurrentIndex(id);
        setStoreStatus(statusCode);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
        setStoreStatus(null);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    //Pagination
    const changePagination = (e, value) => {
        setPage(value);
    };

    //react-hook-form
    const { reset } = useForm({
        resolver: yupResolver(validationSchema),
        mode: 'onChange',
    });

    const { role, token } = useAuth();

    const deleteWeekend = async () => {
        if (storeStatus !== 1) {
            setLoading(true);
            try {
                const res = await Axios.delete(`/transfer/delete/${currentIndex}`, {
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                });
                if (res.data.status === 'success') {
                    toast.success('Transfer Deleted');
                    //for last data delete to fix bug
                    if (transferData?.length === 1 && page !== 1) {
                        setPage(1);
                    }
                    setShouldFetchData(!shouldFetchData);
                    handleDialogClose();
                    setLoading(false);
                } else {
                    toast.warn('Something went wrong');
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
                toast.warn('Something went wrong');
                setLoading(false);
            }
        }
    };

    //Get Loan Request Data
    const getLoanRequestList = async () => {
        try {
            setLoading(true);
            const res = await Axios.get(
                '/loan-request',

                {
                    params: {
                        page: page,
                    },
                    headers: {
                        Authorization: token
                            ? 'Bearer ' + token
                            : 'Bearer ' + localStorage.getItem('token'),
                    },
                }
            );
            console.log('getAnnouncementList - res:', res);
            if (res.data.status === 'success') {
                setTransferData(res?.data?.response_data?.data);
                setCountPage(res?.data?.response_data?.last_page);
                setError({
                    isError: false,
                    errorMessage: null,
                });
            } else if (res.data.success === '200') {
                toast.info("You don't have transfer  permissions, contact admin");
                setTransferData([]);
                navigate('/unauthorized', { replace: true });
            } else {
                toast.warn('Something went wrong');
                setTransferData([]);
            }
            setLoading(false);
        } catch (error) {
            console.log('branch list', error);
            setLoading(false);
            setTransferData([]);
            if (error?.response?.status !== 422) {
                setError({
                    isError: true,
                    errorMessage: error?.response?.data?.message,
                });
            }

            if (error?.response?.status === 422) {
                toast.warn('Data not found');
            } else {
                toast.warn('Something went wrong');
            }
        }
    };

    useEffect(() => {
        getLoanRequestList();
    }, [page, shouldFetchData]);

    if (loading) {
        return <LoadingPage />;
    }
    if (error?.isError) {
        return <ErrorMessage message={error?.errorMessage} />;
    }
    return (
        <Paper elevation={2} sx={{ py: 2, px: { xs: 2, md: 3, xl: 5 } }}>
            <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                flexWrap="wrap"
                gap={'10px'}
            >
                <Typography variant="h5">Loan Manage</Typography>
                {role === ROLENAME ||
                storePermissionPath.includes(routerPermissionPath[0]) ||
                storeRolePermissionPath.includes(routerPermissionPath[0]) ? (
                    <Button
                        component={Link}
                        to="/loan-manage/request"
                        variant={'contained'}
                        startIcon={<AiOutlinePlus />}
                    >
                        Add Request
                    </Button>
                ) : null}
            </Stack>
            <Divider sx={{ mt: 2 }} />

            <TableContainer sx={{ mt: 5, pb: 2 }}>
                <Divider />

                <Table sx={{ minWidth: 650 }} aria-label="simple table" size={'small'}>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'left'}>#SI</TableCell>
                            <TableCell align={'left'}>Employee</TableCell>
                            <TableCell>Designation</TableCell>
                            <TableCell>Amount Per Installment</TableCell>
                            <TableCell>Loan Amount</TableCell>
                            <TableCell>Loan Status</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell align="right">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {transferData?.length <= 0 ? (
                            <TableRow>
                                <TableCell component="th" scope="row" colSpan={9}>
                                    <DataNotFound />
                                </TableCell>
                            </TableRow>
                        ) : (
                            transferData.map((item, index) => {
                                return (
                                    <TableRow key={item?.id}>
                                        <TableCell align="left">
                                            {page !== 1 && index + 1 === 10
                                                ? page
                                                : page !== 1 && page - 1}
                                            {index + 1 === 10 && page !== 1 ? 0 : index + 1}
                                        </TableCell>
                                        <TableCell align="left">
                                            {item?.employee_name || 'N/A'}
                                        </TableCell>

                                        <TableCell align="left">
                                            {item?.designation_name || 'N/A'}
                                        </TableCell>
                                        <TableCell align="left">
                                            {displayAmountText(item?.amount_per_installment) ??
                                                'N/A'}
                                        </TableCell>
                                        <TableCell align="left">
                                            {displayAmountText(item?.loan_amount) ?? 'N/A'}
                                        </TableCell>
                                        <TableCell>
                                            <LoanStatus status={item?.loan_status} />
                                        </TableCell>
                                        <TableCell>
                                            <LoanMainStatus status={item?.approval_status} />
                                        </TableCell>

                                        <TableCell align="right">
                                            {role === ROLENAME ? (
                                                <IconButton
                                                    EditTransfer
                                                    id="basic-button"
                                                    aria-controls={open ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={open ? 'true' : undefined}
                                                    onClick={(e) =>
                                                        handleClick(e, item, item?.status)
                                                    }
                                                >
                                                    <BsThreeDotsVertical size={20} />
                                                </IconButton>
                                            ) : checkAllPermissionList(
                                                  routerPermissionPath.slice(1),
                                                  storePermissionPath?.concat(
                                                      storeRolePermissionPath
                                                  )
                                              ) ? (
                                                <>
                                                    {' '}
                                                    <IconButton
                                                        EditTransfer
                                                        id="basic-button"
                                                        aria-controls={
                                                            open ? 'basic-menu' : undefined
                                                        }
                                                        aria-haspopup="true"
                                                        aria-expanded={open ? 'true' : undefined}
                                                        onClick={(e) => handleClick(e, item)}
                                                    >
                                                        <BsThreeDotsVertical size={20} />
                                                    </IconButton>{' '}
                                                </>
                                            ) : (
                                                'N/A'
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </TableBody>
                </Table>
                {page === 1 && transferData?.length < 10 ? null : (
                    <Stack spacing={2} alignItems="end" mt={3}>
                        <Pagination
                            count={countPage}
                            page={page}
                            onChange={changePagination}
                            renderItem={(item) => (
                                <PaginationItem
                                    slots={{
                                        previous: AiOutlineArrowLeft,
                                        next: AiOutlineArrowRight,
                                    }}
                                    {...item}
                                />
                            )}
                        />
                    </Stack>
                )}
            </TableContainer>

            <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {role === ROLENAME ||
                storePermissionPath.includes(routerPermissionPath[1]) ||
                storeRolePermissionPath.includes(routerPermissionPath[1]) ? (
                    <MenuItem onClick={() => navigate(`/loan-manage/view/${currentIndex?.id}`)}>
                        <AiOutlineEye style={{ marginRight: '5px' }} size={18} /> View Details
                    </MenuItem>
                ) : null}
                {role !== ROLENAME &&
                (storePermissionPath.includes(routerPermissionPath[2]) ||
                    storeRolePermissionPath.includes(routerPermissionPath[2])) ? (
                    <MenuItem
                        onClick={isDisabledStatus ? () => undefined : handleOpenApproveLoan}
                        disabled={isDisabledStatus}
                    >
                        <LuPackageCheck style={{ marginRight: '5px' }} size={16} /> Loan Status
                    </MenuItem>
                ) : null}
                {role !== ROLENAME &&
                (storePermissionPath.includes(routerPermissionPath[3]) ||
                    storeRolePermissionPath.includes(routerPermissionPath[3])) ? (
                    <MenuItem
                        onClick={isDisabledLoanStatus ? () => undefined : handleOpenApprove}
                        disabled={isDisabledLoanStatus}
                    >
                        <GoChecklist style={{ marginRight: '5px' }} /> Approve Status
                    </MenuItem>
                ) : null}

                {/* {role === ROLENAME ? (
                    <MenuItem onClick={handleDialogOpen} disabled={storeStatus === 1}>
                        <RiDeleteBin2Line style={{ marginRight: '5px' }} /> Delete
                    </MenuItem>
                ) : (
                    (storePermissionPath.includes(routerPermissionPath[3]) ||
                        storeRolePermissionPath.includes(routerPermissionPath[3])) && (
                        <MenuItem onClick={handleDialogOpen} disabled={storeStatus === 1}>
                            <RiDeleteBin2Line style={{ marginRight: '5px' }} /> Delete
                        </MenuItem>
                    )
                )} */}
            </StyledMenu>

            {/* Approve Modal  */}
            <NewFormModal
                openModal={approveModal}
                handleModalClose={handleModalCloseApprove}
                modalTitle="Approve Status"
                modalWidth={'sm'}
            >
                <ApproveLoan
                    data={currentIndex}
                    setApproveModal={setApproveModal}
                    setShouldFetchData={setShouldFetchData}
                />
            </NewFormModal>

            {/* Approve Loan Modal  */}
            <NewFormModal
                openModal={approveLoanModal}
                handleModalClose={handleModalCloseApproveLoan}
                modalTitle="Approve Loan Status"
                modalWidth={'sm'}
            >
                <ApproveLoanStatus
                    data={currentIndex}
                    setApproveModal={setApproveLoanModal}
                    setShouldFetchData={setShouldFetchData}
                />
            </NewFormModal>

            <Dialog
                open={dialogOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleDialogClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>
                    <Stack alignItems={'center'} justifyContent={'center'} direction={'row'}>
                        <AiFillWarning size={24} color={'#eed202'} />
                        <Typography sx={{ ml: 1 }} variant={'h5'}>
                            Confirm Delete!
                        </Typography>
                    </Stack>
                </DialogTitle>
                <Divider></Divider>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this transfer? Once deleted, you will not
                        get this transfer data back.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} color={'error'} onClick={handleDialogClose}>
                        Disagree
                    </Button>
                    <Button variant={'contained'} color={'success'} onClick={deleteWeekend}>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </Paper>
    );
};

export default LoanManageList;
